import { InboxOutlined } from "@ant-design/icons";
import { Collapse, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useContext } from "react";
import { IoTFullDeviceType } from "src/constant/deviceType";
import { PROTOCOL } from "src/constant/productModelNewVer";
import { IoTProtocol, IoTProtocolToName } from "src/constant/protocol";
import { Image } from "antd";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import * as _ from "lodash";

export const ProductBaseInformation = (
    {
        productInfo,
        setProductInfo,
        setProductName,
        productName,
        setProductDescription,
        productDescription,
        productType,
        setProductType,
        sharePartner,
        setSharePartner,
        incrementCounter,
        incrementCounterRoot
    }
) => {
    const be = useContext(BackendContext);
    const { defaultOrg } = useContext(FirebaseContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const removePartner = (i) => {
        let sharePartnerTemp = sharePartner;
        sharePartnerTemp[i]["action"] =
            sharePartnerTemp[i]["action"] === "remove" ? "undo" : "remove";
        setSharePartner(sharePartnerTemp);
        incrementCounter();
    };
    const setAddedSharePartnersFunc = () => {
        sharePartner.push({
            action: "remove",
            partnerId: "",
            image: "",
            addedStage: true,
        });
        setSharePartner(sharePartner);
        incrementCounter();
    };

    const getPartner = async (partnerId: string) => {
        const partnerInfo = await be.partnerGetinfo(partnerId);
        return partnerInfo;
    };
    const editListSharePartners = (value: string, index: number) => {
        let tempList = sharePartner;
        tempList[index]["partnerId"] = value;
        setSharePartner(tempList);
        incrementCounter();
    };
    const checkPartnerId = async (index) => {
        let tempList = sharePartner;
        if (tempList[index]["partnerId"]) {
            const partnerId = await getPartner(tempList[index]["partnerId"]);
            if (partnerId) {
                tempList[index]["addedStage"] = false;
            }
        }
        setSharePartner(tempList);
        incrementCounter();
    };
    const editPartnerId = (index) => {
        let tempList = sharePartner;
        if (tempList[index]["partnerId"]) {
            // Todo: check in server
            tempList[index]["addedStage"] = true;
        }
        setSharePartner(tempList);
        incrementCounter();
    };
    const propsDragger: UploadProps = {
        listType: "picture",
        name: "file",
        multiple: false,
        maxCount: 1,
        async customRequest({ file, onProgress, onSuccess, onError }: any) {
            const formData = new FormData();
            formData.append("file", file, file.name);
            formData.append("modelId", productInfo["modelId"]);
            formData.append("path", `images/${productInfo["modelId"]}/`);

            onProgress(() => { });
            const upload = await be.modelUploadImagefile(defaultOrg, formData);
            onSuccess(() => { });
            if (!upload) {
                onError(() => { });
            }
        },
        async onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
            }
            if (status === "done") {
                toast.success(`${info.file.name} file uploaded successfully.`);
                const getProductId = await be.modelgetProductId(
                    defaultOrg,
                    productInfo["modelId"],
                );
                setProductName(getProductId["name"]);
                setProductInfo(getProductId);
                setProductType(getProductId["isPublic"]);
                setSharePartner(
                    getProductId["sharePartners"].map((x) => {
                        return { ...x, action: "remove" };
                    }),
                );
                incrementCounter();
            } else if (status === "error") {
                toast.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            toast.error("Dropped files" + e.dataTransfer.files);
        },
    };
    const confirmEdit = async () => {
        if (productName) {
            const bodyPost = sharePartner
                .filter(
                    (x) => x["partnerId"] && x["action"] === "remove" && !x["addedStage"],
                )
                .map((x) => {
                    return {
                        partnerId: x["partnerId"],
                        image: x["image"],
                    };
                });
            await be.modeleditProductId(defaultOrg, productInfo["modelId"], {
                sharePartners: productType ? productInfo["sharePartners"] : bodyPost, // public then do not change sharePartners
                name: productName,
                isPublic: productType,
                description: productDescription
            });
            toast.success("Edit Product Success");
            const getProductId = await be.modelgetProductId(
                defaultOrg,
                productInfo["modelId"],
            );
            setProductName(getProductId["name"]);
            setProductInfo(getProductId);
            setProductType(getProductId["isPublic"]);
            setSharePartner(
                getProductId["sharePartners"].map((x) => {
                    return { ...x, action: "remove" };
                }),
            );
            incrementCounter();
            incrementCounterRoot();
        }
    };
    return <Collapse collapsible="header" defaultActiveKey={[productInfo['isReadyOEM'] && '1']}>
        <Collapse.Panel header={
            <div className="w-100 d-flex justify-content-between align-items-center">
                <div>
                    <b>Product Base Information</b>
                    <small></small>
                </div>
            </div>
        } key="1">
            <form>
                <div className="form-group row">
                    <label htmlFor="asd" className="col-sm-2 col-form-label">
                        Product Name
                    </label>
                    <div className="col-sm-10">
                        <input
                            onChange={(e) => setProductName(e.target.value)}
                            value={productName}
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="prod-description" className="col-sm-2 col-form-label">
                        Product Description
                    </label>
                    <div className="col-sm-10">
                        <input
                            onChange={(e) => setProductDescription(e.target.value)}
                            value={productDescription}
                            type="text"
                            className="form-control"
                            placeholder="Product Description"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        htmlFor="Protocol"
                        className="col-sm-2 col-form-label"
                    >
                        Protocol
                    </label>
                    <div className="col-sm-10 d-flex justify-content-left align-items-center">
                        <div className="text-align">
                            {Object.values(IoTProtocol)
                                .map(
                                    (z, i) =>
                                        z.includes(productInfo["baseInfo"][1]) &&
                                        Object.values(IoTProtocolToName)[i],
                                )
                                .filter((z) => !!z)
                                .join("") ? Object.values(IoTProtocol)
                                    .map(
                                        (z, i) =>
                                            z.includes(productInfo["baseInfo"][1]) &&
                                            Object.values(IoTProtocolToName)[i],
                                    )
                                    .filter((z) => !!z)
                                    .join("") :
                                _.invert(PROTOCOL)[productInfo["baseInfo"][1]]
                            }
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        htmlFor="Device Type"
                        className="col-sm-2 col-form-label"
                    >
                        Device Type
                    </label>
                    <div className="col-sm-10 d-flex justify-content-left align-items-center">
                        <div className="text-align">
                            {
                                Object.keys(IoTFullDeviceType)[
                                Object.values(IoTFullDeviceType).findIndex(
                                    (z) => z === productInfo["categoryInfo"][0],
                                )
                                ]
                            }
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        htmlFor="Product Type"
                        className="col-sm-2 col-form-label"
                    >
                        Product Type
                    </label>
                    <div className="col-sm-10 d-flex justify-content-left align-items-center">
                        <div className="border border-success mr-2 text-align d-flex justify-content-center align-items-center">
                            {productType ? (
                                <>
                                    <div
                                        style={{ borderRadius: 0 }}
                                        className="btn btn-success font-weight-bold"
                                    >
                                        public
                                    </div>
                                    <div
                                        className="ml-2 mr-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setProductType(!productType)}
                                    >
                                        private
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="ml-2 mr-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setProductType(!productType)}
                                    >
                                        public
                                    </div>
                                    <div
                                        style={{ borderRadius: 0 }}
                                        className="btn btn-success font-weight-bold"
                                        onClick={() => { }}
                                    >
                                        private
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {productType !== undefined && !productType && (
                    <div className="form-group row">
                        <label
                            htmlFor="Share Partners"
                            className="col-sm-2 col-form-label"
                        >
                            Share Partners
                        </label>
                        <div className="col-sm-10 d-flex justify-content-left align-items-center">
                            <div className="list-group w-70">
                                <small>
                                    You have to <b>CHECK</b> before submiting edit,
                                    every partner without checking is considered as{" "}
                                    <b className="text-danger">remove</b>
                                </small>

                                {sharePartner.map((x, i) => {
                                    return (
                                        <div className="mt-2 list-group-item flex-column align-items-start">
                                            <div className=" d-flex w-100 justify-content-between">
                                                <div className="d-flex">
                                                    {x["addedStage"] ? (
                                                        <div
                                                            className="form-group mb-0 mr-3 d-flex "
                                                            style={{ minWidth: 100 }}
                                                        >
                                                            <input
                                                                defaultValue={
                                                                    sharePartner[i]["partnerId"]
                                                                }
                                                                onKeyPress={(e) => {
                                                                    e.key === "Enter" &&
                                                                        checkPartnerId(i);
                                                                }}
                                                                onChange={(e) => {
                                                                    editListSharePartners(
                                                                        e.target.value,
                                                                        i,
                                                                    );
                                                                }}
                                                                placeholder="Type Partner Id"
                                                                id="Partner"
                                                                className="form-control form-control"
                                                                type="text"
                                                            />
                                                            <div
                                                                onClick={() => checkPartnerId(i)}
                                                                className="input-group-append"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <span
                                                                    className=" btn btn-primary"
                                                                    id="basic-addon2"
                                                                    style={{ zIndex: "auto" }}
                                                                >
                                                                    Check
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        // <input placeholder="Enter Partner Id" onChange={(e) => editListSharePartners(e.target.value, i)} type="text" className="form-control" />
                                                        <h5 className="mb-1">
                                                            {x["action"] === "undo" ? (
                                                                <del>
                                                                    {" "}
                                                                    <b>{x["partnerId"]}</b>
                                                                </del>
                                                            ) : (
                                                                <b>{x["partnerId"]}</b>
                                                            )}
                                                        </h5>
                                                    )}
                                                </div>
                                                <div>
                                                    <a
                                                        className="ml-1"
                                                        onClick={() => removePartner(i)}
                                                        style={{
                                                            color:
                                                                x["action"] === "remove"
                                                                    ? "red"
                                                                    : "blue",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <b>{x["action"]}</b>
                                                    </a>
                                                    <a
                                                        className="ml-1 border-left border-success pl-2"
                                                        onClick={() => editPartnerId(i)}
                                                        style={{
                                                            color: "green",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <b>Edit</b>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div
                                    onClick={setAddedSharePartnersFunc}
                                    className="mt-2 list-group-item list-group-item-action flex-column align-items-start"
                                >
                                    <div
                                        style={{ cursor: "pointer" }}
                                        className="d-flex w-100 justify-content-center"
                                    >
                                        <b>Add +</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="form-group row">
                    <label htmlFor="Image" className="col-sm-2 col-form-label">
                        Image
                    </label>
                    <div className="col-sm-10 d-flex justify-content-left align-items-center">
                        <div className="">
                            <Image
                                // width={200}
                                src={productInfo["image"]}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        htmlFor="Image"
                        className="col-2 col-form-label"
                    ></label>
                    <div className="col-10 d-flex justify-content-left align-items-center">
                        <Dragger {...propsDragger}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined rev={undefined} />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                                Upload image file. Support type: png, jpg, jpeg, svg
                            </p>
                        </Dragger>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-10 justify-content-left align-items-center d-flex ">
                        <div onClick={confirmEdit} className="btn btn-success">
                            Confirm
                        </div>
                    </div>
                </div>
            </form>

        </Collapse.Panel>
    </Collapse>
}