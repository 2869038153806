import { Select, Radio, Space, Button, Form } from "antd";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BRAND, OWNERSHIP } from "src/constant/productModelNewVer";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";

export const BrandAndOwnerShip = ({
    productInfo,
    setProductInfo,
    incrementCounter,
    form
}) => {
    useEffect(() => {

    }, [form])
    const be = useContext(BackendContext);
    const { defaultOrg } = useContext(FirebaseContext);
    const [loading, setLoading] = useState<boolean>(false);
    const confirmEditDevelopment = async (body: object, brandAndOwnership: boolean = false) => {
        setLoading(true)
        const { extraInfo, developmentInfo } = productInfo
        let buildBody
        if (!brandAndOwnership) {
            buildBody = {
                developmentInfo: {
                    ...developmentInfo,
                    ...body
                }
            }
        } else {
            buildBody = {
                developmentInfo: {
                    ...developmentInfo,
                },
                ...body
            }
        }
        const data = await be.modeleditProductId(defaultOrg, productInfo['modelId'], buildBody);
        if (data) {
            toast.success('Update Successfully')
            const getProductId = await be.modelgetProductId(defaultOrg, productInfo['modelId']);
            setProductInfo(getProductId);
        }
        setLoading(false)
        incrementCounter()
    };
    return <div className="p-3 m-2 d-flex flex-column justify-content-start align-items center co" >
        {productInfo && <Form
            name="validate_other"
            onFinish={(values) => confirmEditDevelopment(values, true)}
            style={{ minWidth: 300 }}
            form={form}
        >
            <Form.Item
                name="brand"
                label="Brand"
                initialValue={productInfo['brand']}
                rules={[
                    {
                        required: true,
                        message: "Please select your product brand!",
                    },
                ]}
            >
                <Select
                    placeholder="Please select a brand"
                >
                    {Object.keys(BRAND).map((x, i) => {
                        return (
                            <Select.Option
                                key={i.toString()}
                                value={Object.values(BRAND)[i]}>
                                {x}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            {productInfo && productInfo['ownership'] === '0004' ?
                <div>
                    <label htmlFor="">Ownership: <b>3rd</b></label>
                </div>
                : <Form.Item
                    name="ownership"
                    label="Owner Ship"
                    initialValue={productInfo['ownership']}
                    rules={[
                        {
                            required: true,
                            message: "Please select a owner ship of the product!",
                        },
                    ]}
                >
                    <Radio.Group
                    >
                        {Object.keys(OWNERSHIP).map((x, i) => {
                            return (
                                <Radio
                                    key={i.toString()}
                                    value={Object.values(OWNERSHIP)[i]}>{x}</Radio>
                            );
                        })}
                    </Radio.Group>
                </Form.Item>}
            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Space>
                    {loading && (
                        <div className="spinner spinner-success mr-3"></div>
                    )}
                    {!loading && (
                        <>
                            <Button type="primary" htmlType="submit">
                                Confirm
                            </Button>
                        </>
                    )}

                </Space>
            </Form.Item>
        </Form>}
    </div>
}