import { signOut } from "firebase/auth";
import * as React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BackendAPI, BackendContext } from "src/services/backend";
import { auth } from "src/services/config";
import { FirebaseContext } from "src/services/firebaseContext";
import "./partitals.css";
export const Head = ({ mainSideBar }) => {
  const contextInfo = React.useContext(FirebaseContext);
  const be = React.useContext(BackendContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const signOutFirebase = () => {
    signOut(auth).then((x) => {
      localStorage.removeItem("defaultOrg");
      navigate("/auth");
    });
  };
  const [project, setProject] = React.useState<Array<object>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { defaultOrg } = React.useContext(FirebaseContext);
  React.useEffect(() => {}, [project]);
  const switchProject = async () => {
    setLoading(true);
    const getAll = await be.projectGetAll(defaultOrg);
    setProject(getAll);
    setLoading(false);
    return getAll;
  };
  return (
    <>
      <div className="main-header">
        <div className="ml-3 logo d-flex justify-content-center align-items-center">
          <a href={`/`} style={{ minWidth: 50 }}>
            {/* <img src={`${window.location.origin + '/assets/images/logo.svg'}`} alt="Rogo IoT Logo" className="pr-2" /> */}
          </a>
          <div className="border px-3 py-1 d-flex align-items-center rounded">
            <div className="dropdown mega-menu d-block">
              <a
                href="#"
                className="ml-3 pl-2 font-weight-bold m-0 d-flex justify-content-center align-items-center"
                id="dropdownMegaMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>{contextInfo["defaultOrg"]}</span>
                <svg
                  className="leafygreen-ui-866818"
                  height="14"
                  width="14"
                  role="presentation"
                  aria-hidden="true"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8.67903 10.7962C8.45271 11.0679 8.04729 11.0679 7.82097 10.7962L4.63962 6.97649C4.3213 6.59428 4.5824 6 5.06866 6L11.4313 6C11.9176 6 12.1787 6.59428 11.8604 6.97649L8.67903 10.7962Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
              <div
                className="dropdown-menu text-left"
                style={{ overflowY: "hidden", maxWidth: 190 }}
                aria-labelledby="dropdownMenuButton"
              >
                <ul
                  className="p-0 m-0 d-flex justify-content-center flex-column"
                  style={{ listStyle: "none" }}
                >
                  <FirebaseContext.Consumer>
                    {({ userOrg, defaultOrg, setDefautlOrg }) => {
                      return (
                        userOrg &&
                        userOrg.map((x,i) => {
                          const itemActive =
                            defaultOrg === x ? " item-name-active " : "  ";
                          return (
                            <li
                              onClick={(e) => {
                                e.preventDefault();
                                setDefautlOrg(x);
                                window.location.href = "/org/project";
                              }}
                              key={i}
                              className={`li text-center p-0 ${itemActive} `}
                            >
                              <span style={{ padding: "5px 24px" }}>
                                <b>{x}</b>
                              </span>
                            </li>
                          );
                        })
                      );
                    }}
                  </FirebaseContext.Consumer>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="btn-group dropdown ml-4">
            <button
              onClick={switchProject}
              type="button"
              className="btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {/* {localStorage.getItem("projectName")} */}
              {localStorage.getItem("projectName")
                ? localStorage.getItem("projectName")
                : "Switch Project"}
            </button>
            <button
              onClick={switchProject}
              type="button"
              className="btn  dropdown-toggle dropdown-toggle-split _r_drop_right"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle Dropdown</span>
            </button>
            {/* <span className="d-flex align-items-center ml-3 font-weight-bold">{localStorage.getItem("projectName")}</span> */}
            <div
              className="dropdown-menu text-left p-0"
              style={{ overflowY: "hidden", maxWidth: 190 }}
              aria-labelledby="dropdownMenuButton"
            >
              <ul
                className="p-0 m-0 d-flex justify-content-center flex-column"
                style={{ listStyle: "none" }}
              >
                {loading && (
                  <li className="text-center">
                    <div className="spinner spinner-success mr-3"></div>
                  </li>
                )}
                {project &&
                  project.map((x, i) => {
                    const itemActive =
                      projectId === x["uuid"] ? " item-name-active " : "  ";
                    return (
                      <li
                        onClick={(e) => {
                          e.preventDefault();
                          localStorage.setItem("projectName", x["name"]);
                          project.filter((x) => x["uuid"] === projectId)
                            .length > 0
                            ? navigate(
                                "/project/" +
                                  x["uuid"] +
                                  "/" +
                                  pathname.split("/")[
                                    pathname.split("/").length - 1
                                  ],
                              )
                            : navigate("/project/" + x["uuid"] + "/overview");
                          window.location.reload();
                        }}
                        key={i.toString()}
                        className={`li text-center p-0 ${itemActive} `}
                      >
                        <span
                          style={{
                            padding: "5px 24px",
                            color: projectId === x["uuid"] ? "#fe2151" : "",
                          }}
                        >
                          <b>{x["name"]}</b>
                        </span>
                      </li>
                    );
                  })}
                <li
                  style={{ cursor: "pointer" }}
                  className="text-center mt-2 mb-2 border-top"
                  onClick={() => {
                    localStorage.removeItem("projectName");
                    navigate("/org/project");
                  }}
                >
                  <span className="">
                    <b>View All</b>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="menu-toggle-2">
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className="d-flex align-items-center ml-5">
          <div className="dropdown mega-menu d-none d-md-block"></div>
        </div>
        <div style={{ margin: "auto" }}></div>

        <div className="header-part-right">
          <div className="dropdown">
            <div className="user col align-self-end">
              <i
                style={{ fontSize: 20, cursor: "pointer" }}
                className="i-Lock-User mr-1 rounded border p-2"
                id="userDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></i>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="userDropdown"
              >
                <div className="dropdown-header">
                  <i className="i-Lock-User mr-1"></i> Account
                </div>
                <button className="dropdown-item" onClick={signOutFirebase}>
                  Sign out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
