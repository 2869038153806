import { DownloadOutlined, SelectOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useContext, useEffect, useState } from "react";
import { IoTFullDeviceType } from "src/constant/deviceType";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import { getImg } from "../productListTable";
import yaml from 'js-yaml';
import { Buffer } from 'buffer'
export const DownloadDocument = ({
    productInfo,
    setProductInfo
}) => {
    useEffect(() => {
    }, [productInfo])
    const be = useContext(BackendContext);
    const { defaultOrg } = useContext(FirebaseContext);
    const [loading, setLoading] = useState<boolean>(false);
    const getDownloadUrl = async (path) => {
        const downloadUrl = await be.firmwareGetDownloadUrl(defaultOrg, path)
        window.open(downloadUrl, "_blank");
    }
    const flasherUrl = async (path) => {
        const url = await be.modelGetFlasherUrl(defaultOrg, productInfo['modelId'], `firmware/${defaultOrg}/${productInfo['modelId']}/${productInfo['modelId']}.json`, path)
        popupwindow(url, "Flasher", 900, 400);
    }
    function popupwindow(url, title, w, h) {
        var left = (screen.width / 2) - (w / 2);
        var top = (screen.height / 2) - (h / 2);
        return window.open(url, title, 'toolbar=yes, location=yes,  status=yes,  scrollbars=no, resizable=yes, copyhistory=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
    }
    return <div className="row">
        {productInfo &&
            (productInfo['developmentInfo']['moduleType'] === 'MODULE' ? <div className="col-6">
                <pre style={{ backgroundColor: 'whitesmoke' }} className="p-2">
                    <div className="d-flex justify-content-center align-items-center">
                        <h3 className="font-weight-bold">{productInfo['developmentInfo']['moduleName']}</h3>
                    </div>
                    <code className="font-weight-bold ">
                        {
                            yaml.dump(productInfo['developmentInfo']['moduleDetail'])
                        }
                    </code>
                </pre>
            </div> : (productInfo['developmentInfo']['moduleDetail'] && <div className="col-6">
                <Card
                    hoverable={false}
                    className={`card-selected`}
                    style={{
                        marginTop: 10,
                        border: '1px solid #005cff'
                    }}
                >
                    <div className="d-flex">
                        <div className="mr-2 align-items-center d-flex">
                            <img
                                width={70}
                                alt="img"
                                src={
                                    productInfo['developmentInfo']['moduleDetail']['image'] ? productInfo['developmentInfo']['moduleDetail']['image'] : getImg(Object.keys(IoTFullDeviceType)[
                                        Object.values(IoTFullDeviceType).findIndex(
                                            (z) => z === productInfo['developmentInfo']['moduleDetail']["categoryInfo"][0],
                                        )
                                    ])}
                            />
                        </div>
                        <Meta title={<>
                            <div>{productInfo['developmentInfo']['moduleDetail']['name']}</div>
                            <small>{productInfo['developmentInfo']['moduleDetail']['modelId']}</small>
                        </>} description={productInfo['developmentInfo']['moduleDetail']['description']} />
                    </div>
                </Card>
            </div>
            ))
        }
        {productInfo && productInfo['developmentInfo']['moduleType'] !== 'STORE' && <div className="col-6 mt-4 mb-4">
            {
                productInfo && productInfo['developmentInfo']['solution'] === 'NO_CODE' ?
                    <div className=" ">
                        <div>
                            <h3>Download Firmware</h3>
                            <small>Wait till admin upload firmware for your product. More information please contact <b>ninh.dh@rogo.com.vn</b></small>
                        </div>
                        {
                            productInfo['developmentInfo']['moduleFirmware'] ?
                                <div className="mt-4 d-flex flex-column align-items-center">
                                    {/* <Button
                                        className="d-flex justify-content-center align-items-center font-weight-bold"
                                        style={{ color: "#1677ff" }}
                                        onClick={() => getDownloadUrl(productInfo['developmentInfo']['moduleFirmwarePath'])}
                                    >
                                        {productInfo['developmentInfo']['moduleFirmwarePath']}
                                        <DownloadOutlined className="ml-2" rev={undefined} />
                                    </Button>
 */}

                                    <Button
                                        className="d-flex mt-3 justify-content-center align-items-center font-weight-bold p-5"
                                        style={{ color: "#1677ff" }}
                                        onClick={(e) => { flasherUrl(productInfo['developmentInfo']['moduleFirmwarePath'])}}
                                    >
                                        Flasher
                                        <SelectOutlined className="ml-2" rev={undefined} />
                                    </Button>
                                </div> :
                                <>
                                    {loading && (
                                        <div className="spinner spinner-success mr-3"></div>
                                    )}
                                    {!loading && <Button
                                        className="d-flex justify-content-center align-items-center font-weight-bold"
                                        style={{ color: "#1677ff" }}
                                        onClick={async () => {
                                            setLoading(true)
                                            const getProductId = await be.modelgetProductId(defaultOrg, productInfo['modelId']);
                                            setProductInfo(getProductId);
                                            setLoading(false)
                                        }}
                                    >
                                        Refresh
                                        <UndoOutlined className="ml-2" rev={undefined} />
                                    </Button>}
                                </>
                        }
                    </div>
                    :
                    <div className=" ">
                        <div>
                            <h3 className="mb-0">Download SDK</h3>
                            <small>Download SDK and develop on your own, contact <b>mrtan@rogo.com.vn</b> for more information</small>
                        </div>
                        <Button
                            className="d-flex mt-3 justify-content-center align-items-center font-weight-bold"
                            style={{ color: "#1677ff" }}
                            onClick={() => getDownloadUrl(`sdk/SDK.pdf`)}
                        >
                            DAY LA FILE SDK
                            <DownloadOutlined className="ml-2" rev={undefined} />
                        </Button>
                    </div>
            }
        </div>}
    </div>
}