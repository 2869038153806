import { useEffect, useState } from "react";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Action } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import { BackendContext } from "src/services/backend";
import { Can } from "src/services/caslContext";
import Modal from "react-modal";
import { FirebaseContext } from "src/services/firebaseContext";
import toast from "react-hot-toast";
import "../../../assets/modal.css";
import { Form, Switch } from "antd";

export function TabOverview() {
  interface IIp {
    ip: string;
    comment: string;
  }
  const [needVerifyEmail, setNeedVerifyEmail] = useState();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [project, setProject] = React.useState<any>(false);
  const [apiKey, setApiKey] = React.useState<string>();
  const [ipAdd, setIpAdd] = React.useState<IIp>();
  const [listIp, setListIp] = React.useState<any[]>([]);
  const be = React.useContext(BackendContext);
  const { defaultOrg } = React.useContext(FirebaseContext);
  const { projectId } = useParams();
  const [modalIsOpen, setIsOpen] = React.useState<boolean>();
  function closeModal() {
    setIsOpen(undefined);
    setListIp([]);
    setIpAdd({
      ip: "",
      comment: "",
    });
  }
  function ValidateIPaddress(ipaddress) {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress,
      )
    ) {
      return true;
    }
    return false;
  }
  const addIps = () => {
    if (ipAdd && ValidateIPaddress(ipAdd["ip"])) {
      listIp.push({ ip: ipAdd, action: "remove" });
      setListIp(listIp);
      setIpAdd({
        ip: "",
        comment: "",
      });
    }
  };
  const updateWhiteList = async () => {
    setLoading(true);
    const whiteList = listIp
      .filter((x) => x["action"] === "remove")
      .map((x) => x["ip"]);
    await be.projectUpdateWhiteListedIp(defaultOrg, projectId, whiteList);
    setListIp([]);
    setLoading(false);
    setIsOpen(undefined);
    getDetailProject();
  };
  React.useEffect(() => {
    getDetailProject();
  }, []);
  const removeIp = (i) => {
    listIp[i]["action"] = listIp[i]["action"] === "remove" ? "undo" : "remove";
    setListIp(listIp);
  };
  const viewProjectApiKey = async () => {
    setLoading(true);
    if (apiKey) {
      setApiKey(undefined);
    } else {
      const detailProject = await be.projectGetApiKey(defaultOrg, projectId);
      setApiKey(detailProject);
    }
    setLoading(false);
  };

  const getDetailProject = async () => {
    setLoading(true);
    const detailProject = await be.projectGetDetail(defaultOrg, projectId);
    setProject(detailProject);
    setNeedVerifyEmail(detailProject["project"]["needVerifyEmail"]);
    setLoading(false);
  };
  const editProject = async () => {
    try {
      await be.projectEdit(defaultOrg, projectId, {
        needVerifyEmail: !needVerifyEmail,
      });
      await getDetailProject();
      toast.success(`Update Successfully`);
    } catch (e) {
      toast.error(`Update Failed`);
    }
  };
  const getWhitelist = async () => {
    const getWhiteListedIp = await be.projectGetWhiteListedIp(
      defaultOrg,
      projectId,
    );
    if (getWhiteListedIp && getWhiteListedIp["whitelistedIps"].length > 0) {
      const ipList = getWhiteListedIp["whitelistedIps"].map((x) => {
        return {
          ip: typeof x === "string" ? { ip: x, comment: "" } : x,
          action: "remove",
        };
      });
      setListIp(ipList);
    }
  };
  const editListIps = (value: string, index: number) => {
    let tempList = listIp;
    tempList[index]["ip"]["comment"] = value;
    setListIp(tempList);
  };
  return (
    <>
      <div className="main-content">
        <div className="breadcrumb">
          <h1>IoT Dashboard</h1>
        </div>
        <div className="separator-breadcrumb border-top"></div>
        <div className="row">
          <div className="col-lg-8 col-md-4 col-sm-12" style={{ zIndex: 0 }}>
            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
              <div
                className="card-body justify-content-between"
                style={{ padding: "2.25rem", backgroundColor: "#f6faff" }}
              >
                <div className="d-block">
                  <small>
                    **:{" "}
                    <i>
                      {" "}
                      <b>
                        Use Project Id and Project Name and Project API Key and
                        UserId to get user's login Token{" "}
                      </b>{" "}
                    </i>
                  </small>
                  <h4 className="font-weight-bold">
                    {project && project["project"]["name"]}
                  </h4>
                  {!project && (
                    <div className="spinner spinner-success mr-3"></div>
                  )}
                  <div>
                    <div>
                      <b>Creation Time:</b>{" "}
                      {project &&
                        new Date(
                          project["project"]["createdAt"],
                        ).toLocaleString()}
                    </div>
                  </div>
                  <h5 className="font-weight-bold mt-3">Authorization</h5>
                  {!project && (
                    <div className="spinner spinner-success mr-3"></div>
                  )}

                  <div>
                    <div>
                      <b>ProjectId:</b> {project && project["project"]["uuid"]}
                    </div>
                    <Can I={Action.ProjectAccessApiKey} a={Resources.Project}>
                      <div className="d-flex justify-content-between mt-1">
                        <div className="font-weight-bold">Project Api Key:</div>
                        <span>
                          {apiKey ? apiKey["apiKey"] : `*************`}{" "}
                        </span>
                        <a
                          href="#"
                          onClick={viewProjectApiKey}
                          style={{ backgroundColor: "ButtonShadow" }}
                          className="px-2 ml-4"
                        >
                          <span
                            className="font-weight-bold"
                            style={{ fontSize: 10 }}
                          >
                            {apiKey ? `Hide` : `View`}
                          </span>
                        </a>
                      </div>
                      <small>
                        <b>**</b>{" "}
                        <i>
                          For security reasons, Keep the project API key secret.
                        </i>
                      </small>
                    </Can>
                  </div>
                  <Can I={Action.ProjectUpdate} a={Resources.Project}>
                    <div className="d-flex justify-content-between align-items-center w-100 mt-3">
                      <h5 className="font-weight-bold">
                        Project Whitelisted IP Address
                      </h5>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          getWhitelist();
                          setIsOpen(true);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                    <div>{project && project["numOfIps"]} IP(s) added</div>

                    <Modal
                      isOpen={modalIsOpen}
                      // onRequestClose={modalDelete}
                      ariaHideApp={false}
                      style={{
                        content: {
                          top: "50%",
                          left: "50%",
                          right: "auto",
                          bottom: "auto",
                          marginRight: "-50%",
                          transform: "translate(-50%, -50%)",
                          width: "900px",
                        },
                      }}
                    >
                      <h2>Add Authorization IP list</h2>
                      <div className="separator-breadcrumb border-top"></div>
                      <div className="">
                        <div className="form-group w-100 ">
                          <div className="form-group ">
                            <label htmlFor="IPv4">IPv4</label>
                            <input
                              onKeyPress={(e) => e.key === "Enter" && addIps()}
                              value={ipAdd && ipAdd["ip"]}
                              onChange={(e) =>
                                setIpAdd({
                                  ip: e.target.value,
                                  comment: ipAdd && ipAdd["comment"],
                                })
                              }
                              type="text"
                              className="form-control"
                              placeholder="IPv4..."
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                          </div>
                          {!ipAdd ||
                            (!ValidateIPaddress(ipAdd["ip"]) && (
                              <label
                                htmlFor="recipient-name-2"
                                className="text-danger"
                              >
                                Enter Valid IPv4 Address
                              </label>
                            ))}
                          <div className="form-group mt-2">
                            <label htmlFor="Comment">Comment</label>
                            <input
                              onKeyPress={(e) => e.key === "Enter" && addIps()}
                              value={ipAdd && ipAdd["comment"]}
                              onChange={(e) =>
                                setIpAdd({
                                  ip: ipAdd["ip"] && ipAdd["ip"],
                                  comment: e.target.value,
                                })
                              }
                              type="text"
                              className="form-control"
                              placeholder="Comment"
                            />
                          </div>
                          <div className=" text-right">
                            <span
                              className="mt-2 btn btn-success"
                              id="basic-addon2"
                              onClick={addIps}
                            >
                              Confirm
                            </span>
                          </div>
                        </div>
                        <div className="">
                          <div className=" d-flex flex-column">
                            <div className="table-responsive">
                              <table
                                id=""
                                className="table text-left table-hover"
                              >
                                <thead>
                                  <tr>
                                    <th>Ip</th>
                                    <th>Comment</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {listIp &&
                                    listIp.map((x, i) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>
                                              <span
                                                className="mr-3 font-weight-bold"
                                                key={i.toString()}
                                              >
                                                {x["ip"]["ip"]}
                                              </span>
                                            </td>
                                            <td>
                                              <input
                                                onChange={(e) =>
                                                  editListIps(e.target.value, i)
                                                }
                                                defaultValue={
                                                  x["ip"]["comment"]
                                                }
                                                type="text"
                                                className="form-control"
                                              />
                                            </td>
                                            <td className="d-flex justify-content-center align-items-center pt-3">
                                              <a
                                                href="#"
                                                className="ml-1"
                                                onClick={() => removeIp(i)}
                                                style={{
                                                  color:
                                                    x["action"] === "remove"
                                                      ? "red"
                                                      : "blue",
                                                }}
                                              >
                                                <b>{x["action"]}</b>
                                              </a>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-5">
                        <button
                          type="button"
                          onClick={closeModal}
                          className="btn btn-secondary"
                        >
                          Close
                        </button>
                        {!loading && (
                          <button
                            type="button"
                            onClick={updateWhiteList}
                            className="btn btn-secondary ml-3"
                          >
                            Apply
                          </button>
                        )}
                        {loading && (
                          <div className="spinner spinner-success mr-3"></div>
                        )}
                      </div>
                    </Modal>
                  </Can>
                  <Can I={Action.ProjectUpdate} a={Resources.Project}>
                    <div className="mt-3 d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-column">
                        <h5>
                          <b>Need To Verify Email When Sign Up: </b>
                        </h5>
                        <div>
                          <small>
                            <b>**</b>{" "}
                            <i>
                              Set this to Disabled to skip sending email
                              vertification
                            </i>
                          </small>
                        </div>
                      </div>
                      <div>
                        {project && (
                          <Form.Item
                            name="switch"
                            label=""
                            valuePropName="checked"
                          >
                            <Switch
                              onChange={(e) => {
                                editProject();
                              }}
                              defaultChecked={
                                project["project"]["needVerifyEmail"]
                              }
                            />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                  </Can>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
