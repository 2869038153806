import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Action, ActionToName } from "src/constant/abac";
import { BackendContext } from "src/services/backend";
import { Can } from "src/services/caslContext";
import { FirebaseContext } from "src/services/firebaseContext";
import { ModalHandleAuthorize } from "./elements/modalHandleAuthorize";
import Modal from "react-modal";
import { Resources } from "src/constant/resources";
import { RoleDecompile } from "src/services/roleCompiler";
import "../../../assets/modal.css";

export const TabUser = () => {
  const { defaultOrg } = React.useContext(FirebaseContext);
  const { projectId } = useParams();
  const [show, setShow] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [updateScreen, setUpdateScreen] = React.useState<boolean | string>(
    false,
  );
  const [userPartner, setUserPartner] = React.useState<Array<object>>();
  const [project, setProject] = React.useState<Array<object>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = React.useState<string | undefined>();

  const be = React.useContext(BackendContext);
  const openModal = () => {
    setShow(!show);
  };
  React.useEffect(() => {
    getAccessUserPartner().then((x) => {
      getCloudProject();
    });
  }, []);

  const getUserAbac = (ownerId: string) => {
    if (userPartner) {
      const getUserPermission = userPartner["abac"].filter(
        (x) => !!x[ownerId],
      )[0];
      const abacInfo = RoleDecompile.decompile(getUserPermission[ownerId]);
      const abacInfoInPartnerIdAndProjectId = abacInfo.filter(
        (x) => x.partnerId === defaultOrg && x.projectId === projectId,
      );
      const role = abacInfoInPartnerIdAndProjectId[0]["role"];
      return role;
    }
  };
  const getAccessUserPartner = async () => {
    setLoading(true);
    const getAll = await be.userpartnerGetAllWithPermission(
      defaultOrg,
      projectId,
    );
    setUserPartner(getAll);
    setLoading(false);
  };
  const getCloudProject = async () => {
    const getAll = await be.projectGetAll(defaultOrg);
    setProject(getAll);
  };
  const deleteUser = async (uuid: string) => {
    setLoading(true);
    const abacBuilder = [
      {
        resources: [`partner:${defaultOrg}:project/${projectId}`],
        actions: [],
      },
    ];
    await be.abacAttachRoleToUser(
      uuid,
      defaultOrg,
      projectId,
      abacBuilder,
      false,
      true,
    );
    await getAccessUserPartner();
    setLoading(false);
  };
  function modalDelete(uuid: string) {
    setIsOpen(uuid);
  }
  function closeModal() {
    setIsOpen(undefined);
  }
  return (
    <>
      <Modal
        isOpen={show}
        // onRequestClose={modalDelete}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            background: "none!important",
            border: 0,
            width: "900px",
          },
        }}
      >
        <ModalHandleAuthorize
          project={project}
          show={show}
          openModal={openModal}
          getAccessUserPartner={getAccessUserPartner}
          updateScreen={updateScreen}
          setUpdateScreen={() => setUpdateScreen(false)}
        />
      </Modal>
      <div className="row">
        <div className="col-8 mb-3">
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h4 className="mt-2">Authorizations</h4>
                </div>
                <div className="form-group m-0 ">
                  <Can I={Action.UserCreate} a={Resources.User}>
                    <button
                      onClick={openModal}
                      type="button"
                      className="btn btn-success btn-icon m-1"
                    >
                      <span className="ul-btn__icon">
                        <i className="i-Add"></i>
                      </span>
                      Create Sub Account
                    </button>
                  </Can>
                  <Can I={Action.ProjectUpdate} a={Resources.Project}>
                    <button
                      onClick={() => {
                        navigate(`/project/${projectId}/users/attach`);
                      }}
                      type="button"
                      className="btn btn-info btn-icon m-1"
                    >
                      <span className="ul-btn__icon">
                        <i className="i-Add"></i>
                      </span>
                      Attach User To Project
                    </button>
                  </Can>
                </div>
              </div>
              <div className="table-responsive">
                <table id="" className="table text-left  ">
                  <thead>
                    <tr style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                      <th scope="col">User</th>
                      <th scope="col">Role</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {loading && (
                    <tbody>
                      <div className="spinner spinner-success mr-3"></div>
                    </tbody>
                  )}
                  {!loading && (
                    <tbody>
                      {userPartner &&
                        userPartner["userpartner"].map((x, i) => {
                          return (
                            <>
                              <tr key={i.toString()}>
                                <th style={{ width: "16%" }} scope="row">
                                  <span
                                    className="font-weight-bold "
                                    style={{ fontSize: 22 }}
                                  >
                                    {x["name"]}{" "}
                                  </span>
                                  {x["status"] === 0 ? (
                                    <span className="badge badge-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-warning">
                                      Not Active
                                    </span>
                                  )}
                                  <br />
                                  <span className="mr-2 font-weight-normal">
                                    {x["email"]}
                                  </span>{" "}
                                </th>
                                <td style={{ maxWidth: 0, minWidth: "90px" }}>
                                  {getUserAbac(x["ownerId"]).map((x) => (
                                    <span className="badge badge-success mr-1">
                                      {ActionToName[x]}
                                    </span>
                                  ))}
                                </td>
                                <td>
                                  {new Date(x["createdAt"]).toLocaleString()}
                                </td>
                                <td>
                                  <Can
                                    I={Action.ProjectEditRole}
                                    a={Resources.Project}
                                  >
                                    <a
                                      onClick={() => {
                                        setUpdateScreen(x["ownerId"]);
                                        openModal();
                                      }}
                                      href="#"
                                      className="text-success mr-2 border border-dark border-3 rounded p-2"
                                      style={{ backgroundColor: "#efefef" }}
                                    >
                                      Edit Role
                                    </a>
                                  </Can>
                                  <Can I={Action.UserDelete} a={Resources.User}>
                                    <a
                                      onClick={() => modalDelete(x["ownerId"])}
                                      href="#"
                                      className="text-danger mr-2 border border-3 rounded p-2"
                                      style={{ backgroundColor: "#efefef" }}
                                    >
                                      Remove
                                    </a>
                                  </Can>
                                </td>
                                <Modal
                                  isOpen={modalIsOpen === x["ownerId"]}
                                  // onRequestClose={modalDelete}
                                  ariaHideApp={false}
                                  style={{
                                    content: {
                                      top: "50%",
                                      left: "50%",
                                      right: "auto",
                                      bottom: "auto",
                                      marginRight: "-50%",
                                      transform: "translate(-50%, -50%)",
                                    },
                                  }}
                                >
                                  <h2>Detach {x["email"]} ?</h2>
                                  <div className="d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={closeModal}
                                      className="btn btn-secondary"
                                    >
                                      Close
                                    </button>
                                    {!loading && (
                                      <button
                                        type="button"
                                        onClick={() => deleteUser(modalIsOpen)}
                                        className="btn btn-danger ml-3"
                                      >
                                        Delete
                                      </button>
                                    )}
                                    {loading && (
                                      <div className="spinner spinner-success mr-3"></div>
                                    )}
                                  </div>
                                </Modal>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
