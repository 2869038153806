import { ActionShort } from "src/constant/abac";
import { Buffer } from "buffer";
export class RogosdkService {
  private BLOCK_RESOURCES_PARTNER = 20;
  private BLOCK_RESOURCES_PROJECT = 21;
  private SUPPORT_BLOCK_ATTR_VALUE = [
    "01",
    "20",
    "21",
    "80",
    "81",
    "82",
    "83",
    "84",
    "85",
  ];
  private ACTION_SHORT_TO_LONG: Map<any, string> = new Map(
    Object.entries(ActionShort).map(([key, value]) => [value, key]),
  );
  private RNN_TO_BLOCK = {
    project: 80,
    user: 81,
    report: 82,
    app: 83,
    product: 84,
    hardware: 85,
  };
  private BLOCK_TO_RNN = new Map(
    Object.entries(this.RNN_TO_BLOCK).map(([key, value]) => [value, key]),
  );

  getHexByte(value: number) {
    let hexValue = value.toString(16);
    switch (hexValue.length) {
      case 1:
        return "0" + hexValue;
      case 2:
        return hexValue;
      default:
        return "00";
    }
  }

  getHexShort(value: number) {
    let hexValue = value.toString(16);
    switch (hexValue.length) {
      case 1:
        return "000" + hexValue;
      case 2:
        return "00" + hexValue;
      case 3:
        return "0" + hexValue;
      case 4:
        return hexValue;
      default:
        return "0000";
    }
  }
  mapToObjectRec(m) {
    let lo = {};
    for (let [k, v] of m) {
      if (v instanceof Map) {
        lo[k] = this.mapToObjectRec(v);
      } else {
        lo[k] = v;
      }
    }
    return lo;
  }
  decodeHexAbac(abac: string) {
    const abacString = Buffer.from(abac, "hex");
    const header = this.getHexByteFromMsg(0, 1, abacString);

    const BLOCK = this.getHexByteFromMsg(header[1], 1, abacString);
    const BLOCK_LENGTH = this.getHexByteFromMsg(BLOCK[1], 1, abacString);
    const BLOCK_DATA = this.getHexByteFromMsg(
      BLOCK_LENGTH[1],
      parseInt(BLOCK_LENGTH[0], 16),
      abacString,
    );
    const blockBodys = this.getBodyBlock(BLOCK_DATA[1], abacString);
    return Object.values(this.mapToObjectRec(blockBodys));
  }
  getBodyBlock(
    startByteOffset: number,
    abacString: Buffer,
    i: number = 0,
    blockBodyMaps = new Map(),
  ) {
    const BLOCK = this.getHexByteFromMsg(startByteOffset, 1, abacString);
    const BLOCK_LENGTH = this.getHexByteFromMsg(BLOCK[1], 1, abacString);
    const BLOCK_VALUE = this.getHexByteFromMsg(
      BLOCK_LENGTH[1],
      parseInt(BLOCK_LENGTH[0], 16),
      abacString,
    );
    // if this is valid block, then we push to result
    if (this.SUPPORT_BLOCK_ATTR_VALUE.includes(BLOCK[0])) {
      // cuz there are multiple partner in single abac
      if (BLOCK[0] === this.BLOCK_RESOURCES_PARTNER.toString()) {
        if (!blockBodyMaps.has(i.toString())) {
          const newMap = new Map();
          newMap.set("resources", {});
          newMap.set("actions", []);
          blockBodyMaps.set(i.toString(), newMap);
          i += 1;
        }
      }
      // handle partnerId + projectId in resources
      switch (BLOCK[0]) {
        case this.BLOCK_RESOURCES_PARTNER.toString(): {
          const partnerId = Buffer.from(BLOCK_VALUE[0], "hex").toString("utf8");
          blockBodyMaps
            .get((i - 1).toString())
            .set("resources", `partner:${partnerId}`);
          break;
        }
        case this.BLOCK_RESOURCES_PROJECT.toString(): {
          // 2a is *, we decode to string otherwise we keep original string
          let projectId;
          if (BLOCK_VALUE[0] === "2a") {
            projectId = Buffer.from(BLOCK_VALUE[0], "hex").toString("utf8");
          } else {
            projectId = BLOCK_VALUE[0];
          }
          blockBodyMaps
            .get((i - 1).toString())
            .set("resources", [
              `${blockBodyMaps
                .get((i - 1).toString())
                .get("resources")}:project/${projectId}`,
            ]);
          break;
        }
      }
      // handle actions
      if (this.BLOCK_TO_RNN.has(parseInt(BLOCK[0]))) {
        const actionShortToLong = this.ACTION_SHORT_TO_LONG.get(
          Buffer.from(BLOCK_VALUE[0], "hex").toString("utf8"),
        );
        const concatAction = `${this.BLOCK_TO_RNN.get(
          parseInt(BLOCK[0]),
        )}:${actionShortToLong}`;
        blockBodyMaps
          .get((i - 1).toString())
          .get("actions")
          .push(concatAction);
      }
    }
    if (BLOCK_VALUE[1] < abacString.length) {
      this.getBodyBlock(BLOCK_VALUE[1], abacString, i, blockBodyMaps);
      return blockBodyMaps;
    } else {
      return blockBodyMaps;
    }
  }
  getHexByteFromMsg(
    offset: number,
    byteToGet: number,
    msg?: Buffer,
  ): [string, number] {
    const byteToStringLength = byteToGet;
    const nextByteOffset = byteToStringLength + offset;
    return [
      msg.subarray(offset, nextByteOffset).toString("hex"),
      nextByteOffset,
    ];
  }
}
