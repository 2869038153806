import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    Divider,
    Popconfirm,
    Radio,
    Select,
    Space,
    Steps,
    Table,
    Tag,
    Form,
    Button,
    Avatar,
    Segmented,
    Tabs,
} from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import "./productListTable.css";
import { toast } from "react-hot-toast";
import { FirebaseContext } from "src/services/firebaseContext";
import { BackendContext } from "src/services/backend";
import * as _ from 'lodash'
import { StepFeature } from "./components/stepFeature";
import { ModalTarget } from "./components/modalTarget";
import { BRAND, OWNERSHIP } from "src/constant/productModelNewVer";
import { StepHardware } from "./components/stepDevicesDevelop";
import { StepRelease } from "./components/stepRelease";
import yaml from 'js-yaml';
import Meta from "antd/es/card/Meta";
import { IoTFullDeviceType } from "src/constant/deviceType";
import { getImg } from "./productListTable";
import { ArrowLeftOutlined, ArrowRightOutlined, DownloadOutlined, UndoOutlined } from "@ant-design/icons";
export function deleteModuleKeys(input) {
    for (const key in input) {
        if (key.startsWith("module")) {
            delete input[key];
        } else if (typeof input[key] === "object") {
            deleteModuleKeys(input[key]);
        }
    }
}

export const CompleteProduct = () => {
    const stepName = [
        // 'smartmode',
        'feature',
        'hardware',
        'download',
        'brand',
        'release'
    ]
    const { Option } = Select;

    const { productId } = useParams()
    const be = useContext(BackendContext);
    const { defaultOrg } = useContext(FirebaseContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [current, setCurrent] = useState(stepName.findIndex(x => x === searchParams.get('step')));
    const [productInfo, setProductInfo] = useState<any>();
    const [loading, setLoading] = useState<boolean>()
    const [counter, setCounter] = useState(0);
    const [productChoice, setProductChoice] = useState()
    const navigate = useNavigate();

    const incrementCounter = () => {
        setCounter(counter + 1);
    };
    useEffect(() => {
        getCloudProduct()
    }, [counter])
    const handleChangeStep = (value: number) => {
        setSearchParams({
            step: stepName[value],
        });
        setCurrent(value)
    };
    const confirmEdit = async (body: object, brandAndOwnership: boolean = false) => {
        setLoading(true)
        const { extraInfo, developmentInfo } = productInfo
        let buildBody
        if (!brandAndOwnership) {
            buildBody = {
                developmentInfo: {
                    ...developmentInfo,
                    ...body
                }
            }
        } else {
            buildBody = {
                developmentInfo: {
                    ...developmentInfo,
                },
                ...body
            }
        }
        const data = await be.modeleditProductId(defaultOrg, productId, buildBody);
        if (data) {
            toast.success('Update Successfully')
        }
        setLoading(false)
        incrementCounter()
    };
    const getCloudProduct = async () => {
        setLoading(true)
        const getProductId = await be.modelgetProductIdDevelopment(defaultOrg, productId);
        setProductInfo(getProductId);
        setProductChoice(getProductId['developmentInfo'] && getProductId['developmentInfo']['moduleType'])
        setLoading(false)
    };
    const [open, setOpen] = useState(false)
    const getDownloadUrl = async (path) => {
        const downloadUrl = await be.firmwareGetDownloadUrl(defaultOrg, path)
        window.open(downloadUrl, "_blank");
    }
    const items = [
        {
            title: <>
                <div>Select Features Function </div>
            </>,
            icon: <><p className="p-2 m-2 border rounded bg-success circle rounded-circle"></p></>,
            description: <>

                {productInfo &&
                    productInfo['developmentInfo'] &&
                    productInfo['developmentInfo']['moduleName'] &&
                    <small className="bg-warning pl-3 pr-3 pt-1 pb-1">!!! You have selected module hardware in <b><i>Select Devices Development</i></b> step. <b><i>If you edit this steps</i></b> , all hardware module you choose before <b><i className="text-danger">will be deleted</i></b></small>
                }
                <div className="m-2 row" >
                    <>
                        <StepFeature
                            productInfo={productInfo}
                            incrementCounter={incrementCounter}
                        />
                        <ModalTarget
                            productInfo={productInfo}
                            featureTarget={[true, { attrs: [] }]}
                            setOpen={setOpen}
                            open={open}
                            be={be} incrementCounter={incrementCounter} />
                        <div className="col-12">
                            <div className="col-6">
                                <div
                                    onClick={() => setOpen(true)}
                                    className="mt-2 list-group-item list-group-item-action flex-column align-items-start"
                                >
                                    <div
                                        style={{ cursor: "pointer" }}
                                        className="d-flex w-100 justify-content-center"
                                    >
                                        <b>Add Element +</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </>
        },
        {
            title: 'Select Devices Development',
            icon: <><p className="p-2 m-2 border rounded bg-success circle rounded-circle"></p></>,
            description: <>
                {productInfo && <StepHardware
                    currentRootStep={current}
                    productInfo={productInfo}
                    smartmode={productInfo['developmentInfo']['smartMode']}
                    incrementCounter={incrementCounter}
                    counter={counter}
                    confirmEdit={confirmEdit}
                    productChoice={productChoice}
                    setProductChoice={setProductChoice} />}
            </>
        },
        // {
        //     title: 'Download Document',
        //     icon: <><p className="p-2 m-2 border rounded bg-success circle rounded-circle"></p></>,
        //     description: <div className="row">
        //         {productInfo && <div className="col-6" >
        //             {
        //                 productInfo['developmentInfo']['moduleType'] === 'MODULE' ? <div className=" ">
        //                     <pre style={{ backgroundColor: 'whitesmoke' }} className="p-2">
        //                         <div className="d-flex justify-content-center align-items-center">
        //                             <h3 className="font-weight-bold">{productInfo['developmentInfo']['moduleName']}</h3>
        //                         </div>
        //                         <code className="font-weight-bold ">
        //                             {
        //                                 yaml.dump(productInfo['developmentInfo']['moduleDetail'])
        //                             }
        //                         </code>
        //                     </pre>
        //                 </div> : <div className="">
        //                     {productInfo['developmentInfo']['moduleDetail'] && <Card
        //                         hoverable={false}
        //                         className={`card-selected`}
        //                         style={{
        //                             marginTop: 10,
        //                             border: '1px solid #005cff'
        //                         }}
        //                     >
        //                         <div className="d-flex">
        //                             <div className="mr-2 align-items-center d-flex">
        //                                 <img
        //                                     width={70}
        //                                     alt="img"
        //                                     src={
        //                                         productInfo['developmentInfo']['moduleDetail']['image'] ? productInfo['developmentInfo']['moduleDetail']['image'] : getImg(Object.keys(IoTFullDeviceType)[
        //                                             Object.values(IoTFullDeviceType).findIndex(
        //                                                 (z) => z === productInfo['developmentInfo']['moduleDetail']["categoryInfo"][0],
        //                                             )
        //                                         ])}
        //                                 />
        //                             </div>
        //                             <Meta title={<>
        //                                 <div>{productInfo['developmentInfo']['moduleDetail']['name']}</div>
        //                                 <small>{productInfo['developmentInfo']['moduleDetail']['modelId']}</small>
        //                             </>} description={productInfo['developmentInfo']['moduleDetail']['description']} />
        //                         </div>
        //                     </Card>
        //                     }
        //                 </div>
        //             }
        //         </div>}
        //         <div className="col-6">
        //             {
        //                 productInfo && productInfo['developmentInfo']['solution'] === 'NO_CODE' ?
        //                     <div className=" ">
        //                         <div>
        //                             <h3>Download Firmware</h3>
        //                             <small>Wait till admin upload firmware for your product. More information please contact <b>ninh.dh@rogo.com.vn</b></small>
        //                         </div>
        //                         {
        //                             productInfo['developmentInfo']['moduleFirmware'] ?
        //                                 <div className="mt-4 d-flex align-items-center">
        //                                     <b></b><Button
        //                                         className="d-flex justify-content-center align-items-center font-weight-bold"
        //                                         style={{ color: "#1677ff" }}
        //                                         onClick={() => getDownloadUrl(productInfo['developmentInfo']['moduleFirmwarePath'])}
        //                                     >
        //                                         {productInfo['developmentInfo']['moduleFirmwarePath']}
        //                                         <DownloadOutlined className="ml-2" rev={undefined} />
        //                                     </Button>
        //                                 </div> :
        //                                 <>
        //                                     {loading && (
        //                                         <div className="spinner spinner-success mr-3"></div>
        //                                     )}
        //                                     {!loading && <Button
        //                                         className="d-flex justify-content-center align-items-center font-weight-bold"
        //                                         style={{ color: "#1677ff" }}
        //                                         onClick={() => {
        //                                             setLoading(true)
        //                                             incrementCounter()
        //                                             setLoading(false)
        //                                         }}
        //                                     >
        //                                         Refresh
        //                                         <UndoOutlined className="ml-2" rev={undefined} />
        //                                     </Button>}
        //                                 </>
        //                         }
        //                     </div>
        //                     :
        //                     <div className=" ">
        //                         <div>
        //                             <h3 className="mb-0">Download SDK</h3>
        //                             <small>Download SDK and develop on your own, contact <b>mrtan@rogo.com.vn</b> for more information</small>
        //                         </div>
        //                         <Button
        //                             className="d-flex mt-3 justify-content-center align-items-center font-weight-bold"
        //                             style={{ color: "#1677ff" }}
        //                             onClick={() => getDownloadUrl(`sdk/SDK.pdf`)}
        //                         >
        //                             DAY LA FILE SDK 
        //                             <DownloadOutlined className="ml-2" rev={undefined} />
        //                         </Button>
        //                     </div>
        //             }
        //         </div>
        //     </div>
        // },
        // {
        //     title: 'Select Brand & Owner Ship',
        //     icon: <><p className="p-2 m-2 border rounded bg-success circle rounded-circle"></p></>,
        //     description: <>
        //         <div className="p-3 m-2 d-flex flex-column justify-content-start align-items center col-6" >
        //             {productInfo && <Form
        //                 name="validate_other"
        //                 onFinish={(values) => confirmEdit(values, true)}
        //                 style={{ minWidth: 300 }}
        //             >
        //                 <Form.Item
        //                     name="brand"
        //                     label="Brand"
        //                     initialValue={productInfo['brand']}
        //                     rules={[
        //                         {
        //                             required: true,
        //                             message: "Please select your product brand!",
        //                         },
        //                     ]}
        //                 >
        //                     <Select
        //                         placeholder="Please select a brand"
        //                     >
        //                         {Object.keys(BRAND).map((x, i) => {
        //                             return (
        //                                 <Option
        //                                     key={i.toString()}
        //                                     value={Object.values(BRAND)[i]}>{x}</Option>
        //                             );
        //                         })}
        //                     </Select>
        //                 </Form.Item>
        //                 <Form.Item
        //                     name="ownership"
        //                     label="Owner Ship"
        //                     initialValue={productInfo['ownership']}
        //                     rules={[
        //                         {
        //                             required: true,
        //                             message: "Please select a owner ship of the product!",
        //                         },
        //                     ]}
        //                 >
        //                     <Radio.Group
        //                     >
        //                         {Object.keys(OWNERSHIP).map((x, i) => {
        //                             return (
        //                                 <Radio
        //                                     key={i.toString()}
        //                                     value={Object.values(OWNERSHIP)[i]}>{x}</Radio>
        //                             );
        //                         })}
        //                     </Radio.Group>
        //                 </Form.Item>
        //                 <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        //                     <Space>
        //                         {loading && (
        //                             <div className="spinner spinner-success mr-3"></div>
        //                         )}
        //                         {!loading && (
        //                             <>
        //                                 <Button type="primary" htmlType="submit">
        //                                     Confirm
        //                                 </Button>
        //                             </>
        //                         )}

        //                     </Space>
        //                 </Form.Item>
        //             </Form>}
        //             {/* 
        //             <div className="d-flex justify-content-end align-items-center">
        //                 <button onClick={() => {
        //                     handleChangeStep(stepName[4]);
        //                     setCurrent(4)
        //                 }} className="btn btn-success ml-3">
        //                     Next
        //                 </button>
        //             </div> */}
        //         </div>
        //     </>
        // },
        // {
        //     title: 'Confirm & Release',
        //     icon: <><p className="p-2 m-2 border rounded bg-success circle rounded-circle"></p></>,
        //     description: <>
        //         {/* {productInfo && <StepRelease productInfo={productInfo} />} */}
        //     </>
        // },
    ]
    const checkDisable = (i) => {
        if (!productInfo || !productInfo['developmentInfo']) {
            return true
        }
        if (productInfo && productInfo['wrapFeatures'] && productInfo['wrapFeatures'].length === 0 && i !== 0) {
            return true
        }
        if (
            (
                (productChoice && productChoice === 'STORE') ||
                (productInfo['developmentInfo'] && !productInfo['developmentInfo']['moduleType'])
            ) &&
            i === 2
        ) {
            return true
        }
        return false
    }
    return (
        <>
            <h3>Product Development</h3>
            <div
                className="breadcrumb"
                style={{ borderBottom: "3px solid rgb(232, 237, 235)" }}
            >
                <ul className=" pb-2">
                    <li className="pl-0">
                        <a href="/org/project">Dashboard</a>
                    </li>
                    <li>Version 1</li>
                </ul>
            </div>
            <Segmented
                onChange={(value: number) => {
                    handleChangeStep(value);
                }}
                value={current}
                size='small'
                options={items.map((x, i) => {
                    return {
                        value: i,
                        disabled: checkDisable(i),
                        label: (
                            <div
                                key={i.toString()}
                                className={` `}
                                style={{
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    color: current === i && '#005cff',
                                }}
                            >
                                <div><b>{x['title']}</b> </div>
                            </div>
                        ),
                    }
                })}
            />
            <div className="row">
                <div className="col-12 mb-3">

                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="d-flex">
                                <Steps
                                    current={0}
                                    direction="vertical"
                                    items={[items[current]]}
                                />
                            </div>

                            <div className="d-flex justify-content-center align-items-center">

                                {
                                    searchParams.get('step') === 'hardware' && <Button
                                        type="dashed"
                                        onClick={() => {
                                            handleChangeStep(0)
                                        }}
                                        className="d-flex align-items-center mr-3"
                                    >
                                        <ArrowLeftOutlined rev={undefined} className="mr-3"/>
                                        <b>Back to choose Select Features Function</b>{" "}
                                    </Button>
                                }
                                <Button
                                    type="dashed"
                                    className="btn btn-success"
                                    onClick={() => {
                                        searchParams.get('step') === 'feature' ?
                                            handleChangeStep(1)
                                            : searchParams.get('step') === 'hardware' &&
                                            navigate(`/org/product/development?page=1&selectField=developing&modelId=${productInfo['modelId']}`)
                                    }}
                                >
                                    <b> {searchParams.get('step') === 'feature' ? `Select Devices Development` : 'Next Step'}</b>{" "}
                                    <ArrowRightOutlined rev={undefined} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
