import React, { useState } from "react";
import {
  Form,
  Divider,
  Input,
  Radio,
  Steps,
  Select,
  Menu,
  MenuProps,
  Space,
  Button,
  Tabs,
} from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  BRAND,
  DEVICE_SUBTYPE,
  OWNERSHIP,
  POWERTYPE,
  PROTOCOL,
} from "src/constant/productModelNewVer";
import "./productListTable.css";
import { IoTFullDeviceType } from "src/constant/deviceType";
import { toast } from "react-hot-toast";
import { FirebaseContext } from "src/services/firebaseContext";
import { BackendContext } from "src/services/backend";
import { getImg } from "./productListTable";


const Step1 = ({
  stepAdd,
  setStepAdd,
  setSearchParams,
  searchParams,
  currentDevType,
}) => {
  const setStep1Information = (
    data,
    indexDevSubtype,
    indexDevType,
    isDeviceType = false,
  ) => {
    const dataStepToAdd = {
      "1": {
        deviceType: isDeviceType ? data : indexDevType,
        deviceSubType: !isDeviceType ? data : -1,
        indexDevSubtype,
      },
    };
    setStepAdd(dataStepToAdd);
    setSearchParams((x) => {
      const result = {}
      for (const data of x) {
        result[data[0]] = data[1]
      }
      result['step'] = "2"
      return result
    })
  };
  return stepAdd && stepAdd["1"] ? (
    <>
      <div className=" rounded p-3">
        <div className="mt-2">
          <div className="">{stepAdd["1"]["deviceType"]}</div>
          <div className="d-flex row mb-3">
            <div className=" border rounded  ml-3 col-4">
              <img
                style={{ margin: "2px 6px ", width: 40 }}
                src={getImg(stepAdd["1"]["deviceType"])}
              ></img>
              <span className=" ">
                {stepAdd["1"]["deviceSubType"] === -1
                  ? stepAdd["1"]["deviceType"]
                  : stepAdd["1"]["deviceSubType"]}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="border shadow rounded p-3 m-2" >
      <div className="container-fluid">
        {Object.keys(IoTFullDeviceType)
          .filter((x) => x === currentDevType)
          .map((x, i) => {
            const img = getImg(x);
            return (
              <div key={i} className="">
                <div className="span-devtype">{x}</div>
                <div className="d-flex row mb-3">
                  <div
                    onClick={() => setStep1Information(x, -1, i, true)}
                    style={{ width: 250, cursor: "pointer" }}
                    className="  mt-3 item-devtype border rounded pl-3 pr-3 pt-2 pb-2 ml-3"
                  >
                    <img
                      style={{ margin: "2px 6px ", width: 40 }}
                      src={img}
                    ></img>
                    <span className=" ">{x}</span>
                  </div>
                  {DEVICE_SUBTYPE[IoTFullDeviceType[x]] &&
                    Object.values(DEVICE_SUBTYPE[IoTFullDeviceType[x]]).map(
                      (y, j): any => {
                        return (
                          <div
                            key={j}
                            onClick={() => setStep1Information(y, j, x)}
                            style={{ width: 300, cursor: "pointer" }}
                            className=" mt-3 item-devtype border rounded pl-3 pr-3 pt-2 pb-2 ml-3"
                          >
                            <img style={{ width: 40 }} src={img}></img>
                            <span className=" ">
                              {JSON.stringify(y).replace(/"/g, "")}
                            </span>
                          </div>
                        );
                      },
                    )}
                </div>
                <Divider className=" m-0 pb-1" />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const Step2 = ({ stepAdd, setStepAdd, setSearchParams, searchParams }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { defaultOrg } = React.useContext(FirebaseContext);
  const navigate = useNavigate()
  const be = React.useContext(BackendContext);
  const { projectId } = useParams();
  const setStep2Information = (data) => {
    const dataStepToAdd = {
      "2": {},
    };
    setStepAdd(dataStepToAdd);

    setSearchParams((x) => {
      const result = {}
      for (const data of x) {
        result[data[0]] = data[1]
      }
      result['step'] = "3"
      return result
    })
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const onFinish = async (values: any, solution: string) => {
    setLoading(true)
    const ownership = searchParams.get('type')
    const productModel = {
      name: values["name"],
      brand: values["brand"],
      description: values["description"],
      protocol: values["protocol"],
      devType: stepAdd["1"]["deviceType"],
      subDevType: stepAdd["1"]["indexDevSubtype"],
      ownership: ownership === '3rd' ? '3RD' : values["OWNERSHIP"],
      element: values["element"],
      partnerId: defaultOrg,
      projectId: projectId,
      powerType: values["powerType"],
      developmentInfo: {
        solution
      }
    };
    const addProductInfo = await be.modelEncodeProductInfo(productModel);
    if (addProductInfo) {
      toast.success("Create Successfully");
      navigate(`/org/product/development/${addProductInfo[0]['modelId']}?step=feature`)
    }
    setLoading(false)
  };
  const { Option } = Select;
  return (
    stepAdd &&
    stepAdd["1"] && (
      <div className="border shadow rounded p-3 m-2" style={{ minWidth: 610 }}>
        <div className="container">
          <Tabs
            defaultActiveKey="1"
            size={`large`}
            items={new Array(2).fill(null).map((_, i) => {
              const id = String(i + 1);
              return {
                label: i === 0 ? `No Code Developement` : `Iot SDK`,
                key: id,
                children:
                  <Form
                    name="validate_other"
                    {...formItemLayout}
                    onFinish={(values) => onFinish(values, i === 0 ? 'NO_CODE' : i === 1 ? 'SDK' : '')}
                    style={{ minWidth: 300 }}
                  >
                    <Form.Item
                      name={`name`}
                      label="Product Name"
                      rules={[
                        { required: true, message: "Please enter your product name!" },
                      ]}
                    >
                      <Input placeholder={`Enter product name`} />
                    </Form.Item>
                    <Form.Item
                      className=""
                      name={`description`}
                      label="Product Description"
                    >
                      <Input.TextArea placeholder="Enter product description" />
                    </Form.Item>
                    <Form.Item
                      name="protocol"
                      label="Protocols"
                      rules={[{ required: true, message: "Please select a protocol!" }]}
                    >
                      <Radio.Group>
                        {
                          i === 0 ? <Radio value={`Wile`}>{`Wile`}</Radio> : Object.keys(PROTOCOL).map((x, i) => {
                            return (
                              <>
                                <Radio value={x}>{x}</Radio>
                              </>
                            );
                          })
                        }
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name="powerType"
                      label="Power Type"
                      rules={[{ required: true, message: "Please select powerType!" }]}
                    >
                      <Radio.Group>
                        {
                          Object.keys(POWERTYPE).map((x, i) => {
                            return (
                              <>
                                <Radio value={Object.values(POWERTYPE)[i]}>{x}</Radio>
                              </>
                            );
                          })
                        }
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                      <Space>
                        {loading && (
                          <div className="spinner spinner-success mr-3"></div>
                        )}
                        {!loading && (
                          <>
                            <Button type="primary" htmlType="submit">
                              Submit
                            </Button>
                            <Button htmlType="reset">reset</Button>
                          </>
                        )}

                      </Space>
                    </Form.Item>
                  </Form>
              };
            })}
          />
        </div>
      </div>
    )
  );
};
export const AddProduct = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [stepAdd, setStepAdd] = useState<string>();
  const [currentDevType, setCurrentDevType] = useState("LIGHT");
  const onChange = (value: number) => {
    if (value === 0) {
      setStepAdd(undefined);
    }
    handleChangeStep((value + 1).toString());
  };
  const handleChangeStep = (step) => {
    setSearchParams((x) => {
      const result = {}
      for (const data of x) {
        result[data[0]] = data[1]
      }
      result['step'] = step
      return result
    })
  };
  const step = [
    {
      title: stepAdd && stepAdd["1"] ? "Reselect" : "Select a product",
      description: (
        <Step1
          stepAdd={stepAdd}
          setStepAdd={setStepAdd}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          currentDevType={currentDevType}
        />
      ),
    },
    {
      title: "Complete product information",
      description: (
        <Step2
          stepAdd={stepAdd}
          setStepAdd={setStepAdd}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ),
    },
  ];

  const onClickChangeDevType: MenuProps["onClick"] = (e) => {
    setCurrentDevType(e.key);
    setSearchParams((x) => {
      const result = {}
      for (const data of x) {
        result[data[0]] = data[1]
      }
      result['step'] = "1"
      return result
    })
    setStepAdd(undefined);
  };

  const items: MenuProps["items"] = Object.keys(IoTFullDeviceType).map((x) => {
    return {
      label: x,
      key: x,
    };
  });
  return (
    <>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h4 className="mt-2">Add Product Step</h4>
                </div>
              </div>
              <Divider />
              <div className="d-flex">
                <Menu
                  onClick={onClickChangeDevType}
                  selectedKeys={[currentDevType]}
                  mode="vertical"
                  items={items}
                />
                <div className="ml-2">
                  <Steps
                    current={parseInt(searchParams.get("step")) - 1}
                    onChange={onChange}
                    items={step}
                    direction="vertical"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
