import axios from "axios";
var params = new URLSearchParams();
params.append("key", "admin");
function getDomain(url, subdomain?) {
  subdomain = subdomain || false;

  url = url.replace(/(https?:\/\/)?(www.)?/i, "");

  if (!subdomain) {
    url = url.split(".");

    url = url.slice(url.length - 2).join(".");
  }

  if (url.indexOf("/") !== -1) {
    return url.split("/")[0];
  }
  return url;
}
const hostName = getDomain(window.location.hostname, true);
export const baseURL =
  hostName === "localhost"
    ? "http://localhost:9000/api/v1"
    : hostName === "iot.rogo.com.vn"
    ? "https://openapi.rogo.com.vn/api/v1"
    : hostName === "dashboard.fptsmarthome.vn"
    ? "https://openapi.rogo.com.vn/api/v1"
    : hostName === "iot-stag.rogo.com.vn" &&
      "https://staging.openapi.rogo.com.vn/api/v1";
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    "Access-Control-Allow-Credentials": "true",
  },
  params,
});

export default axiosInstance;
