import { UploadOutlined, DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Drawer, Popconfirm, Space, Upload, Form, Input, Radio, Switch, InputNumber } from "antd"
import { useForm } from "antd/es/form/Form";
import React, { useContext, useEffect, useState } from "react"
import toast from "react-hot-toast";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";

export const FirmwareDrawer = ({
    current,
    setCurrent,
    getNeedFirmware,
    getAllFirmwares
}) => {
    const [fileList, setFileList] = useState([]);
    const [firmware, setFirmware] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [prompterase, setPromperase] = useState<boolean>()
    const [encryptbin, setEncryptbin] = useState<boolean>()
    const be = useContext(BackendContext);
    const { defaultOrg } = useContext(FirebaseContext);
    const [form] = useForm()
    const onClose = () => {
        setCurrent(false);
    };
    useEffect(() => {
        getProductFirmware()
    }, [])
    const getProductFirmware = async () => {
        const productFirmware = await be.firmwareGetAll(defaultOrg, current['modelId'])
        const getfirm = productFirmware[0]
        setFirmware(getfirm)
        // form.setFieldValue('chipFamily', getfirm && getfirm['flashJsonData'] && getfirm['flashJsonData']['builds'][0]['chipFamily'])
        // form.setFieldValue('offset', getfirm && getfirm['flashJsonData'] && getfirm['flashJsonData']['builds'][0]['parts'][0]['offset'])
        // setPromperase(getfirm && getfirm['flashJsonData'] && getfirm['flashJsonData']['new_install_prompt_erase'])
        // setEncryptbin(getfirm && getfirm['flashJsonData'] && getfirm['flashJsonData']['encrypt_bin'])
        // form.setFieldValue('path', getfirm && getfirm['flashJsonData'] && getfirm['flashJsonData']['builds'][0]['parts'][0]['path'])
    }

    const handleUploadChange = async (info, productInfo) => {
        setCurrent(productInfo)
        let fileList = [...info.fileList];
        // Remove all files except the latest one
        fileList = fileList.slice(-1);
        form.setFieldValue('path', info.file)
        setFileList(fileList);
    };
    const handleRemove = () => {
        setFileList([]);
        form.setFieldValue('path', undefined)
    };
    const sampleData = {
        "name": `Firmware ${current['modelId']}`,
        "version": "1.0.0.0",
        "new_install_prompt_erase": false,
        "flash_size": "4MB",
        "flash_mode": "dio",
        "flash_freq": "80m",
        "encrypt_bin": false,
        "new_install_speed": 460800,
        "new_install_improv_wait_time": 0,
        "builds": [
            {
                "chipFamily": "ESP32-C3",
                "parts": [
                    {
                        "path": "./WILE_SOCKET_4MB.bin",
                        "offset": 0
                    }
                ]
            }
        ]
    }

    const renderInput = (inputData) => {
        const result = []
        for (const key in inputData) {
            if (inputData.hasOwnProperty(key)) {
                const data = inputData[key]
                if (typeof data === 'string') {
                    result.push(
                        <Form.Item
                            name={key}
                            label={key}
                            rules={[
                                { required: true },
                            ]}
                            initialValue={data}
                        >
                            <Input placeholder={key} />
                        </Form.Item>
                    )
                } else if (typeof data === 'number') {
                    result.push(
                        <Form.Item
                            name={key}
                            label={key}
                            rules={[
                                { required: true },
                            ]}
                            initialValue={data}
                        >
                            <InputNumber placeholder={key} />
                        </Form.Item>
                    )
                } else if (typeof data === 'boolean') {
                    // const getDataBoolean = key === 'new_install_prompt_erase' ? prompterase : key === 'encrypt_bin' ? encryptbin : false
                    result.push(
                        <Form.Item
                            name={key}
                            label={key}
                            valuePropName={key}
                            rules={[
                                { required: true },
                            ]}
                            initialValue={data}
                        >
                            <Switch
                                // checked={data}
                                onChange={(e) => {
                                    // key === 'new_install_prompt_erase' ? setPromperase(!prompterase) : key === 'encrypt_bin' ? setEncryptbin(!encryptbin) : false
                                }}
                            />
                        </Form.Item>
                    )
                }
            }
        }
        return result.flat()
    }
    const confirmUploadFirmware = async (data) => {

        setLoading(true);
        const info = data['path']
        const formData = new FormData();
        formData.append("file", info, info.name);
        formData.append(
            "path",
            current['modelId'],
        );
        formData.append(
            "jsonfile",
            JSON.stringify(data),
        );
        formData.append(
            "releaseStatus",
            current['releaseStatus'],
        );
        const createOtafolder = await be.firmwareUploadModuleYaml(current['partnerId'], formData);
        if (createOtafolder) {
            toast.success("Succesfully upload");
            await getNeedFirmware();
            await getAllFirmwares();
            setCurrent(undefined)
        }
        setLoading(false);
    }
    const getDownloadUrl = async (path) => {
        const downloadUrl = await be.firmwareGetDownloadUrl(defaultOrg, path)
        window.open(downloadUrl, "_blank");
    }
    return (
        <>

            <Drawer
                title={`Firmware Request for "${current['name']}"`}
                placement={`right`}
                closable={false}
                onClose={onClose}
                keyboard={false}
                open={!!current['modelId']}
                key={`right`}
                width={`600px`}
                closeIcon={true}
            >
                <div>
                    <Form
                        name="validate_other"
                        style={{ minWidth: 300 }}
                        onFinish={(x) => confirmUploadFirmware(x)}
                        form={form}
                    >
                        {renderInput(sampleData)}
                        {/* {(!firmware || !firmware['flashJsonData']) ? renderInput(sampleData) : renderInput(firmware['flashJsonData'] )} */}
                        <Form.Item
                            name={`builds`}
                            label={`builds`}
                        >
                            <Space className="d-flex">
                                <Space.Compact direction="vertical">
                                    <Form.Item
                                        name={`chipFamily`}
                                        label={`chipFamily`}
                                        rules={[
                                            { required: true },
                                        ]}
                                        initialValue={`ESP32-C3`}
                                    >
                                        <Input placeholder={`ESP32-C3`} />
                                    </Form.Item>
                                    <Form.Item
                                        name={`path`}
                                        label={`path`}
                                        valuePropName="path"
                                        rules={[
                                            { required: true },
                                        ]}
                                    >
                                        <Upload
                                            name={`path`}
                                            multiple={false}
                                            fileList={fileList}
                                            maxCount={1}
                                            beforeUpload={() => { return false }}
                                            onChange={(file) => handleUploadChange(file, current)}
                                            showUploadList={false}
                                            accept=".bin"
                                        >
                                            <Button type="primary" icon={<UploadOutlined rev={undefined} />}>
                                                Upload {current['releaseStatus'] === 'releasing' ? `` : ``}
                                            </Button>
                                        </Upload>

                                        {current['developmentInfo']['moduleFirmwarePath'] && <div className=" d-flex align-items-center">
                                            <Button
                                                className="mt-2 d-flex justify-content-center align-items-center font-weight-bold "
                                                style={{ color: "#1677ff" }}
                                                onClick={() => getDownloadUrl(current['developmentInfo']['moduleFirmwarePath'])}
                                            >
                                                {current['developmentInfo']['moduleFirmwarePath']}
                                                <DownloadOutlined className="ml-2" rev={undefined} />
                                            </Button>
                                        </div>}
                                        {fileList && current && current['modelId'] === current['modelId'] && fileList.map((file) => (
                                            <div key={file.uid}>
                                                <Space className="mt-3">
                                                    <span>{file.name}</span>
                                                    <Button
                                                        icon={<DeleteOutlined rev={undefined} />}
                                                        onClick={() => handleRemove()}
                                                    >
                                                    </Button>
                                                </Space>
                                                {file.preview}
                                            </div>
                                        ))}
                                    </Form.Item>
                                    <Form.Item
                                        name={`offset`}
                                        label={`offset`}
                                        rules={[
                                            { required: true },
                                        ]}
                                        initialValue={0}
                                    >
                                        <InputNumber placeholder={`offset`} />
                                    </Form.Item>
                                </Space.Compact>
                            </Space>
                        </Form.Item>
                        {loading && (
                            <div className="spinner spinner-success mr-3"></div>
                        )}
                        {!loading && <Button type="primary" htmlType="submit">
                            Submit
                        </Button>}
                        <Button htmlType="reset">reset</Button>
                    </Form>
                </div>
            </Drawer >
        </>
    )
}