export class IoTManufacturer {
  public static UNKNOWN = -1;
  public static ROGO = 0;
  public static FPT_PLAY = 1;
  public static FPT_CAM = 2;
  public static AQARA = 30;
  public static TUYA = 40;
  public static YALE = 50;

  public static RANDONG = 99;
  public static DIENQUANG = 100;
  public static ROGO_RAL_OEM_FPT_PLAY = 101;

  public static HIK = 214;
  public static HONEYWELL = 215;
  public static AOSMITH = 216;
  public static APPLE = 254;
  public static OTHER = 255;

  public static XIAOMI = 500;
  public static VIVO = 501;
  public static SKYWORTH = 502;
  public static HUAWEI = 503;
  public static HINSENSE = 504;
  public static GALANZ = 504;

  public static SAMSUNG = 1000;
  public static SONY = 1001;
  public static LG = 1002;
  public static SHARP = 1003;
  public static TCL = 1004;
  public static CASPER = 1005;
  public static TOSHIBA = 1006;

  public static PANASONIC = 1007;
  public static LENOVO = 1008;
  public static HITACHI = 1009;
  public static DAIKIN = 1010;
  public static MIDEA = 1011;
  public static GREE = 1012;
  public static ELECTRONUX = 1013;
  public static FUJITSU = 1014;
  public static HUYNDAI = 1015;
  public static MITSUBISHI_ELECTRIC = 1016;
  public static SANYO = 1017;
  public static BEKO = 1018;
  public static PHILIPS = 1019;
  public static AQUA = 1020;
  public static HAIER = 1021;
  public static CORONA = 1022;
  public static NEC = 1023;
  public static NATIONAL = 1024;
  public static YORK = 1025; //USA
  public static CHIGO = 1026; //HONGKONG
  public static JVC = 1027;
  public static VIZIO = 1028; //HONGKONG
  public static NAGAKAWA = 1029; //HONGKONG

  //    public static AOC = 1300;
  //    public static VIEWSONIC = 1301;
  //    public static LOGITECH = 1302;
  //    public static FOXCONN = 1303;
  //    public static BOE = 1304;
  //    public static CASIO = 1305;
  //    public static RICOH = 1306;
  //    public static FUJIFILM = 1307;
  //    public static OLYMPIC = 1308;
  //
  //
  //
  //    public static AIWA = 1500;
  //    public static KLIPSCH = 1501;
  //    public static BOSTON_ACOUSTIC = 1502;
  //    public static AUDIO_TECHNICA = 1503;
  //    public static MACINTOSH = 1504;
  //    public static AUDIOLAB = 1505;
  //    public static BOSE = 1506;
  //    public static KENWOOD = 1507;
  //    public static HARMAN_KARDON = 1508;
  //    public static ALTEC_LANSING = 1509;
  //    public static CANON = 1510;
  //    public static VICTOR = 1511;
  //    public static MCINTOSH = 1512;
  //    public static NAIM = 1513;
  //    public static MICROLAB = 1513;
  //    public static PIONEER = 1514;
  //
  //
  //
  //    public static MOTOROLA = 2000;
  //    public static SIEMENS = 2001;
  public static DAEWOO = 2002;
  public static MITSUBISHI = 2003;
  public static MITSUBISHI_INDUSTRY = 2004;
}
