import {
  ArrowRightOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
  FileProtectOutlined,
  FolderOpenOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Divider,
  Modal,
  Upload,
  UploadProps,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  IoTProtocol,
  IoTProtocolToImage,
} from "src/constant/protocol";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import * as _ from "lodash";
import { Can } from "src/services/caslContext";
import { Action } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StepRelease } from "./components/stepRelease";
import { BrandAndOwnerShip } from "./components/brandAndOwnership";
import { DownloadDocument } from "./components/downloadDocument";
import { OTADrawer } from "./components/otaDrawer";
import { ProductBaseInformation } from "./components/productBaseInformation";
export const EditProduct = ({
  setProductType,
  productType,
  productInfo,
  sharePartner,
  setSharePartner,
  setProductName,
  productName,
  setProductInfo,
  incrementCounterRoot,
  setListOTA,
  listOTA,
  setSizeWidth,
  productDescription,
  setProductDescription,
  setTab = undefined,
  form = undefined,
}) => {
  useEffect(() => {
  }, []);
  const [searchParams, setSearchParams] = useSearchParams();
  const [counter, setCounter] = useState(0);
  const be = useContext(BackendContext);
  const { defaultOrg } = useContext(FirebaseContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [verInput, setVerInput] = useState<boolean>(false);
  const navigate = useNavigate()
  const incrementCounter = () => {
    setCounter(counter + 1);
  };

  const closeEdit = () => {
    setProductName();
    setProductInfo();
    setProductType();
    setSharePartner();
    incrementCounter();
    setSizeWidth(12)
  };
  const showDrawer = async () => {
    setLoading(true);
    setOpen(true);
    await getListOTAFile(productInfo["modelId"]);
    setLoading(false);
  };

  const getListOTAFile = async (productId) => {
    const listInDb: any = await be.modelGetObjectInS3(defaultOrg, productId);
    setListOTA(transformObjectToMenuItems(listInDb));
  };
  const props: UploadProps = {
    accept: ".bin",
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      size: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    async onChange(info) {
      if (info.file.status === "done") {
        toast.success(`${info.file.name} file uploaded successfully`);
        setLoading(true);
        await getListOTAFile(productInfo["modelId"]);
        setLoading(false);
      } else if (info.file.status === "error") {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
    maxCount: 1,
  };
  function transformObjectToMenuItems(obj) {
    // TL;DR: this code for transforming server response s3 bucket information into menu of antd format
    // ref for antd format: https://ant.design/components/menu
    const tempObj = obj;
    const menuItems = [];
    for (const key in _.omit(obj, ["key"])) {
      if (obj.hasOwnProperty(key)) {
        const item = obj[key];
        const children =
          typeof item === "object" && item !== null
            ? transformObjectToMenuItems(item)
            : [];

        const menuItem = {
          key: key + Math.random(),
          icon: <SettingOutlined rev={undefined} />,
          label: key,
        };
        if (!key.includes(".bin")) {
          menuItem["children"] = children;
        }
        menuItems.push(menuItem);
        const emptyFolder = Object.keys(_.omit(item, ["key"])).length === 0;
        if (key.includes(".bin") || (emptyFolder && key !== "latest")) {
          menuItem["icon"] = <FileProtectOutlined rev={undefined} />;
          menuItems.push({
            key: "upload" + Math.random(),
            style: { display: "flex", padding: 0 },
            label: (
              <>
                <Upload
                  customRequest={async ({
                    file,
                    onProgress,
                    onSuccess,
                    onError,
                  }: any) => {
                    const formData = new FormData();
                    formData.append("file", file, file.name);
                    formData.append("modelId", productInfo["modelId"]);
                    formData.append(
                      "path",
                      emptyFolder ? tempObj[key]["key"] : item,
                    );

                    onProgress(() => { });
                    const upload = await be.modelUploadBinfile(
                      defaultOrg,
                      formData,
                    );
                    onSuccess(() => { });
                    if (!upload) {
                      onError(() => { });
                    }
                  }}
                  {...props}
                >
                  <Button icon={<UploadOutlined rev={undefined} />}>
                    {emptyFolder ? `Upload` : `Replace this version`}
                  </Button>
                </Upload>
              </>
            ),
          });
        }
      }
    }

    return menuItems;
  }
  const onFinishVerInput = async (data) => {
    const createOtafolder = await be.modelCreateOTAFolder(defaultOrg, {
      modelId: productInfo["modelId"],
      path: data["path"],
    });
    if (createOtafolder) {
      toast.success("Create folder success");
      setLoading(true);
      await getListOTAFile(productInfo["modelId"]);
      setLoading(false);
    }
  };

  const releaseProduct = async () => {
    const release = await be.modelReleaseProduct(defaultOrg, productInfo['modelId'])
    if (release) {
      // window.location.href = '/org/product/development?page=1&selectField=releasing&modelId=' + release['modelId']
      navigate('/org/product/development?page=1&selectField=releasing&modelId=' + release['modelId'])
      setLoading(true)
      setTab(`releasing`)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      toast.success(`Release Product ${release['name']} Succesfully, Wait for admin to apprve the request`)
      setLoading(false)
    }
  }
  return (
    <>
      <div className="card text-left">
        <div className="card-body">
          <div>
            <div className="d-flex justify-content-between">
              <h4>
                <b>{productInfo["name"]}</b>
              </h4>
              <button
                type="button"
                onClick={closeEdit}
                className="btn btn-secondary mr-3 d-flex justify-content-center align-items-center"
              >
                <CloseOutlined rev={undefined} />
                <div className="ml-2">
                  Close
                </div>
              </button>
            </div>
            <Divider className="mb-2 mt-2" />

            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    className="d-flex justify-content-between align-items-center"
                    type="link"
                    onClick={showDrawer}
                  >
                    <FolderOpenOutlined rev={undefined} />
                    <div className=" ">
                      <b>Show OTA folder</b>{" "}
                    </div>
                  </Button>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Can
                  I={Action.ProductEditPartnerModel}
                  a={Resources.Product}
                >
                  {
                    <>
                      {!productInfo['isReadyOEM'] && searchParams.get('selectField') !== 'releasing' &&
                        <Button
                          type="link"
                          onClick={() => {
                            navigate(`/org/product/development/${productInfo['modelId']}?step=feature`)
                          }}
                        >
                          <b> Development Page</b>{" "}
                          <ArrowRightOutlined rev={undefined} />
                        </Button>}
                    </>
                  }
                </Can>
              </div>
            </div>
            <div>
            </div>
            <div className="text-align d-flex justify-content-center align-items-center">
              <img
                width={300}
                alt="img"
                src={`${window.location.origin +
                  "/assets/images" +
                  Object.values(IoTProtocol)
                    .map(
                      (z, i) =>
                        z.includes(parseInt(productInfo["baseInfo"][1])) &&
                        Object.values(IoTProtocolToImage)[i],
                    )
                    .filter((z) => !!z)
                    .join("")
                  }`}
              />
            </div>
            <div className="card-body">
              <ProductBaseInformation
                productInfo={productInfo}
                setProductInfo={setProductInfo}
                setProductName={setProductName}
                productName={productName}
                setProductDescription={setProductDescription}
                productDescription={productDescription}
                productType={productType}
                setProductType={setProductType}
                sharePartner={sharePartner}
                setSharePartner={setSharePartner}
                incrementCounter={incrementCounter}
                incrementCounterRoot={incrementCounterRoot}
              />
            </div>
            <div className="card-body">

              {
                !productInfo['isReadyOEM'] && searchParams.get('selectField') !== 'releasing' && <Collapse className="" collapsible="header" defaultActiveKey={['1']}>
                  <Collapse.Panel header={
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <b>Product Development Information</b>
                        <small className="ml-3">Complete your product development and release</small>
                      </div>
                    </div>
                  } key="1">
                    {/*  DOWNLOAD DOCUMENT */}
                    <div className="">
                      <div className="font-weight-bold mb-2" style={{ fontSize: 16 }}> Download Documents</div>
                      <DownloadDocument
                        productInfo={productInfo}
                        setProductInfo={setProductInfo}
                      />
                    </div>

                    {/*  BAND & OWNERSHIP */}
                    {productInfo &&
                      <>
                        <div className="font-weight-bold mt-5  mb-2" style={{ fontSize: 16 }}> Select Brand & Owner Ship</div>
                        <BrandAndOwnerShip
                          productInfo={productInfo}
                          setProductInfo={setProductInfo}
                          incrementCounter={incrementCounter}
                          form={form}
                        />
                      </>
                    }
                    {/*  RELEASE */}
                    <div className="font-weight-bold  mb-2" style={{ fontSize: 16 }}>Confirm & Release</div>
                    {productInfo && <StepRelease
                      releaseProduct={releaseProduct}
                      productInfo={productInfo}
                    />}
                  </Collapse.Panel>
                </Collapse>
              }
            </div>
          </div>
          <OTADrawer
            open={open}
            setOpen={setOpen}
            verInput={verInput}
            setVerInput={setVerInput}
            listOTA={listOTA}
            onFinishVerInput={onFinishVerInput}
          />
        </div>
      </div>
    </>
  );
};
