export enum Resources {
  Project = "project",
  User = "user",
  Report = "report",
  App = "app",
  Product = "product",
  Hardware = "hardware",
}

export const FULLPERMISSION_STRING = "*";
export const ROGO_RESOURCE_NAME_TO_OBJECT = {
  project: Resources.Project,
  user: Resources.User,
  report: Resources.Report,
  app: Resources.App,
  product: Resources.Product,
  hardware: Resources.Hardware,
};
export interface IAbac {
  resources: string[];
  actions: string[];
}
export type Subjects =
  | Resources.Project
  | Resources.User
  | Resources.Report
  | Resources.App
  | Resources.Product
  | Resources.Hardware
  | "all";
