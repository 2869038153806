import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Collapse, Popconfirm, Space, Table, Tabs, Tag } from "antd";
import React, { useContext } from "react"
import { IoTAttribute } from "src/constant/features";
const { Panel } = Collapse;
import * as _ from 'lodash'
import '../productListTable.css'
import Paragraph from "antd/es/skeleton/Paragraph";
import yaml from 'js-yaml';
import { PROTOCOL, POWERTYPE, BRAND, OWNERSHIP } from "src/constant/productModelNewVer";
import Meta from "antd/es/card/Meta";
import { IoTFullDeviceType } from "src/constant/deviceType";
import { getImg } from "../productListTable";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import { useNavigate } from "react-router-dom";

export const StepRelease = ({
    productInfo,
    releaseProduct
}) => {
    const navigate = useNavigate();
    const be = useContext(BackendContext);
    const { defaultOrg } = useContext(FirebaseContext);
    const checkEverythingOk = () => {
        if (
            (productInfo['extraInfo']['elmInfos']) &&
            (productInfo['extraInfo']) &&
            (Object.entries(productInfo['extraInfo']['elmInfos']).length !== 0) &&
            (productInfo['developmentInfo']['moduleType']) &&
            (productInfo['developmentInfo']['moduleDetail']) &&
            (productInfo['ownership'] !== '0000')
        ) {
            if (productInfo['developmentInfo']['moduleType'] === 'MODULE') {
                if (productInfo['developmentInfo']['moduleFirmware']) {
                    return true
                } else {
                    if (productInfo['developmentInfo']['solution'] === 'SDK') {
                        return true
                    }
                    return false
                }
            } else {
                return true                
            }
        } else {
            return false
        }
    }
    const generateSummaryProductInfo = () => {
        const elmInfosInDb = productInfo['extraInfo']['elmInfos']
        const protocols = [_.invert(PROTOCOL)[productInfo['baseInfo'][1]].toLowerCase()]
        const powerTypes = [_.invert(POWERTYPE)[productInfo['categoryInfo'][2]]]
        const developmentInfo = productInfo['developmentInfo']

        const features = []
        for (const elmInfo in elmInfosInDb) {
            if (elmInfosInDb.hasOwnProperty(elmInfo)) {
                const attrs = elmInfosInDb[elmInfo]['attrs']
                features.push(attrs)
            }
        }
        const uniqueFeaturesWithName = _.uniq(features.flat()).map(x => _.invert(IoTAttribute)[x] && _.invert(IoTAttribute)[x].toLowerCase())

        return <>
            <div className="d-flex mt-2 mb-2">
                <div>
                    {
                        Object.entries(developmentInfo).map((x: any, i) => {
                            return typeof x[1] !== 'object' && <div key={i} className="ml-3 mr-3"><b>{x[0]}</b>: {x[1]}</div>
                        })
                    }
                    <div className="ml-3 mr-3"><b>protocols</b>: {protocols.join(',')}</div>
                    <div className="ml-3 mr-3"><b>powerTypes</b>: {powerTypes.join(',')}</div>
                    <div className="ml-3 mr-3"><b>features</b>: {uniqueFeaturesWithName && uniqueFeaturesWithName.join(', ')}</div>
                </div>
            </div>
        </>
    }
    const renderTag = (data?) => {
        return !data ? <Tag icon={<CheckCircleOutlined rev={undefined} />} color="success">
            Complete
        </Tag> : <Tag icon={<ExclamationCircleOutlined rev={undefined} />} color="warning">
            Not Complete
        </Tag>
    }
    return <Space className="col-12" direction="vertical">
        <Collapse collapsible="header" defaultActiveKey={['1']}>
            <Panel header={
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <div>
                        <b>Device Features</b>
                    </div>
                    {renderTag(
                        (!productInfo['extraInfo']['elmInfos']) ||
                        (!productInfo['extraInfo']) ||
                        (Object.entries(productInfo['extraInfo']['elmInfos']).length === 0)
                    )}
                </div>
            } key="1">
                <div className="row">
                    {
                        productInfo && productInfo['extraInfo'] && productInfo['extraInfo']['elmInfos'] && Object.entries(productInfo['extraInfo']['elmInfos']).map((x: any, i) => {
                            return <div className="col-6">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="mb-2"><b>{'Element ' + x[0]}</b> </div>
                                </div>
                                <Table
                                    bordered={true}
                                    className="mb-5"
                                    pagination={false}
                                    size="small" dataSource={x[1] && x[1]['attrs'] && x[1]['attrs'].map((y, j) => {
                                        return {
                                            name: _.invert(IoTAttribute)[y],
                                            key: y,
                                        }
                                    })} columns={[
                                        {
                                            title: 'Feature Name',
                                            dataIndex: 'name',
                                        },
                                        {
                                            title: 'Feature Key',
                                            dataIndex: 'key',
                                        },
                                    ]}>
                                </Table>
                            </div>
                        })
                    }
                </div>
            </Panel>
        </Collapse>

        <Collapse collapsible="header" defaultActiveKey={['1']}>
            <Panel header={
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <div>
                        <b>Device Development</b>
                    </div>
                    {renderTag(
                        (!productInfo['developmentInfo']['moduleType']) ||
                        (!productInfo['developmentInfo']['moduleDetail']) ||
                        (productInfo['developmentInfo']['moduleType'] === 'MODULE' && !productInfo['developmentInfo']['moduleFirmware'])
                    )}
                </div>
            } key="1">
                <div className="">
                    {
                        generateSummaryProductInfo()
                    }
                    {
                        productInfo['developmentInfo']['moduleType'] === 'MODULE' ? <div className=" ">
                            <pre style={{ backgroundColor: 'whitesmoke' }} className="p-2">
                                <div className="d-flex justify-content-center align-items-center">
                                    <h3 className="font-weight-bold">{productInfo['developmentInfo']['moduleName']}</h3>
                                </div>
                                <code className="font-weight-bold ">
                                    {
                                        yaml.dump(productInfo['developmentInfo']['moduleDetail'])
                                    }
                                </code>
                            </pre>
                        </div> : <div className="">
                            {productInfo['developmentInfo']['moduleDetail'] && <Card
                                hoverable={false}
                                className={`card-selected`}
                                style={{
                                    marginTop: 10,
                                    border: '1px solid #005cff'
                                }}
                            >
                                <div className="d-flex">
                                    <div className="mr-2 align-items-center d-flex">
                                        <img
                                            width={70}
                                            alt="img"
                                            src={
                                                productInfo['developmentInfo']['moduleDetail']['image'] ? productInfo['developmentInfo']['moduleDetail']['image'] : getImg(Object.keys(IoTFullDeviceType)[
                                                    Object.values(IoTFullDeviceType).findIndex(
                                                        (z) => z === productInfo['developmentInfo']['moduleDetail']["categoryInfo"][0],
                                                    )
                                                ])}
                                        />
                                    </div>
                                    <Meta title={<>
                                        <div>{productInfo['developmentInfo']['moduleDetail']['name']}</div>
                                        <small>{productInfo['developmentInfo']['moduleDetail']['modelId']}</small>
                                    </>} description={productInfo['developmentInfo']['moduleDetail']['description']} />
                                </div>
                            </Card>
                            }
                        </div>
                    }
                </div>
            </Panel>
        </Collapse>

        <Collapse collapsible="header" defaultActiveKey={['1']}>
            <Panel header={
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <div>
                        <b>Brand & Ownership</b>
                    </div>
                    {renderTag(
                        (productInfo['ownership'] === '0000')
                    )}
                </div>
            } key="1">
                <div className="">
                    <div className=" mt-2 mb-2">
                        <div className="ml-3 mr-3"><b>Brand</b>: {_.invert(BRAND)[productInfo['brand']]}</div>
                        <div className="ml-3 mr-3"><b>Ownership</b>:  {productInfo['ownership'] === '0004'  ? '3rd' : _.invert(OWNERSHIP)[productInfo['ownership']]} </div>
                    </div>
                </div>
            </Panel>
        </Collapse>
        {checkEverythingOk() && <div className="w-100 d-flex justify-content-end">
            <Popconfirm title={<div className="text-danger"><b>Sure to <i>Release</i> product ? You just be able to edit <i>basic</i> information of product when already released</b></div>} onConfirm={(confirm) => releaseProduct()}  >
                <Button className="" type="primary" htmlType="submit">
                    Release Product
                </Button>
            </Popconfirm>
        </div>}
    </Space >
}