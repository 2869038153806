import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import { ProductlistTable } from "../product/productListTable";
import { IoTFullDeviceType } from "src/constant/deviceType";
import { IoTProtocol, IoTProtocolToName } from "src/constant/protocol";
import { CloseOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";

export const TabProduct = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [productInfo, setProductInfo] = React.useState<object>();
  const [productType, setProductType] = React.useState<boolean>();
  const [sharePartner, setSharePartner] = React.useState<object[]>();
  const [productName, setProductName] = React.useState<string>();
  const [selectedList, setSelectedList] = React.useState<object[]>([]);
  const [counter, setCounter] = React.useState(0);
  const [undo, setUndo] = React.useState(false);
  const [innerCounter, setInnerCounter] = React.useState(0);
  const be = React.useContext(BackendContext);
  const { defaultOrg } = React.useContext(FirebaseContext);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const incrementInnerCounter = () => {
    setInnerCounter(innerCounter + 1);
  };
  React.useEffect(() => {
    getSelectedList();
  }, [counter]);
  const getSelectedList = async () => {
    setLoading(true);
    const listInDb = await be.modelGetProjectProduct(defaultOrg, projectId);
    setSelectedList(listInDb);
    setLoading(false);
  };
  const handleSetSelectedList = (data, index, removestage = false) => {
    setUndo(true);
    if (
      selectedList.findIndex((x) => x["modelId"] === data["modelId"]) === -1
    ) {
      selectedList.push(data);
      setSelectedList(selectedList);
      incrementInnerCounter();
    }
    if (removestage) {
      const removeSelected = selectedList.filter(
        (x) => x["modelId"] !== data["modelId"],
      );
      setSelectedList(removeSelected);
      incrementInnerCounter();
    }
  };
  const addprojectproduct = async () => {
    setLoading(true);
    const bodyToAdd = {
      partnerId: defaultOrg,
      projectId,
      partnerProductId: selectedList.map((x) => {
        return {
          productId: x["modelId"],
        };
      }),
    };
    const addprojectproductData = await be.modelAddprojectproduct(bodyToAdd);
    if (addprojectproductData) {
      toast.success(`Successfully`);
      setLoading(false);
    }
    setUndo(false);
    setLoading(false);
  };
  return (
    <>
      <div className="row">
        <div className="col-6 mb-3">
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h4 className="mt-2 mb-0">Products</h4>
                  <small>
                    The products are listed below contain:{" "}
                    <b> the product is belong to your partner</b> ,{" "}
                    <b>all product marked as public</b>, and{" "}
                    <b>product that other partner share with yours</b>{" "}
                  </small>
                </div>
              </div>
              <div>
                <ProductlistTable
                  productInfo={productInfo}
                  setProductInfo={setProductInfo}
                  setProductType={setProductType}
                  setSharePartner={setSharePartner}
                  setProductName={setProductName}
                  counter={counter}
                  consumerStage={true}
                  handleSetSelectedList={handleSetSelectedList}
                  selectedList={selectedList}
                  setListOTA={undefined}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 mb-3">
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h4 className="mt-2 mb-0">Selected Products</h4>
                  <small>
                    <i>
                      App will be able to fetch information of product selected
                      which are listed below
                    </i>{" "}
                  </small>
                  <br />
                  <small>
                    You will be able to see all of your devices with that
                    productId by <b>Clicking</b> to product name in that section
                  </small>
                </div>
              </div>
              <div>
                <table id="" className="table text-left table-hover">
                  <thead>
                    <tr style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                      <th scope="col"></th>
                      <th scope="col">ProductId Information</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">
                        <div className="d-flex justify-content-left align-items-center pt-2">
                          <div>Protocol</div>
                          <div className="demo ">
                            <div
                              style={{
                                marginLeft: 10,
                                marginBottom: 1,
                                clear: "both",
                                whiteSpace: "nowrap",
                              }}
                            ></div>
                          </div>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="d-flex justify-content-left align-items-center pt-2">
                          <div>Device Type</div>
                          <div className="demo ">
                            <div
                              style={{
                                marginLeft: 10,
                                marginBottom: 1,
                                clear: "both",
                                whiteSpace: "nowrap",
                              }}
                            ></div>
                          </div>
                        </div>
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedList &&
                      selectedList.map((x, i) => {
                        return (
                          <tr>
                            <th
                              scope="d-block"
                              className="d-flex justify-content-center align-items-center"
                            >
                              {i + 1}
                            </th>
                            <th scope="d-block">
                              <span
                                style={{ color: "#016bf8", cursor: "pointer" }}
                                onClick={() => {
                                  navigate(
                                    `/project/${projectId}/product/${x["modelId"]}?page=1`,
                                  );
                                }}
                              >
                                {x["name"]}
                              </span>
                              <br />
                              <small>{x["modelId"]}</small>
                            </th>
                            <td>
                              {x["isPublic"] ? (
                                <span className=" ml-2 badge badge-pill badge-success">
                                  Public Product
                                </span>
                              ) : (
                                <span className="text-dark ml-2 badge badge-pill badge-warning">
                                  Share Product
                                </span>
                              )}
                            </td>
                            <td>
                              {Object.values(IoTProtocol)
                                .map(
                                  (z, i) =>
                                    z.includes(x["baseInfo"][1]) &&
                                    Object.values(IoTProtocolToName)[i],
                                )
                                .filter((z) => !!z)
                                .join("")}
                            </td>
                            <td>
                              {
                                Object.keys(IoTFullDeviceType)[
                                  Object.values(IoTFullDeviceType).findIndex(
                                    (z) => z === x["categoryInfo"][0],
                                  )
                                ]
                              }
                            </td>
                            <td
                              onClick={() => {
                                handleSetSelectedList(x, i, true);
                              }}
                            >
                              <CloseOutlined
                                rev={undefined}
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end">
                  {loading && (
                    <div className="spinner spinner-success mr-3"></div>
                  )}

                  {!loading && undo && (
                    <button
                      onClick={() => {
                        getSelectedList();
                        setUndo(false);
                      }}
                      className="btn btn-success mr-2"
                    >
                      Restore
                    </button>
                  )}
                  {!loading && (
                    <button
                      onClick={addprojectproduct}
                      className="btn btn-success"
                    >
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
