import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "src/components/pavigation";
import { Popconfirm } from "antd";
import { toast } from "react-hot-toast";

export const ProjectUserTable = ({
  activeUserDevices,
  setActiveUserDevices,
  canView,
  setLinkingInfo,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [size, setSize] = useState<number>(50);
  const [searchParams, setSearchParams] = useSearchParams();
  const [puid, setPartnerUserId] = useState<string>("+84");
  useEffect(() => {
    canView && getAllUser();
  }, []);
  const getAllUser = async (
    pageActual: number = parseInt(searchParams.get("page")),
    sizePerPage: number = size,
  ) => {
    setLoading(true);
    const data = await be.projectusergetAlluser(
      defaultOrg,
      projectId,
      pageActual,
      sizePerPage,
    );
    setData(data);
    setLoading(false);
  };
  const getPartnerUserDevices = async (puidDevices: string) => {
    const deviceProjectUser = await be.projectusergetPartnerUserDevices(
      defaultOrg,
      projectId,
      puidDevices,
    );
    setActiveUserDevices({
      data: deviceProjectUser,
      puid: puidDevices,
    });
  };

  const be = React.useContext(BackendContext);
  const { defaultOrg } = React.useContext(FirebaseContext);
  const { projectId } = useParams();
  const handleChangePage = (pageChange: number) => {
    setSearchParams({
      page: pageChange.toString(),
    });
    setLoading(true);
    getAllUser(pageChange);
    setLoading(false);
  };
  const handleSetSize = (sizePage: number) => {
    setSize(sizePage);
    getAllUser(parseInt(searchParams.get("page")), sizePage);
  };
  const filterByUserId = async () => {
    setLoading(true);
    if (puid) {
      const dataFilterByUserId = await be.projectusergetByPartnerUserId(
        defaultOrg,
        projectId,
        puid,
      );
      if (dataFilterByUserId) {
        setData({
          data: [dataFilterByUserId],
          page: parseInt(searchParams.get("page")),
          pageSize: data["pageSize"],
          totalPage: data["totalPage"],
        });
      } else {
        setData({
          data: [],
          page: parseInt(searchParams.get("page")),
          pageSize: data["pageSize"],
          totalPage: data["totalPage"],
        });
      }
    }
    if (!puid) {
      getAllUser();
    }
    setLoading(false);
  };
  const projectuserUpdateUserMode = async (
    puid: string,
    disableMode: boolean = false,
  ) => {
    const update = await be.projectuserUpdateUserMode({
      puid,
      partnerId: defaultOrg,
      projectId,
      userMode: disableMode ? 1 : 0,
    });
    if (update.success) {
      toast.success(`Success ${disableMode ? "disabled" : "enabled"}`);
      filterByUserId();
    } else {
      toast.error(`Failed to ${disableMode ? "disabled" : "enabled"}`);
    }
  };
  return (
    <>
      <Pagination
        data={data}
        handleChangePage={handleChangePage}
        searchParams={searchParams}
        setData={getAllUser}
        setSize={handleSetSize}
        size={size}
      />
      <div className="justify-content-start d-flex align-items-center mb-3">
        <div>
          <span className="mr-2">
            <b>Search With Partner User Id</b>
          </span>
          <div
            className="form-group mb-0 mr-3 d-flex "
            style={{ minWidth: 100 }}
          >
            <input
              onKeyPress={(e) => {
                e.key === "Enter" && filterByUserId();
              }}
              onChange={(e) => {
                setPartnerUserId(e.target.value);
              }}
              defaultValue={puid}
              placeholder="Type Partner User Id"
              id="Partner_User_Id"
              className="form-control form-control"
              type="text"
            />
            <div
              onClick={filterByUserId}
              className="input-group-append"
              style={{ cursor: "pointer" }}
            >
              <span
                className=" btn btn-primary"
                id="basic-addon2"
                style={{ zIndex: "auto" }}
              >
                OK
              </span>
            </div>
          </div>
        </div>
      </div>
      <table id="" className="table text-left table-hover">
        <thead>
          <tr style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
            <th scope="col">Partner User Id</th>
            <th scope="col">Created At</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <div className="spinner spinner-success mr-3"></div>
            </tr>
          )}
          {!loading &&
            data &&
            data.data.length > 0 &&
            data.data.map((x) => {
              const disable =
                (x["extraInfo"][0] && x["extraInfo"][0]["userMode"] === 1) ||
                (x["userMode"] && x["userMode"] === 1);
              return (
                <tr
                  className={`${disable ? "bg-light text-white" : ""}`}
                  style={{
                    background:
                      activeUserDevices &&
                      x["puid"] === activeUserDevices["puid"] &&
                      "aliceblue",
                  }}
                >
                  <td>
                    {" "}
                    <span>{x["puid"]}</span>
                    {disable ? (
                      <span className="badge badge-danger ml-1">Disable</span>
                    ) : (
                      <span className="badge badge-success ml-1">Active</span>
                    )}
                  </td>
                  <td>{new Date(x["firstCreatedAt"]).toLocaleString()}</td>

                  <td>
                    {disable ? (
                      <>
                        <Popconfirm
                          placement="topLeft"
                          title={`Sure to enable this user ?`}
                          description={``}
                          onConfirm={() => projectuserUpdateUserMode(x["puid"])}
                          okText="Yes"
                          cancelText="No"
                        >
                          <a
                            style={{ cursor: "pointer" }}
                            className="pr-2 text-white mr-2 font-weight-bold badge badge-pill badge-success pl-3 pr-3 pt-1 pb-1 "
                          >
                            Enable
                          </a>
                        </Popconfirm>
                      </>
                    ) : (
                      <>
                        <a
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                            getPartnerUserDevices(x["puid"]);
                            setLinkingInfo(x["extraInfo"]);
                          }}
                          style={{ cursor: "pointer" }}
                          className="pr-2 text-success mr-2 font-weight-bold"
                        >
                          View
                        </a>
                        <Popconfirm
                          placement="topLeft"
                          title={`Sure to Disabled this user ?`}
                          description={``}
                          onConfirm={() =>
                            projectuserUpdateUserMode(x["puid"], true)
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <a
                            style={{ cursor: "pointer" }}
                            className="pr-2 text-danger mr-2 font-weight-bold"
                          >
                            Disable
                          </a>
                        </Popconfirm>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};
