import { Auth } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackendAPI, BackendContext } from "src/services/backend";
import { auth } from "src/services/config";
import { FirebaseContext } from "src/services/firebaseContext";
import Modal from "react-modal";
import { ModalProjectAdd } from "./project/elements/modalProjectAdd";
import { Can } from "src/services/caslContext";
import { Action } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import "../../assets/modal.css";

export const Project = () => {
  const [project, setProject] = useState<Array<object>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { defaultOrg } = useContext(FirebaseContext);
  const [updateScreen, setUpdateScreen] = React.useState<any>(false);
  const be = useContext(BackendContext);
  const [show, setShow] = React.useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = React.useState<string | undefined>();
  useEffect(() => {
    getCloudProject();
    const projectName = localStorage.getItem("projectName");
    if (projectName) {
      localStorage.removeItem("projectName");
    }
  }, []);
  const navigate = useNavigate();

  const handleOnclick = (data: object) => {
    localStorage.setItem("projectName", data["name"]);
    navigate(`/project/${data["uuid"]}/overview`, { state: data });
  };
  const getCloudProject = async () => {
    setLoading(true);
    const getAll = await be.projectGetAll(defaultOrg);
    setProject(getAll);
    setLoading(false);
  };
  const openModal = () => {
    setShow(!show);
  };
  const deleteProject = async (uuid: string) => {
    setLoading(true);
    await be.projectDelete(defaultOrg, uuid);
    await getCloudProject();
    setLoading(false);
  };
  function modalDelete(uuid: string) {
    setIsOpen(uuid);
  }
  function closeModal() {
    setIsOpen(undefined);
  }
  return (
    <>
      <h3>Project</h3>
      <div
        className="breadcrumb"
        style={{ borderBottom: "3px solid rgb(232, 237, 235)" }}
      >
        <ul className=" pb-2">
          <li className="pl-0">
            <a href="/org/project">Dashboard</a>
          </li>
          <li>Version 1</li>
        </ul>
      </div>
      <Modal
        isOpen={show}
        // onRequestClose={modalDelete}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            background: "none!important",
            border: 0,
            width: "900px",
          },
        }}
      >
        <ModalProjectAdd
          show={show}
          openModal={openModal}
          getCloudProject={getCloudProject}
          updateScreen={updateScreen}
          setUpdateScreen={setUpdateScreen}
        />
      </Modal>
      <div className="row">
        <div className="col-8 mb-3">
          <div className="card text-left">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h4 className="mt-2">Available Project</h4>
                  <small>
                    Your organization is limited to 2 projects. Contact admin
                    for more information.
                  </small>
                </div>
                <div className="form-group m-0 ">
                  <button
                    onClick={openModal}
                    type="button"
                    className="btn btn-success btn-icon m-1"
                  >
                    <span className="ul-btn__icon">
                      <i className="i-Add"></i>
                    </span>
                    Add Project
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <div className="table-responsive">
                  <table id="" className="table text-left table-hover">
                    <thead>
                      <tr style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                        <th scope="col">Project Name/ Description</th>
                        <th scope="col">Org</th>
                        <th scope="col">Creation Time</th>
                        <th scope="col">LastUpdate Time</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {loading && (
                      <tbody>
                        <div className="spinner spinner-success mr-3"></div>
                      </tbody>
                    )}
                    {!loading && (
                      <tbody>
                        {project &&
                          project.map((x, i) => {
                            return (
                              <tr key={i}>
                                <th scope="row">
                                  <span
                                    style={{
                                      color: "#016bf8",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleOnclick(x)}
                                  >
                                    {x["name"]}
                                  </span>{" "}
                                </th>
                                <td>{x["partnerId"]}</td>
                                <td>
                                  {new Date(x["createdAt"]).toLocaleString()}
                                </td>
                                <td>
                                  {new Date(x["updatedAt"]).toLocaleString()}
                                </td>
                                <td>
                                  <Can
                                    I={Action.ProjectUpdate}
                                    a={Resources.Project}
                                  >
                                    <a
                                      onClick={() => {
                                        setUpdateScreen(x);
                                        openModal();
                                      }}
                                      href="#"
                                      className="text-success mr-2 font-weight-bold"
                                    >
                                      Edit
                                    </a>
                                  </Can>
                                  {/* {
                                    // for migration FPT users case
                                    x["uuid"] !==
                                      "642eaa3e345d8ccc0f34d9dd" && (
                                      <Can
                                        I={Action.ProjectDelete}
                                        a={Resources.Project}
                                      >
                                        <a
                                          onClick={() => modalDelete(x["uuid"])}
                                          href="#"
                                          className="text-danger mr-2 font-weight-bold"
                                        >
                                          Delete
                                        </a>
                                      </Can>
                                    )
                                  } */}
                                </td>
                                <Modal
                                  isOpen={modalIsOpen === x["uuid"]}
                                  // onRequestClose={modalDelete}
                                  ariaHideApp={false}
                                  style={{
                                    content: {
                                      top: "50%",
                                      left: "50%",
                                      right: "auto",
                                      bottom: "auto",
                                      marginRight: "-50%",
                                      transform: "translate(-50%, -50%)",
                                    },
                                  }}
                                >
                                  <h2>Delete {x["name"]} ?</h2>
                                  <div className="d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={closeModal}
                                      className="btn btn-secondary"
                                    >
                                      Close
                                    </button>
                                    {!loading && (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          deleteProject(modalIsOpen)
                                        }
                                        className="btn btn-danger ml-3"
                                      >
                                        Delete
                                      </button>
                                    )}
                                    {loading && (
                                      <div className="spinner spinner-success mr-3"></div>
                                    )}
                                  </div>
                                </Modal>
                              </tr>
                            );
                          })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
