export enum Action {
  ProjectAccessApiKey = "projectAccessApiKey",
  ProjectCreate = "projectCreate",
  ProjectUpdate = "projectUpdate",
  ProjectDelete = "projectDelete",
  ProjectEditRole = "projectEditRole",
  ProjectViewUserReport = "projectViewUserReport",
  ProjectUserViewUserDevices = "projectViewUserDevice",
  UserCreate = "userCreate",
  UserRead = "userView",
  UserUpdate = "userUpdate",
  UserDelete = "userDelete",
  ReportView = "reportDeviceactivations",
  AppView = "appView",
  AppCreated = "appCreate",
  AppUpdated = "appUpdated",
  AppDeleted = "appDelete",
  ProductViewAllPartnerModelId = "productViewAll",
  ProductEditPartnerModel = "productEdit",
  HardwareViewAll = 'hardwareView',
  ALL = "*",
}
export enum ActionToName {
  "projectAccessApiKey" = "Access To Api Key",
  "projectCreate" = "Project Add",
  "projectUpdate" = "Project Edit",
  "projectDelete" = "Project Delete",
  "projectEditRole" = "Edit Role Of User",
  "projectViewUserReport" = "View User Report",
  "projectViewUserDevice" = "View Project User Devices",
  "userCreate" = "User Add",
  "userView" = "User View",
  "userUpdate" = "User Edit",
  "userDelete" = "User Delete",
  "reportDeviceactivations" = "Report Device Activations",
  "appView" = "View App",
  "appCreate" = "Create App",
  "appUpdated" = "Update App",
  "appDelete" = "Delete App",
  "productViewAll" = "View ProductId",
  "productEdit" = "Edit ProductId",
  "hardwareView" = "Edit & View Hardware",
  "ALL" = "All",
}
export enum ActionShort {
  projectCreate = "prc",
  projectUpdate = "pru",
  projectDelete = "prd",
  projectEditRole = "prer",
  projectAccessApiKey = "praak",
  projectViewUserReport = "prvur",
  projectViewUserDevice = "prvud",
  userCreate = "usc",
  userView = "usv",
  userUpdate = "usu",
  userDelete = "usd",
  reportDeviceactivations = "rpda",
  appView = "av",
  appCreate = "ac",
  appUpdated = "au",
  appDelete = "ad",
  productViewAll = "pva",
  productEdit = "pre",
  hardwareView = 'hwv',
  "*" = "*",
}
