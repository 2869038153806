import * as React from "react";

export const TabAuthorization = () => {
  return (
    <div
      className="tab-pane fade show active"
      id="1"
      role="tabpanel"
      aria-labelledby="1"
    >
      Authorization
    </div>
  );
};
