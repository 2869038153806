import * as React from "react";

export const Footer = (props) => {
  return (
    <div className="app-footer">
      <div className="footer-bottom d-flex flex-column flex-sm-row align-items-center">
        <div className="d-flex align-items-center">
          {/* {props.logo && <img className="logo"  src="../assets/images/logo.svg" alt="" />} */}
          <div>
            <p className="m-0">&copy; 2023 FPT Smart Home</p>
            <p className="m-0">All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};
