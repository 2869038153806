import * as React from "react";
import DatePicker from "react-datepicker";
import "./../../assets/react-datepicker-cssmodules.min.css";
import "../../components/picker.css";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import Chart from "react-apexcharts";
import { IoTManufacturer } from "src/constant/manufactory";
import { IoTDeviceType } from "src/constant/deviceType";
import { Empty, Statistic } from "antd";
import {
  IoTProtocol,
  IoTProtocolToImage,
  IoTProtocolToName,
} from "src/constant/protocol";
import { DatePicker2, convertTimezone } from "../../components/datePicker";
import { ExportDeviceActivation } from "../../components/export";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

export enum Inteval {
  DAY = "day",
  MONTH = "month",
  WEEK = "week",
  // QUARTER = 'quarter',
  YEAR = "year",
}
export enum CHART_TYPE {
  LINE = "line",
  BAR = "bar",
  AREA = "area",
  // HEATMAP = 'heatmap',
}
export const StatReport = () => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();

  // Get the start of the month in UTC
  const startOfMonth = new Date(year, month, 1, 0, 0, 0, 0);

  // Get the end of the month in UTC
  const nextMonth = month + 1;
  const startOfNextMonth = new Date(year, nextMonth, 1, 0, 0, 0, 0);
  const endOfMonth = new Date(startOfNextMonth.getTime() - 1);

  const [dateRange, setDateRange] = React.useState<any>([
    startOfMonth.getTime(),
    endOfMonth.getTime(),
  ]);
  const [timeInteval, setTimeInteval] = React.useState<string>(Inteval.DAY);
  const [chartType, setChartType] = React.useState<any>(CHART_TYPE.LINE);
  const [startDate, endDate] = dateRange;
  const [data, setData] = React.useState<any>();
  const [pieData, setPieData] = React.useState<any>();
  const [tableData, setTableData] = React.useState<any>();
  const [manufacturer, setManufacturer] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [percent, setPercent] = React.useState<any>();
  const [productId, setPrdId] = React.useState<any>();
  const [size, setSize] = React.useState<boolean>(false);
  const [empty, setEmpty] = React.useState<boolean>(false);
  const [openDate, setOpenDate] = React.useState(false);
  const be = React.useContext(BackendContext);
  const { defaultOrg } = React.useContext(FirebaseContext);
  React.useEffect(() => {
    getReports();
  }, [timeInteval, chartType]);
  const filterByProductId = () => {
    getReports();
  };
  const getReports = async (
    start = convertTimezone(dateRange[0]),
    to = convertTimezone(dateRange[1]),
    inteval?,
  ) => {
    setLoading(true);
    const startTimestamp: any =
      typeof start === "object" ? start.getTime() : start;
    const toTimestamp: any = typeof to === "object" ? to.getTime() : to;
    // const startTimestamp = start
    // const toTimestamp = to
    const substraction: any = new Date(
      startTimestamp - (toTimestamp - startTimestamp),
    ).getTime();

    const intevalChoice = inteval ? inteval : timeInteval;
    const viewReport = await be.deviceactivationViewReport(
      defaultOrg,
      startTimestamp,
      toTimestamp,
      [],
      productId ? [productId] : [],
    );
    if (viewReport.length === 0) {
      setEmpty(true);
      setData({ data: [], dataPrev: [] });
      setManufacturer(undefined);
      setPieData(undefined);
      setTableData(undefined);
      setPercent(undefined);
      setLoading(false);
    } else {
      setEmpty(false);
      const viewPieReportData = await be.deviceactivationViewPieReportData(
        defaultOrg,
        startTimestamp,
        toTimestamp,
      );
      const viewPieReportTableData =
        await be.deviceactivationViewPieReportTableData(
          defaultOrg,
          startTimestamp,
          toTimestamp,
        );
      const deviceActivationManufacturer =
        await be.deviceactivationManufacturer(
          defaultOrg,
          startTimestamp,
          toTimestamp,
        );
      setManufacturer({
        data: deviceActivationManufacturer,
        total: deviceActivationManufacturer.reduce(
          (x, i) => (x += i["count"]),
          0,
        ),
      });

      const pieDataHandle = Object.values(IoTDeviceType).map(
        (x) =>
          viewPieReportData.filter((y) => y["categoryId"] === x).length > 0 &&
          viewPieReportData.filter((y) => y["categoryId"] === x)[0]["count"],
      );
      const pieLabel = Object.keys(IoTDeviceType).map(
        (x, i) => x + " : " + pieDataHandle[i],
      );
      setPieData({ pieData: pieDataHandle, pieLabel: pieLabel });
      setTableData({
        data: viewPieReportTableData,
        total: viewPieReportTableData.reduce((x, i) => (x += i["count"]), 0),
      });
      // console.log('groupByLabel', groupByLabel(viewPieReportTableData))
      const viewReportForCamparision = await be.deviceactivationViewReport(
        defaultOrg,
        substraction,
        startTimestamp,
        [],
        productId ? [productId] : [],
      );
      const total = viewReport.reduce((x, i) => x + i["y"], 0);
      const totalBefore = viewReportForCamparision.reduce(
        (x, i) => x + i["y"],
        0,
      );
      const comparision = ((total - totalBefore) / totalBefore) * 100;
      const getLastElm =
        viewReportForCamparision[viewReportForCamparision.length - 1];
      const getLastElmViewReport = viewReport[viewReport.length - 1];
      if (viewReportForCamparision.length > 0) {
        setPercent({
          comparision,
          start: [
            `${numberMonthToName(viewReportForCamparision[0]["x"]["month"])}-${viewReportForCamparision[0]["x"]["date"]
            }/${viewReportForCamparision[0]["x"]["year"]}`,
            `${numberMonthToName(getLastElm["x"]["month"])}-${getLastElm["x"]["date"]
            }/${getLastElm["x"]["year"]}`,
          ],
          to: [
            `${numberMonthToName(viewReport[0]["x"]["month"])}-${viewReport[0]["x"]["date"]
            }/${viewReport[0]["x"]["year"]}`,
            `${numberMonthToName(getLastElmViewReport["x"]["month"])}-${getLastElmViewReport["x"]["date"]
            }/${getLastElmViewReport["x"]["year"]}`,
          ],
          total,
          totalBefore,
        });
      } else {
        setEmpty(true);
        setPercent(undefined);
      }
      switch (intevalChoice) {
        case Inteval.DAY:
          setData({ data: viewReport, dataPrev: viewReportForCamparision });
          // setDataPrev(viewReportForCamparision)
          break;
        case Inteval.WEEK: {
          if (viewReport.length % 7 === 0) {
            const splitArray = chunkMaxLength(
              viewReport,
              7,
              viewReport.length / 7,
            );
            const sum = splitArray.map((x) => {
              return {
                x: x[0]["x"],
                y: x.reduce((p, a) => p + a["y"], 0),
              };
            });

            const splitArrayBefore = chunkMaxLength(
              viewReport,
              7,
              viewReport.length / 7,
            );
            const sumBefore = splitArrayBefore.map((x) => {
              return {
                x: x[0]["x"],
                y: x.reduce((p, a) => p + a["y"], 0),
              };
            });
            setData({
              data: sum,
              dataPrev: sumBefore,
            });
          } else {
            const temp = viewReport;
            const divide = 7 - (viewReport.length % 7);
            let addToLastObj = viewReport[0];
            addToLastObj["y"] = 0;
            for (let i = 0; i < divide; i++) {
              temp.unshift(addToLastObj);
            }
            const splitArray = chunkMaxLength(temp, 7, temp.length / 7);
            const sum = splitArray.map((x, i) => {
              const calTotalDevices = x.reduce((p, a) => p + a["y"], 0);
              return {
                x: x[x.length - 1]["x"],
                y: calTotalDevices,
              };
            });

            const tempBefore = viewReportForCamparision;
            const divideBefore = 7 - (viewReportForCamparision.length % 7);
            let addToLastObjBefore = viewReportForCamparision[0];
            addToLastObjBefore["y"] = 0;
            for (let i = 0; i < divideBefore; i++) {
              tempBefore.unshift(addToLastObjBefore);
            }
            const splitArrayBefore = chunkMaxLength(
              tempBefore,
              7,
              tempBefore.length / 7,
            );
            const sumBefore = splitArrayBefore.map((x, i) => {
              const calTotalDevices = x.reduce((p, a) => p + a["y"], 0);
              return {
                x: x[x.length - 1]["x"],
                y: calTotalDevices,
              };
            });
            setData({
              data: sum,
              dataPrev: sumBefore,
            });
          }
          break;
        }
        case Inteval.MONTH: {
          const month = new Map();
          for (const x of viewReport) {
            if (!month.has(x["x"]["year"])) {
              month.set(x["x"]["year"], new Map());
            }
            if (!month.get(x["x"]["year"]).has(x["x"]["month"])) {
              month.get(x["x"]["year"]).set(x["x"]["month"], 0);
            }
            const cumulative = month.get(x["x"]["year"]).get(x["x"]["month"]);
            month.get(x["x"]["year"]).set(x["x"]["month"], cumulative + x["y"]);
          }
          const sum = [];
          month.forEach((v, k) => {
            v.forEach((v2, k2) => {
              sum.push({
                x: {
                  month: k2,
                  year: k,
                  date: undefined,
                },
                y: v2,
              });
            });
          });

          const monthBefore = new Map();
          for (const x of viewReportForCamparision) {
            if (!monthBefore.has(x["x"]["year"])) {
              monthBefore.set(x["x"]["year"], new Map());
            }
            if (!monthBefore.get(x["x"]["year"]).has(x["x"]["month"])) {
              monthBefore.get(x["x"]["year"]).set(x["x"]["month"], 0);
            }
            const cumulativeBefore = monthBefore
              .get(x["x"]["year"])
              .get(x["x"]["month"]);
            monthBefore
              .get(x["x"]["year"])
              .set(x["x"]["month"], cumulativeBefore + x["y"]);
          }
          const sumBefore = [];
          monthBefore.forEach((v, k) => {
            v.forEach((v2, k2) => {
              sumBefore.push({
                x: {
                  month: k2,
                  year: k,
                  date: undefined,
                },
                y: v2,
              });
            });
          });
          setData({ data: sum, dataPrev: sumBefore });
          break;
        }
        case Inteval.YEAR: {
          const year = new Map();
          for (const x of viewReport) {
            if (!year.has(x["x"]["year"])) {
              year.set(x["x"]["year"], 0);
            }
            const cumulative = year.get(x["x"]["year"]);
            year.set(x["x"]["year"], cumulative + x["y"]);
          }
          const sum = [];
          year.forEach((v, k) => {
            sum.push({
              x: {
                month: undefined,
                year: k,
                date: undefined,
              },
              y: v,
            });
          });
          setData(sum);

          const yearBefore = new Map();
          for (const x of viewReportForCamparision) {
            if (!yearBefore.has(x["x"]["year"])) {
              yearBefore.set(x["x"]["year"], 0);
            }
            const cumulativeBefore = yearBefore.get(x["x"]["year"]);
            yearBefore.set(x["x"]["year"], cumulativeBefore + x["y"]);
          }
          const sumBefore = [];
          yearBefore.forEach((v, k) => {
            sumBefore.push({
              x: {
                month: undefined,
                year: k,
                date: undefined,
              },
              y: v,
            });
          });
          setData({
            data: sum,
            dataPrev: sumBefore,
          });
          break;
        }
      }
      setLoading(false);
    }
  };
  function chunkMaxLength(arr, chunkSize, maxLength) {
    return Array.from({ length: maxLength }, () => arr.splice(0, chunkSize));
  }
  const transform = (num: number) => {
    if (num.toString().length === 1) {
      return "0" + num.toString();
    } else {
      return num.toString();
    }
  };
  const numberMonthToName = (month: number) => {
    switch (month) {
      case 0:
        return "January";
        break;
      case 1:
        return "February";
        break;
      case 2:
        return "March";
        break;
      case 3:
        return "April";
        break;
      case 4:
        return "May";
        break;
      case 5:
        return "June";
        break;
      case 6:
        return "July";
        break;
      case 7:
        return "August";
        break;
      case 8:
        return "September";
        break;
      case 9:
        return "October";
        break;
      case 10:
        return "November";
        break;
      case 11:
        return "December";
        break;
    }
  };
  function groupByLabel(data) {
    const groupedData = {};

    for (const item of data) {
      if (item.data["protocol"]) {
        const protocol = item.data["protocol"];

        if (groupedData[protocol]) {
          groupedData[protocol].push(item);
        } else {
          groupedData[protocol] = [item];
        }
      } else {
        if (groupedData["none"]) {
          groupedData["none"].push(item);
        } else {
          groupedData["none"] = [item];
        }
      }
    }

    return groupedData;
  }
  return (
    <>
      <div className="main-content">
        <div className="breadcrumb">
          <h1 className="mr-3">Devices Activations Statistics</h1>
          <div>
            <ExportDeviceActivation />
          </div>
        </div>
        <div className="separator-breadcrumb border-top"></div>
        <div className="col-12">
          <div className="">
            <div className="text-left d-flex justify-content-end">
              <a
                href="#collapse-icon"
                className="text-default collapsed btn btn-default p-0 m-0"
                data-toggle="collapse"
                aria-expanded="false"
              >
                <h5 className="heading text-primary">
                  Filter:{" "}
                  <span className="text-muted">
                    by <b>{timeInteval}</b>
                    <b> {productId ? `product: ${productId}` : ""}</b>
                    from
                    <b> {new Date(startDate).toLocaleDateString()}</b> to
                    <b> {new Date(endDate).toLocaleDateString()}</b>
                  </span>{" "}
                </h5>
              </a>
            </div>
            <div className="collapse show" id="collapse-icon">
              <div className="d-flex justify-content-end align-items-center ">
                <div className="justify-content-end d-flex align-items-center mb-3">
                  <span className="mr-2">Filter By ProductId: </span>
                  <div
                    className="form-group mb-0 mr-3 d-flex "
                    style={{ minWidth: 100 }}
                  >
                    <input
                      onKeyPress={(e) => {
                        e.key === "Enter" && filterByProductId();
                      }}
                      onChange={(e) => setPrdId(e.target.value)}
                      id="productId"
                      className="form-control form-control"
                      type="text"
                    />
                    <div
                      onClick={filterByProductId}
                      className="input-group-append"
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className=" btn btn-primary"
                        id="basic-addon2"
                        style={{ zIndex: "auto" }}
                      >
                        OK
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center ">
                  <div className="justify-content-end d-flex align-items-center mb-3">
                    <span className="mr-2">View By: </span>
                    <div
                      className="form-group mb-0 mr-3 d-flex "
                      style={{ minWidth: 100 }}
                    >
                      <select
                        onChange={(e) => {
                          setTimeInteval(e.target.value);
                        }}
                        className="form-control"
                      >
                        {Object.values(Inteval).map((x) => {
                          return (
                            <>
                              <option
                                selected={x === timeInteval}
                                key={x}
                                value={x}
                              >
                                {x}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="justify-content-end d-flex align-items-center mb-3">
                    <div
                      className="picker"
                      onClick={() => {
                        setOpenDate(true);
                      }}
                    >
                      <div
                        className="font-weight-bold dropdown-toggle"
                        style={{ cursor: "pointer", paddingRight: 25 }}
                      >
                        {typeof startDate === "object"
                          ? startDate.toLocaleDateString()
                          : new Date(startDate).toLocaleDateString()}{" "}
                        -{" "}
                        {typeof endDate === "object"
                          ? endDate.toLocaleDateString()
                          : new Date(endDate).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="box-calendar shadow">
                      <DatePicker2
                        openDate={openDate}
                        setOpenDate={setOpenDate}
                        getReports={getReports}
                        startDate={startDate}
                        endDate={endDate}
                        setDateRange={setDateRange}
                      />
                    </div>
                  </div>
                  <div className="justify-content-end d-flex align-items-center mb-3">
                    <a
                      href="#collapse-icon"
                      className="text-default collapsed btn btn-default"
                      data-toggle="collapse"
                      aria-expanded="false"
                    >
                      <h5 className="heading text-mute">Hidden</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-4 row">
            <div className="card-body ">
              <h2 className="w-100 card-title m-0 font-weight-bold mb-3">
                Devices
              </h2>
              <div className="row">
                <div className="m-3 col" style={{ width: 358 }}>
                  <div className="card mb-4" style={{ height: 300 }}>
                    <div className="card-body">
                      <div className="mb-1 text-22 font-weight-light font-weight-bold">
                        <div className="heading d-flex justify-content-between align-items-center">
                          <div className="heading d-flex justify-content-start align-items-center">
                            <h1 className="heading text-success font-weight-bold">
                              {percent && percent["total"]}
                            </h1>
                            <small
                              className="text-muted pl-2"
                              style={{ fontSize: "60%", fontWeight: 800 }}
                            >
                              Total Devices Activated
                            </small>
                          </div>
                          <div className="d-flex">
                            {percent && percent["comparision"] > 0 ? (
                              <>
                                <Statistic
                                  value={
                                    percent && percent["comparision"].toFixed(2)
                                  }
                                  precision={2}
                                  valueStyle={{ color: "#3f8600" }}
                                  prefix={<ArrowUpOutlined rev={undefined} />}
                                  suffix="%"
                                />
                              </>
                            ) : (
                              <>
                                <Statistic
                                  value={
                                    percent && percent["comparision"].toFixed(2)
                                  }
                                  precision={2}
                                  valueStyle={{ color: "#cf1322" }}
                                  prefix={<ArrowDownOutlined rev={undefined} />}
                                  suffix="%"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-column pt-3">
                          <small
                            className="text-muted"
                            style={{ fontSize: "60%" }}
                          >
                            Compare Time:
                          </small>
                          <small
                            className="text-muted"
                            style={{ fontSize: "60%", fontWeight: 800 }}
                          >
                            {percent &&
                              `${percent["start"][0]} -> ${percent["start"][1]} (${percent["totalBefore"]})`}
                          </small>
                          <small
                            className="text-muted"
                            style={{ fontSize: "60%", fontWeight: 800 }}
                          >
                            <span style={{ fontSize: "100%", fontWeight: 400 }}>
                              vs
                            </span>{" "}
                            {percent &&
                              `${percent["to"][0]} -> ${percent["to"][1]} (${percent["total"]})`}
                          </small>
                        </div>
                      </div>
                      <div className="progress mb-1" style={{ height: "4px" }}>
                        <div
                          className="progress-bar bg-warning"
                          style={{ width: `${1000000 / 10000}%` }}
                          role="progressbar"
                        ></div>
                      </div>
                      {/* {defaultOrg === "FPT" && <div className="mb-1 text-22 font-weight-light font-weight-bold">
                                                <div className="heading d-flex justify-content-start align-items-center pt-3">
                                                    <h1 className='heading text-success font-weight-bold'>2950</h1>
                                                    <small className="text-muted pt-1 pl-2" style={{ fontSize: '60%', fontWeight: 800 }}>
                                                        Active Users from 01/01/2022 to 31/12/2022
                                                    </small>
                                                </div>
                                            </div>} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-12">
                  <div className="justify-content-start d-flex align-items-center mb-3">
                    <span className="mr-2">Chart: </span>
                    <div
                      className="form-group mb-0 mr-3 d-flex "
                      style={{ minWidth: 100 }}
                    >
                      <select
                        onChange={(e) => {
                          setChartType(e.target.value);
                        }}
                        className="form-control"
                      >
                        {Object.values(CHART_TYPE).map((x) => {
                          return (
                            <>
                              <option
                                selected={x === chartType}
                                key={x}
                                value={x}
                              >
                                {x}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {loading && (
                    <div className="spinner spinner-success mr-3"></div>
                  )}
                  {empty && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  {data && data["data"].length > 0 && (
                    <Chart
                      type={chartType}
                      options={{
                        chart: {
                          height: 280,
                          dropShadow: {
                            enabled: !0,
                            top: 3,
                            left: 3,
                            blur: 1,
                            opacity: 0.2,
                          },
                        },
                        stroke: {
                          width: 3,
                        },
                        xaxis: {
                          categories: data["data"].map(
                            (x) =>
                              `${x["x"]["year"]}${x["x"]["date"]
                                ? "/" + transform(x["x"]["month"] + 1)
                                : x["x"]["month"] + 1
                                  ? "-" + numberMonthToName(x["x"]["month"])
                                  : ""
                              }${x["x"]["date"]
                                ? "/" + transform(x["x"]["date"])
                                : ""
                              }`,
                          ),
                        },
                        fill: {
                          type: "gradient",
                          gradient: {
                            shade: "dark",
                            gradientToColors: ["#fe1b57"],
                            shadeIntensity: 1,
                            type: "horizontal",
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100, 100, 100],
                          },
                        },
                        markers: {
                          size: 1,
                          colors: ["#FFA41B"],
                          strokeWidth: 1,
                          hover: {
                            size: 7,
                          },
                        },
                        yaxis: [
                          {
                            title: {
                              text: "Activated Devices",
                              style: {
                                fontWeight: "normal",
                                fontFamily: "inherit",
                                fontSize: "14",
                              },
                            },
                          },
                        ],
                      }}
                      series={[
                        {
                          name: productId ? productId : "Devices Activated",
                          data: data["data"].map((x) => x["y"]),
                        },
                      ]}
                      height={280}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-xs-12 col-md-12">
              {/* <h2 className="w-100 card-title m-0 font-weight-bold">Tỉ trọng thiết bị kích hoạt theo chủng loại sản phẩm</h2> */}
              <h2 className="text-center w-100 card-title m-0 mb-2 font-weight-bold">
                Share of of activated devices by product type
              </h2>
              <div className="donut d-flex justify-content-center">
                {pieData && (
                  <Chart
                    options={{
                      labels: pieData["pieLabel"],
                      colors: [
                        "#42a59d",
                        "#baee5a",
                        "#31b62c",
                        "#116554",
                        "#85a0e2",
                        "#e0262c",
                        "#d27519",
                        "#71c9b3",
                        "#1e0f54",
                      ],
                      fill: {
                        colors: [
                          "#42a59d",
                          "#baee5a",
                          "#31b62c",
                          "#116554",
                          "#85a0e2",
                          "#e0262c",
                          "#d27519",
                          "#71c9b3",
                          "#1e0f54",
                        ],
                      },
                    }}
                    series={pieData["pieData"]}
                    type="donut"
                    width="500"
                  />
                )}
              </div>
              <h2 className="text-center w-100 mt-4 card-title font-weight-bold">
                Share of of activated devices by Manufacturer
              </h2>
              <div className="donut  d-flex justify-content-center">
                {pieData && (
                  <Chart
                    options={{
                      labels: manufacturer["data"].map(
                        (x, i) =>
                          Object.keys(IoTManufacturer)[
                          Object.values(IoTManufacturer).findIndex(
                            (y) => y.toString() === x["manufacturer"],
                          )
                          ] +
                          " : " +
                          x["count"].toString(),
                      ),
                      colors: [
                        "#42a59d",
                        "#baee5a",
                        "#31b62c",
                        "#116554",
                        "#85a0e2",
                        "#e0262c",
                        "#d27519",
                        "#71c9b3",
                        "#1e0f54",
                      ],
                      fill: {
                        colors: [
                          "#42a59d",
                          "#baee5a",
                          "#31b62c",
                          "#116554",
                          "#85a0e2",
                          "#e0262c",
                          "#d27519",
                          "#71c9b3",
                          "#1e0f54",
                        ],
                      },
                    }}
                    series={manufacturer["data"].map((x) => x["count"])}
                    type="donut"
                    width="550"
                  />
                )}
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-12">
              <div className="table-responsive">
                <table className="table text-left">
                  <thead>
                    <tr style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                      <th scope="col">Protocol</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {loading && (
                    <tbody>
                      <div className="spinner spinner-success mr-3"></div>
                    </tbody>
                  )}
                  {!loading && (
                    <tbody>
                      {tableData &&
                        tableData["data"] &&
                        Object.entries(groupByLabel(tableData["data"])).map(
                          (x: any, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {Object.values(IoTProtocol)
                                    .map(
                                      (z, i) =>
                                        z.includes(parseInt(x[0])) &&
                                        Object.values(IoTProtocolToName)[i],
                                    )
                                    .filter((z) => !!z)
                                    .join("")}
                                </td>
                                <td>
                                  <img
                                    width={150}
                                    alt="img"
                                    src={`${window.location.origin +
                                      "/assets/images" +
                                      Object.values(IoTProtocol)
                                        .map(
                                          (z, i) =>
                                            z.includes(parseInt(x[0])) &&
                                            Object.values(IoTProtocolToImage)[
                                            i
                                            ],
                                        )
                                        .filter((z) => !!z)
                                        .join("")
                                      }`}
                                  />
                                </td>
                                <td className="p-0">
                                  <table id="" className="table text-left  ">
                                    <thead>
                                      <tr
                                        style={{
                                          backgroundColor: "rgba(0, 0, 0, .03)",
                                        }}
                                      >
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Product ID</th>
                                        <th scope="col">Manufacturer</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Rate</th>
                                      </tr>
                                    </thead>
                                    {loading && (
                                      <tbody>
                                        <div className="spinner spinner-success mr-3"></div>
                                      </tbody>
                                    )}
                                    {!loading && (
                                      <tbody>
                                        {x[1].map((tableData2, i) => {
                                          return (
                                            <tr key={i.toString()}>
                                              <td className="text-bold">
                                                {tableData2["data"]["name"]}
                                              </td>
                                              <td>
                                                {
                                                  tableData2["data"][
                                                  "productId"
                                                  ]
                                                }
                                              </td>
                                              <td>
                                                {
                                                  Object.keys(
                                                    IoTManufacturer,
                                                  )[
                                                  Object.values(
                                                    IoTManufacturer,
                                                  ).findIndex(
                                                    (y) =>
                                                      y.toString() ===
                                                      tableData2["data"][
                                                      "manufacturer"
                                                      ],
                                                  )
                                                  ]
                                                }
                                              </td>
                                              <td>{tableData2["count"]}</td>
                                              <td>
                                                {(
                                                  (tableData2["count"] /
                                                    tableData["total"]) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    )}
                                  </table>
                                </td>
                              </tr>
                            );
                          },
                        )}
                    </tbody>
                  )}
                </table>
                {/* <div
                  className="justify-content-center align-items-center d-flex"
                  style={{ fontSize: 18, cursor: "pointer" }}
                  onClick={() => setSize(!size)}
                >
                  <div className="mr-2 font-weight-bold">
                    {!size ? `More` : `Less`}
                  </div>
                  {!size ? (
                    <i className="i-Arrow-Down-in-Circle"></i>
                  ) : (
                    <i className="i-Arrow-Up-in-Circle"></i>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <div className="separator-breadcrumb border-top"></div>
        </div>
      </div>
    </>
  );
};
