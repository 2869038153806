import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import {
  MenuProps,
  Menu,
  Divider,
  Radio,
  Table,
  UploadProps,
  Empty,
  Select,
  RadioChangeEvent,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { Pagination } from "src/components/pavigation";
import { FileProtectOutlined, SettingOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import * as _ from "lodash";
export const TabDevicesOta = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [listDevices, setListDevices] = React.useState<any[]>();
  const be = React.useContext(BackendContext);
  const { defaultOrg } = React.useContext(FirebaseContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [size, setSize] = React.useState<number>(50);
  const { productId, projectId } = useParams();
  const [listOTA, setListOTA] = React.useState<MenuProps["items"]>();
  const [selectedList, setSelectedList] = React.useState<object[]>([]);
  const [innerCounter, setInnerCounter] = React.useState(0);
  React.useEffect(() => {
    getListOTADevices();
    getListOTAFile();
    getSelectedList();
  }, [innerCounter]);
  const incrementInnerCounter = () => {
    setInnerCounter(innerCounter + 1);
  };
  const getSelectedList = async () => {
    setLoading(true);
    const listInDb = await be.modelGetProjectProduct(defaultOrg, projectId);
    setSelectedList(
      listInDb.map((x) => {
        return { value: x["modelId"], label: x["name"] };
      }),
    );
    setLoading(false);
  };

  const getListOTAFile = async () => {
    const listInDb: any = await be.modelGetObjectInS3(defaultOrg, productId);
    setListOTA(transformObjectToMenuItems(listInDb));
  };
  function transformObjectToMenuItems(obj) {
    // TL;DR: this code for transforming server response s3 bucket information into menu of antd format
    // ref for antd format: https://ant.design/components/menu
    const tempObj = obj;
    const menuItems = [];
    for (const key in _.omit(obj, ["key"])) {
      if (obj.hasOwnProperty(key)) {
        const item = obj[key];
        const children =
          typeof item === "object" && item !== null
            ? transformObjectToMenuItems(item)
            : [];

        const menuItem = {
          key: key + Math.random(),
          icon: <SettingOutlined rev={undefined} />,
          label: key,
        };
        if (!key.includes(".bin")) {
          menuItem["children"] = children;
        }
        menuItems.push(menuItem);
        const emptyFolder = Object.keys(_.omit(item, ["key"])).length === 0;
        if (key.includes(".bin") || (emptyFolder && key !== "latest")) {
          menuItem["icon"] = <FileProtectOutlined rev={undefined} />;
        }
      }
    }

    return menuItems;
  }
  interface DataType {
    key: React.Key;
    label: string;
    mac: string;
    uuid: string;
    eid: number;
    firmCode: number;
    firmVer: string;
  }
  const handleChangePage = (pageChange: number) => {
    setSearchParams({
      page: pageChange.toString(),
    });
    setLoading(true);
    getListOTADevices(pageChange);
    setLoading(false);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Label",
      dataIndex: "label",
      render: (text: string) => (
        <div style={{ fontWeight: "500" }}>{text} </div>
      ),
    },
    {
      title: "mac",
      dataIndex: "mac",
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: "firmCode",
      dataIndex: "firmCode",
    },
    {
      title: "firmVer",
      dataIndex: "firmVer",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.label === "Disabled User", // Column configuration not to be checked
      name: record.label,
    }),
  };
  // const randomCharacter = (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2)
  const getListOTADevices = async (
    pageActual: number = parseInt(searchParams.get("page")),
    sizePerPage: number = size,
  ) => {
    setLoading(true);
    const listInDb: any = await be.modelGetAlldevices(
      defaultOrg,
      productId,
      pageActual,
      sizePerPage,
    );
    setListDevices(listInDb);
    setLoading(false);
  };
  const handleSetSize = (sizePage: number) => {
    setSize(sizePage);
    getListOTADevices(parseInt(searchParams.get("page")), sizePage);
  };
  return (
    <>
      <h3>
        Product <b>{productId}</b>{" "}
      </h3>
      <div
        className="breadcrumb"
        style={{ borderBottom: "3px solid rgb(232, 237, 235)" }}
      >
        <ul className=" pb-2">
          <li className="pl-0">
            <a href="/org/project">Dashboard</a>
          </li>
          <li>Version 1</li>
        </ul>
      </div>
      <div className="row">
        <div className="col-3 mb-3">
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h4 className="mt-2 mb-0">Products Firmware</h4>
                </div>
                <div>
                  <Select
                    defaultValue={productId}
                    dropdownMatchSelectWidth={false}
                    placement={`bottomRight`}
                    options={selectedList && selectedList}
                    onChange={(x) => {
                      navigate(`/project/${projectId}/product/${x}?page=1`);
                      incrementInnerCounter();
                    }}
                  />
                </div>
              </div>
              {listOTA && listOTA.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              {!loading && (
                <Menu
                  defaultOpenKeys={
                    listOTA &&
                    listOTA[0] &&
                    listOTA[0]["children"].map((x) => x["key"])
                  }
                  mode="inline"
                  items={listOTA && listOTA[0] && listOTA[0]["children"]}
                />
              )}
            </div>
          </div>
          {loading && <div className="spinner spinner-success mr-3"></div>}
          <div></div>
        </div>
        <div className="col-9 mb-3">
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="w-100 d-flex justify-content-between align-items-center mb-3">
                  <h4 className="mt-2 mb-0">Products OTA</h4>
                  <button
                    type="button"
                    onClick={() => { navigate(`/project/${projectId}/product?page=1`) }}
                    className="btn btn-secondary mr-3"
                  >
                    Close
                  </button>
                </div>
              </div>

              <Divider />

              <Pagination
                data={listDevices}
                handleChangePage={handleChangePage}
                searchParams={searchParams}
                setData={getListOTAFile}
                setSize={handleSetSize}
                size={size}
              />
              <Table
                pagination={false}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={
                  listDevices &&
                  listDevices["data"].map((x) => {
                    return {
                      ...x,
                      key: x["uuid"],
                    };
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
