import { Modal, Table, Tag, Transfer, TransferProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import { TransferItem } from "antd/es/transfer";
import React, { useContext, useEffect, useState } from "react";
import { IoTAttribute } from "src/constant/features";
import * as _ from 'lodash'
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { FirebaseContext } from "src/services/firebaseContext";
import { deleteModuleKeys } from "../completeProduct";

interface DataType {
    key: string;
    name: string;
    description: string;
    disabled: boolean;
    tag: string;
}

interface RecordType {
    key: string;
    name: string;
    description: string;
    disabled: boolean;
    tag: string;
}

export const ModalTarget = ({
    productInfo,
    featureTarget,
    setOpen,
    open,
    be,
    incrementCounter
}) => {
    useEffect(() => {
        const getTarget = featureTarget[1]['attrs']
        setTargetKeys(getTarget)
    }, [open])
    const { productId } = useParams()
    const { defaultOrg } = useContext(FirebaseContext);
    const [targetKeys, setTargetKeys] = useState<string[]>();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const iotFeaturesData: RecordType[] = Object.entries(IoTAttribute).map((x, i) => {
        return {
            key: x[1],
            name: x[0],
            description: ``,
            disabled: false,
            tag: x[1],
        }
    })
    interface TableTransferProps extends TransferProps<TransferItem> {
        dataSource: DataType[];
        leftColumns: ColumnsType<DataType>;
        rightColumns: ColumnsType<DataType>;
    }

    // Customize Table Transfer
    const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => (
        <Transfer {...restProps}>
            {({
                direction,
                filteredItems,
                onItemSelectAll,
                onItemSelect,
                selectedKeys: listSelectedKeys,
                disabled: listDisabled,
            }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;

                const rowSelection: TableRowSelection<TransferItem> = {
                    getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                            .filter((item) => !item.disabled)
                            .map(({ key }) => key);
                        const diffKeys = selected
                            ? _.difference(treeSelectedKeys, listSelectedKeys)
                            : _.difference(listSelectedKeys, treeSelectedKeys);
                        onItemSelectAll(diffKeys as string[], selected);
                    },
                    onSelect({ key }, selected) {
                        onItemSelect(key as string, selected);
                    },
                    selectedRowKeys: listSelectedKeys,
                };

                return (
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        size="small"
                        style={{ pointerEvents: listDisabled ? 'none' : undefined }}
                        onRow={({ key, disabled: itemDisabled }) => ({
                            onClick: () => {
                                if (itemDisabled || listDisabled) return;
                                onItemSelect(key as string, !listSelectedKeys.includes(key as string));
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );

    const leftTableColumns: ColumnsType<DataType> = [
        {
            dataIndex: 'name',
            title: 'Name',
        },
        {
            dataIndex: 'key',
            title: 'Key',
            render: (tag) => <Tag>{tag}</Tag>,
        },
        {
            dataIndex: 'description',
            title: 'Description',
        },
    ];

    const rightTableColumns: ColumnsType<Pick<DataType, 'name'>> = [
        {
            dataIndex: 'name',
            title: 'Name',
        },
    ];


    const onChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };
    const isAddedModuleHardware = productInfo && !!productInfo['developmentInfo']['moduleName']
    return (
        <Modal
            title={open === true ? 'Add new element' : `Element ` + featureTarget[0]}
            centered
            open={open === featureTarget[0]}
            onOk={() => {
                setConfirmLoading(true);
                setTimeout(async () => {
                    // `open` variable for determine which stage are we working on (add new or edit an element)
                    // add new
                    isAddedModuleHardware && deleteModuleKeys(productInfo['developmentInfo'])
                    if (open !== true) {
                        productInfo['extraInfo']['elmInfos'][featureTarget[0]] = { attrs: targetKeys }
                        const data = await be.modeleditProductId(defaultOrg, productId, {
                            extraInfo: productInfo['extraInfo'],
                            developmentInfo: productInfo['developmentInfo']
                        });
                        if (data) {
                            toast.success('Update Successfully')
                        }
                        //  edit an element
                    } else {
                        if (
                            Object.keys(productInfo['extraInfo']).length === 0 ||
                            !productInfo['extraInfo']['elmInfos']
                        ) {
                            // if `feature field` not exist then create new, default element increase from 1
                            if (Object.keys(productInfo['extraInfo']).length === 0) {
                                productInfo['extraInfo'] = {}
                            }
                            productInfo['extraInfo']['elmInfos'] = {}
                            productInfo['extraInfo']['elmInfos']['1'] = { attrs: targetKeys } // create default 1 element
                            const data = await be.modeleditProductId(defaultOrg, productId, {
                                extraInfo: productInfo['extraInfo'],
                                developmentInfo: productInfo['developmentInfo']
                            });
                            if (data) {
                                toast.success('Created1 Successfully')
                            }
                        } else {
                            // if `feature field` existed then add 1 element into (last key + 1) we found in db
                            const elms = Object.keys(productInfo['extraInfo']['elmInfos'])
                            const lastKeys = elms.length === 0 ? 1 : parseInt(elms[elms.length - 1]) + 1
                            productInfo['extraInfo']['elmInfos'][lastKeys.toString()] = { attrs: targetKeys }
                            const data = await be.modeleditProductId(defaultOrg, productId, {
                                extraInfo: productInfo['extraInfo'],
                                developmentInfo: productInfo['developmentInfo']
                            });
                            if (data) {
                                toast.success('Created Successfully')
                            }
                        }
                    }
                    setConfirmLoading(false);
                    incrementCounter()
                    setOpen(undefined)
                }, 1000);
            }}
            onCancel={() => {
                setTargetKeys(targetKeys);
                setOpen(undefined)
            }}
            width={1000}
            confirmLoading={confirmLoading}
        >

            <TableTransfer
                dataSource={iotFeaturesData}
                targetKeys={targetKeys}
                disabled={false}
                showSearch={true}
                onChange={onChange}
                filterOption={(inputValue, item) =>
                    item.name!.indexOf(inputValue.toUpperCase()) !== -1
                    || item.tag.toString().indexOf(inputValue.toUpperCase()) !== -1
                }
                leftColumns={leftTableColumns}
                rightColumns={rightTableColumns}
            />

        </Modal>
    )
}