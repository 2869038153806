import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import {
    Typography,
    Table,
    Button,
    Upload,
    Space,
    Empty,
    Popconfirm,
    Drawer,
    Dropdown,
    Tabs,
} from "antd";
import * as _ from "lodash";
import { POWERTYPE, PROTOCOL } from "src/constant/productModelNewVer";
import { IoTProtocol, IoTProtocolToName } from "src/constant/protocol";
import { IoTFullDeviceType } from "src/constant/deviceType";
import { IoTAttribute } from "src/constant/features";
import { getImg } from "./product/productListTable";
import { DeleteOutlined, DownloadOutlined, PlusOutlined, UndoOutlined, UploadOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import yaml from 'js-yaml';
import { FirmwareDrawer } from "./product/components/firmwareDrawer";

export const Firmware = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [productData, setProductData] = useState<any>();
    const be = useContext(BackendContext);
    const { defaultOrg } = useContext(FirebaseContext);
    const [current, setCurrent] = useState();
    const [allFirmwares, setAllFirmwares] = useState<object[]>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState<any>(searchParams.get('type'))

    useEffect(() => {
        getNeedFirmware().then(x => getAllFirmwares())
    }, []);
    
    const getDownloadUrl = async (path) => {
        const downloadUrl = await be.firmwareGetDownloadUrl(defaultOrg, path)
        window.open(downloadUrl, "_blank");
    }
    const generateFeatures = (productInfo) => {
        const elmInfosInDb = productInfo['extraInfo']['elmInfos']
        const powerTypes = [_.invert(POWERTYPE)[productInfo['categoryInfo'][2]]]
        const developmentInfo = productInfo['developmentInfo']
        const features = []
        for (const elmInfo in elmInfosInDb) {
            if (elmInfosInDb.hasOwnProperty(elmInfo)) {
                const attrs = elmInfosInDb[elmInfo]['attrs']
                features.push(attrs)
            }
        }
        const uniqueFeaturesWithName = features.length > 0 && _.uniq(features.flat()).map(x => _.invert(IoTAttribute)[x] && _.invert(IoTAttribute)[x].toLowerCase())
        return (
            <div>
                {
                    developmentInfo && Object.entries(developmentInfo).map((x: any, i) => {
                        if (x[0] === 'moduleFirmwarePath') {
                            return <div className="ml-3 mr-3 d-flex align-items-center">
                                <b>{x[0]}</b>:<Button
                                    className="d-flex justify-content-center align-items-center font-weight-bold ml-3"
                                    style={{ color: "#1677ff" }}
                                    onClick={() => getDownloadUrl(x[1])}
                                >
                                    {x[1]}
                                    <DownloadOutlined className="ml-2" rev={undefined} />
                                </Button>
                            </div>
                        } else {
                            return typeof x[1] !== 'object' && x[0] !== 'moduleType' && <div key={i} className="ml-3 mr-3"><b>{x[0]}</b>: {x[1]}</div>
                        }
                    })
                }

                <div className="ml-3 mr-3"><b>powerTypes</b>: {powerTypes.join(',')}</div>
                <div className="ml-3 mr-3"><b>features</b>: {uniqueFeaturesWithName && uniqueFeaturesWithName.join(', ')}</div>
            </div>
        )
    }
    const columns = [
        {
            title: 'Product Information',
            dataIndex: 'productId',
            key: 'productId',
        },
        {
            title: 'Partner',
            dataIndex: 'partnerId',
            key: 'partnerId',
        },
        {
            title: 'Features',
            dataIndex: 'features',
            key: 'features',
        },
        {
            title: 'Protocol',
            dataIndex: 'protocol',
            key: 'protocol',
        },
        {
            title: 'Device Type',
            dataIndex: 'devicetype',
            key: 'devicetype',
        },
        {
            title: ' ',
            dataIndex: 'action',
            key: 'action',
        },
    ];
    const getAllFirmwares = async (productId?) => {
        const allFirmwares = await be.firmwareGetAll(defaultOrg, productId)
        setAllFirmwares(allFirmwares)
    }
    const getNeedFirmware = async () => {
        const getProductDevelop = await be.modelGetNeedProductFirmware(defaultOrg)
        setProductData(getProductDevelop)
    }
    const downloadUserFirmwareChoice = (data) => {
        const blobFile = new Blob([yaml.dump(JSON.parse(data))], { type: 'text/yml' });
        const url = window.URL.createObjectURL(blobFile);
        return url
    }
    const stepsDevelopment = [
        {
            name: "Developing",
            key: "developing"
        },
        {
            name: "Releasing",
            key: "releasing"
        }
    ]
    const release = async (modelId: string, name: string, releaseStatus: string) => {
        setLoading(true);
        const releaseData = await be.modelReleaseProduct(defaultOrg, modelId, releaseStatus)
        if (releaseData) {
            await getNeedFirmware();
            toast.success(`Release Product ${name} Successfully`)
        }
        setLoading(false);
    }
    return (
        <>
            <h3>
                Firmware & SDK
            </h3>
            <div
                className="breadcrumb"
                style={{ borderBottom: "3px solid rgb(232, 237, 235)" }}
            >
                <ul className=" pb-2">
                    <li className="pl-0">
                        <a href="/org/project">Dashboard</a>
                    </li>
                    <li>Version 1</li>
                </ul>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="card mb-4 p-3">
                        <div>
                            <h3>
                                Firmware Request
                            </h3>
                        </div>
                        <div className="card-body">
                            {loading && (
                                <div className="spinner spinner-success mr-3"></div>
                            )}
                            <Tabs
                                defaultActiveKey={tab}
                                activeKey={tab}
                                size={`large`}
                                onChange={(e) => {
                                    setTab(e)
                                    // incrementCounter()
                                    setSearchParams({
                                        type: e,
                                        page: "1"
                                    })
                                }}
                                items={stepsDevelopment.map((x, i) => {
                                    return {
                                        label: x['name'],
                                        key: x['key'],
                                        children: <>
                                            <Table
                                                dataSource={productData && productData.filter(x => x['releaseStatus'] === tab).map((x, i) => {
                                                    return {
                                                        productId: <div className=" d-flex">
                                                            <div className="mr-2 align-items-center d-flex">
                                                                <img
                                                                    width={70}
                                                                    alt="img"
                                                                    src={x['image'] ? x['image'] : getImg(Object.keys(IoTFullDeviceType)[
                                                                        Object.values(IoTFullDeviceType).findIndex(
                                                                            (z) => z === x["categoryInfo"][0],
                                                                        )
                                                                    ])}
                                                                />
                                                            </div>
                                                            <div>
                                                                <span
                                                                    style={{ color: "black", cursor: "pointer" }}
                                                                    onClick={() => { }}
                                                                >
                                                                    <b> {x["name"]}</b>
                                                                </span>
                                                                <br />
                                                                <small>{x["modelId"]}</small>
                                                                <br />
                                                            </div>
                                                        </div>,
                                                        protocol: Object.values(IoTProtocol)
                                                            .map(
                                                                (z, i) =>
                                                                    z.includes(x["baseInfo"][1]) &&
                                                                    Object.values(IoTProtocolToName)[i],
                                                            )
                                                            .filter((z) => !!z)
                                                            .join("") ? Object.values(IoTProtocol)
                                                                .map(
                                                                    (z, i) =>
                                                                        z.includes(x["baseInfo"][1]) &&
                                                                        Object.values(IoTProtocolToName)[i],
                                                                )
                                                                .filter((z) => !!z)
                                                                .join("") :
                                                            _.invert(PROTOCOL)[x["baseInfo"][1]],
                                                        features: generateFeatures(x),
                                                        devicetype: Object.keys(IoTFullDeviceType)[
                                                            Object.values(IoTFullDeviceType).findIndex(
                                                                (z) => z === x["categoryInfo"][0],
                                                            )
                                                        ],
                                                        partnerId: x['partnerId'],
                                                        action: <div className="d-flex align-items-center">

                                                            {
                                                                x['developmentInfo']['moduleType'] === 'MODULE' && x['developmentInfo']['moduleDetail'] &&
                                                                <div>
                                                                    <a href={downloadUserFirmwareChoice(JSON.stringify(x['developmentInfo']['moduleDetail']))} download={`${x['modelId']}.yaml`} >Download  <DownloadOutlined className="ml-2" rev={undefined} /></a>
                                                                </div>
                                                            }
                                                            <Button onClick={(e) => {
                                                                setCurrent(x)
                                                            }} type="link"><b>Action</b> </Button>

                                                            {(x['developmentInfo']['solution'] !== 'SDK' && x['developmentInfo']['moduleType'] !== 'STORE') ?
                                                                <>
                                                                    {
                                                                        (
                                                                            x['developmentInfo']['moduleType'] === 'STORE' ||
                                                                            (
                                                                                x['releaseStatus'] === 'releasing' &&
                                                                                x['developmentInfo']['moduleFirmwarePath'] &&
                                                                                x['developmentInfo']['moduleFirmwarePath'].includes(x['modelId'])
                                                                            )
                                                                        )
                                                                        &&
                                                                        <Popconfirm className="ml-3" title={<div className="text-danger"><b>Release cannot be undone</b></div>} onConfirm={(confirm) => release(x['modelId'], x['name'], x['releaseStatus'])}  >
                                                                            <button className="btn btn-success mb-1"  >
                                                                                Release
                                                                            </button>
                                                                        </Popconfirm>
                                                                    }
                                                                </> : <>
                                                                    {x['releaseStatus'] === 'releasing' && <Popconfirm title={<div className="text-danger"><b>Release cannot be undone</b></div>} onConfirm={(confirm) => release(x['modelId'], x['name'], x['releaseStatus'])}  >
                                                                        <button className="btn btn-success mb-1"  >
                                                                            Release
                                                                        </button>
                                                                    </Popconfirm>}
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                })}
                                                columns={columns}
                                                pagination={false}
                                            />
                                        </>
                                    };
                                })}
                            />

                        </div>
                    </div>
                </div>
            </div>
            {current && <FirmwareDrawer
                current={current}
                setCurrent={setCurrent}
                getNeedFirmware={getNeedFirmware}
                getAllFirmwares={getAllFirmwares} />}

            <div className="row">
                <div className="col-12 mb-3">
                    <div className="card mb-4 p-3">
                        <div>
                            <h3>
                                All firmwares
                            </h3>
                        </div>
                        <div className="card-body col-6">
                            {allFirmwares && allFirmwares.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            {loading && (
                                <div className="spinner spinner-success mr-3"></div>
                            )}
                            {!loading && <Table
                                dataSource={
                                    allFirmwares && allFirmwares.map((x, i) => {
                                        return {
                                            url: <div className="ml-3 mr-3 d-flex align-items-center">
                                                <b></b><Button
                                                    className="d-flex justify-content-center align-items-center font-weight-bold ml-3"
                                                    style={{ color: "#1677ff" }}
                                                    onClick={() => getDownloadUrl(x['url'])}
                                                >
                                                    {x['url']}
                                                    <DownloadOutlined className="ml-2" rev={undefined} />
                                                </Button>
                                            </div>,
                                            createdat: new Date(x['createdAt']).toISOString(),
                                            product: <b>{x['productId']}</b>
                                        }
                                    })
                                }
                                columns={[
                                    {
                                        title: 'Product',
                                        dataIndex: 'product',
                                        key: 'product',
                                    }, {
                                        title: 'URL',
                                        dataIndex: 'url',
                                        key: 'url',
                                    },
                                    {
                                        title: 'created At',
                                        dataIndex: 'createdat',
                                        key: 'createdat',
                                    },
                                ]}
                                pagination={false}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
