import { DownloadOutlined } from "@ant-design/icons";
import { Space, Dropdown, Button, MenuProps, Divider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
interface IExport {
  year: number;
  month: number;
}
// Get current date
const currentDate = new Date();

// Get the year
const year = currentDate.getFullYear();

// Create a new Date object for the start of the year
const startOfYear = new Date(year, 0, 1);

// Calculate the difference in months
const monthCount = currentDate.getMonth() - startOfYear.getMonth();

export const ExportDeviceActivation = () => {
  const be = useContext(BackendContext);
  const { defaultOrg } = useContext(FirebaseContext);
  const [size, setSize] = React.useState<number>(monthCount);
  const [loading, setLoading] = useState<string>();
  const [open, setOpen] = useState(false);
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key > size.toString()) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  useEffect(() => {}, [size]);
  const getMonth = (lastYear): string[] => {
    const now = new Date();

    const monthsArray = [];

    let currentMonth = lastYear;
    while (currentMonth <= new Date(now.getFullYear(), now.getMonth() - 1)) {
      const monthNumber = currentMonth.getMonth() + 1;
      const monthName = currentMonth.toLocaleString("default", {
        month: "long",
      });
      const year = currentMonth.getFullYear();

      monthsArray.push({
        name: `${monthName} ${year}`,
        month: monthNumber,
        year,
      });
      currentMonth.setMonth(currentMonth.getMonth() + 1);
    }
    return monthsArray.reverse();
  };
  const getData = async (year: number, month: number) => {
    setLoading(`${year}-${month}`);
    const getExportData = await be.deviceactivationGetExportData(
      defaultOrg,
      year,
      month,
    );
    window.open(getExportData, "_blank");
    setLoading("");
  };
  return (
    <>
      <Space direction="vertical">
        <Space wrap>
          <Dropdown
            open={open}
            onOpenChange={(e) => setOpen(e)}
            trigger={["click"]}
            menu={{
              items: getMonth(
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - size,
                ),
              )
                .map((x, i) => {
                  return {
                    key: (i + 1).toString(),
                    label: (
                      <div className="border-bottom">
                        <span>{x["name"]} </span>
                        <a
                          onClick={() => getData(x["year"], x["month"])}
                          className="text-success mr-2 "
                        >
                          {loading === `${x["year"]}-${x["month"]}` ? (
                            <div className="spinner spinner-success mr-3"></div>
                          ) : (
                            <span>Download</span>
                          )}
                        </a>
                      </div>
                    ),
                  };
                })
                .concat([
                  {
                    key: (size + 1).toString(),
                    label: (
                      <div
                        className="text-center"
                        onClick={() => setSize(size + 2)}
                      >
                        More
                      </div>
                    ),
                  },
                ]),
              onClick: handleMenuClick,
            }}
            placement="bottomRight"
            arrow
          >
            <Button
              className="d-flex justify-content-center align-items-center "
              style={{ backgroundColor: "#d9d9d9" }}
            >
              <b>Export to CSV</b>
              <DownloadOutlined className="ml-2" rev={undefined} />
            </Button>
          </Dropdown>
        </Space>
      </Space>
    </>
  );
};
