import * as React from "react";
import {
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { StatReport } from "./screens/org/deviceactivation";
import { Layout } from "./partials/layout";
import { Login } from "./screens/login";
import { Project } from "./screens/org/project";
import { Activity } from "./screens/org/activity";
import { Billing } from "./screens/org/billing";
import { Setting } from "./screens/org/setting";
import { Access } from "./screens/org/access";
import { firebaseHOC } from "./services/firebaseContext";
import { TabOverview } from "./screens/org/project/tabOverview";
import { TabAuthorization } from "./screens/org/project/tabAuthorization";
import { TabUser } from "./screens/org/project/tabUser";
import { TabDevices } from "./screens/org/project/tabDevices";
import { AttachUsers } from "./screens/org/project/tabAttachUser";
import { TabEndUser } from "./screens/org/project/tabEndUsers";
import { TabApps } from "./screens/org/project/tabApps";
import { Product } from "./screens/org/productManager";
import { AddProduct } from "./screens/org/product/addProduct";
import { CompleteProduct } from "./screens/org/product/completeProduct";
import { TabProduct } from "./screens/org/project/tabProduct";
import { TabDevicesOta } from "src/screens/org/project/tabDevicesOta";
import { HardwareModule } from "./screens/org/hardwareModule";
import { ProductDevelopment } from "./screens/org/productDevelopement";
import { Firmware } from "./screens/org/firmware";
let router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="*" element={<Login />} />
      <Route path="auth" element={<Login />} />
      <Route path="org" element={<Layout mainSideBar={false} />}>
        <Route path="project" element={<Project />} />
        <Route path="deviceactivations" element={<StatReport />} />
        {/* <Route path="export" element={<ExportDeviceActivation />} /> */}
        <Route path="access" element={<Access />} />
        <Route path="activity" element={<Activity />} />
        <Route path="billing" element={<Billing />} />
        <Route path="settings" element={<Setting />} />
        <Route path="module" element={<HardwareModule />} />
        <Route path="firmware" element={<Firmware />} />
        <Route path="product" element={<Product />} />
        <Route path="product/development" element={<ProductDevelopment />} />
        <Route path="product/add" element={<AddProduct />} />
        <Route path="product/development/:productId" element={<CompleteProduct />} />
      </Route>
      <Route path="project" element={<Layout mainSideBar={false} />}>
        <Route path=":projectId/overview" element={<TabOverview />} />
        <Route path=":projectId/authorization" element={<TabAuthorization />} />
        <Route path=":projectId/users" element={<TabUser />} />
        <Route path=":projectId/enduser" element={<TabEndUser />} />
        <Route path=":projectId/app" element={<TabApps />} />
        <Route path=":projectId/users/attach" element={<AttachUsers />} />
        <Route path=":projectId/devices" element={<TabDevices />} />
        <Route path=":projectId/product" element={<TabProduct />} />
        <Route
          path=":projectId/product/:productId"
          element={<TabDevicesOta />}
        />
      </Route>
    </>,
  ),
);

function App(props) {
  return (
    <RouterProvider
      key={props.trigger}
      router={router}
      fallbackElement={<Fallback />}
    />
  );
}

function Fallback() {
  return <p>Performing initial data "load"</p>;
}
export default firebaseHOC(App);
