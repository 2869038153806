import React, { useState } from "react";

import { AbilityContext, Can } from "src/services/caslContext";
import { Action, ActionToName } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import { useAbility } from "@casl/react";

export const RoleAttach = ({
  closeModal,
  users,
  setUserPermission,
  emails,
}) => {
  const [checkBoxState, setCheckBoxState] = useState(users[1]);
  const onProjectCheckBoxChange = (position) => {
    const findUserReadPosition = Object.values(Action).findIndex(
      (x) => x === Action.UserRead,
    );

    const updatedCheckedState = checkBoxState.map((item, index) => {
      return index === position ? !item : item;
    });
    if (
      Object.values(Action)[position] === Action.UserCreate ||
      Object.values(Action)[position] === Action.UserDelete ||
      Object.values(Action)[position] === Action.UserUpdate
    ) {
      updatedCheckedState[findUserReadPosition] = true;
    }
    if (
      Object.values(Action)[position] === Action.UserRead &&
      !updatedCheckedState[findUserReadPosition]
    ) {
      updatedCheckedState.map((item, index) => {
        if (Object.values(Action)[index].startsWith(Resources.User)) {
          updatedCheckedState[index] = false;
        }
      });
    }

    setCheckBoxState(updatedCheckedState);

    setUserPermission(users[0], updatedCheckedState);
  };
  const ability = useAbility(AbilityContext);
  return (
    <>
      <div className="form-group w-100 container">
        <h2>
          Set <b>{emails[users[0]]}</b> Permission
        </h2>
      </div>
      {Object.values(Resources).map((resource) => {
        return (
          <>
            <div className="form-group w-100 container">
              <label htmlFor="recipient-name-2" className="col-form-label">
                <span className="text-success">(Optional)</span> Role to{" "}
                {resource}:
              </label>
              <div className="d-flex align-self-center row ml-1">
                {ability.can(Action.ProjectEditRole, Resources.Project)
                  ? Object.values(Action).map((x, i) => {
                      return (
                        x.startsWith(resource) &&
                        x !== Action.ProjectCreate && (
                          <>
                            <label
                              key={i.toString()}
                              className="checkbox checkbox-primary mr-2 mb-2"
                            >
                              <input
                                checked={checkBoxState[i]}
                                name={resource}
                                type="checkbox"
                                onChange={() => {
                                  onProjectCheckBoxChange(i);
                                }}
                              />
                              <span className="font-weight-bold">
                                {ActionToName[x]}
                              </span>
                              <span className="checkmark"></span>
                            </label>
                          </>
                        )
                      );
                    })
                  : Object.values(Action).map((x, i) => {
                      return (
                        x.startsWith(resource) &&
                        x !== Action.ProjectCreate && (
                          <>
                            <Can I={x} a={resource} key={i.toString()}>
                              <label className="checkbox checkbox-primary mr-2 mb-2">
                                <input
                                  checked={checkBoxState[i]}
                                  name={resource}
                                  type="checkbox"
                                  onChange={() => {
                                    onProjectCheckBoxChange(i);
                                  }}
                                />
                                <span className="font-weight-bold">
                                  {ActionToName[x]}
                                </span>
                                <span className="checkmark"></span>
                              </label>
                            </Can>
                          </>
                        )
                      );
                    })}
              </div>
            </div>
          </>
        );
      })}
      <div className="form-group w-100 container d-flex justify-content-end">
        <button
          type="button"
          onClick={closeModal}
          className="btn btn-primary ml-2"
        >
          OK
        </button>
      </div>
    </>
  );
};
