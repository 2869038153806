import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Action, ActionToName } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import { BackendContext } from "src/services/backend";
import { Can } from "src/services/caslContext";
import { FirebaseContext } from "src/services/firebaseContext";
import { RoleDecompile } from "src/services/roleCompiler";

export const ModalAdd = ({
  show,
  openModal,
  project,
  getAccessUserPartner,
  updateScreen,
  setUpdateScreen,
}) => {
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [pass, setPass] = useState<string>();
  const [all, setAll] = useState<boolean>(false);
  const [checkBoxState, setCheckBoxState] = useState(
    new Array(Object.values(Action).length).fill(false),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { defaultOrg } = useContext(FirebaseContext);
  const { projectId } = useParams();
  const be = useContext(BackendContext);
  const [checkBoxProjectState, setCheckBoxProjectState] = useState(
    project && new Array(project.length).fill(false),
  );
  useEffect(() => {
    if (updateScreen) {
      getUserAbac(updateScreen).then((x) => {
        const abacInfo = RoleDecompile.decompile(x["abac"]);
        const abacInfoInPartnerId = abacInfo.filter(
          (x) => x.partnerId === defaultOrg,
        );
        const role = abacInfoInPartnerId[0]["role"];
        const checkBoxStateInit = Object.values(Action).map((x, i) =>
          role.includes(x),
        );
        setCheckBoxState(checkBoxStateInit);
        setCheckBoxProjectState(
          project.map((x) =>
            abacInfoInPartnerId.map((x) => x["projectId"]).includes(x["uuid"]),
          ),
        );
      });
    }
  }, []);
  const getUserAbac = async (uuid: string) => {
    const getUserPermission = await be.abacGetUserABAC(defaultOrg, uuid);
    return getUserPermission;
  };
  const nextModal = async () => {
    const abacBuilder = [];
    let projectIdSendToServer;
    project.map((x, i) => {
      if (checkBoxProjectState[i]) {
        const projectId = x["uuid"];
        projectIdSendToServer = projectId;
        const actions = Object.values(Action);
        const actionResource = [];
        actions.map((x, i) => {
          if (checkBoxState[i]) {
            Object.values(Resources).forEach((resource, index) => {
              if (actions[i].startsWith(resource)) {
                actionResource.push(`${resource}:${actions[i]}`);
              }
            });
          }
        });
        abacBuilder.push({
          resources: [`partner:${defaultOrg}:project/${projectId}`],
          actions: actionResource,
        });
      }
    });
    if (!updateScreen) {
      if (
        name &&
        email &&
        pass &&
        pass.length >= 8 &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
        checkBoxProjectState &&
        checkBoxProjectState.includes(true)
      ) {
        setLoading(true);
        const data = {
          name,
          email,
          pass,
        };
        const users = await be.userpartnerAddToSystem(
          data,
          defaultOrg,
          projectIdSendToServer,
        );
        if (users) {
          const user = users[0];
          const attachRole = await be.abacAttachRoleToUser(
            user["ownerId"],
            defaultOrg,
            projectIdSendToServer,
            abacBuilder,
            true,
          );
          await getAccessUserPartner();
          openModal();
        }
        setLoading(false);
      }
    } else {
      setLoading(true);
      const attachRole = await be.abacAttachRoleToUser(
        updateScreen,
        defaultOrg,
        projectId,
        abacBuilder,
        false,
      );
      await getAccessUserPartner();
      openModal();
      setLoading(false);
    }
  };
  const onPermissionCheckBoxChange = (position) => {
    const findUserReadPosition = Object.values(Action).findIndex(
      (x) => x === Action.UserRead,
    );

    const updatedCheckedState = checkBoxState.map((item, index) => {
      return index === position ? !item : item;
    });
    if (
      Object.values(Action)[position] === Action.UserCreate ||
      Object.values(Action)[position] === Action.UserDelete ||
      Object.values(Action)[position] === Action.UserUpdate
    ) {
      updatedCheckedState[findUserReadPosition] = true;
    }
    if (
      Object.values(Action)[position] === Action.UserRead &&
      !updatedCheckedState[findUserReadPosition]
    ) {
      updatedCheckedState.map((item, index) => {
        if (Object.values(Action)[index].startsWith(Resources.User)) {
          updatedCheckedState[index] = false;
        }
      });
    }

    setCheckBoxState(updatedCheckedState);
  };
  const onProjectCheckBoxChange = (position?: number) => {
    if (position !== undefined) {
      const updatedCheckedState = checkBoxProjectState.map((item, index) =>
        index === position ? !item : item,
      );
      setCheckBoxProjectState(updatedCheckedState);
    } else {
      setCheckBoxProjectState(checkBoxProjectState.map((x) => !all));
    }
  };
  return (
    <div
      className=""
      id=""
      role="dialog"
      aria-labelledby="modalAdd"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="verifyModalContent_title">
              Create User Partner
            </h5>
          </div>
          <div className="modal-body">
            <form className="d-flex">
              {!updateScreen && (
                <div>
                  <div className="form-group w-100 container">
                    <label
                      htmlFor="recipient-name-2"
                      className="col-form-label"
                    >
                      <span className="text-danger">*</span> Name:
                    </label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      className="form-control"
                    />
                    {!name && (
                      <label htmlFor="recipient-name-2" className="text-danger">
                        Enter Name
                      </label>
                    )}
                  </div>
                  <div className="form-group w-100 container">
                    <label
                      htmlFor="recipient-name-2"
                      className="col-form-label"
                    >
                      <span className="text-danger">*</span> Email:
                    </label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="form-control"
                    />
                    {!email ? (
                      <label htmlFor="recipient-name-2" className="text-danger">
                        Enter Email Address
                      </label>
                    ) : (
                      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                        email,
                      ) && (
                        <label
                          htmlFor="recipient-name-2"
                          className="text-danger"
                        >
                          Not Valid Email Format
                        </label>
                      )
                    )}
                  </div>
                  <div className="form-group w-100 container">
                    <label
                      htmlFor="recipient-name-2"
                      className="col-form-label"
                    >
                      <span className="text-danger">*</span> Password:
                    </label>
                    <input
                      onChange={(e) => setPass(e.target.value)}
                      type="password"
                      placeholder="******"
                      className="form-control"
                    />
                    {!pass ? (
                      <label htmlFor="recipient-name-2" className="text-danger">
                        Enter Password
                      </label>
                    ) : (
                      pass.length < 8 && (
                        <label
                          htmlFor="recipient-name-2"
                          className="text-danger"
                        >
                          The format of the specified password is incorrect
                        </label>
                      )
                    )}
                    <label>
                      The password must at least 8 characters in length
                      {/* and contain the following types: numbers, uppercase letters, lowercase letters and special characters. */}
                    </label>
                  </div>
                </div>
              )}
              <div>
                <div className="form-group w-100 container">
                  <label htmlFor="recipient-name-2" className="col-form-label">
                    <span className="text-success">
                      <span className="text-danger">*</span>
                    </span>{" "}
                    Role to Project of Partner:
                  </label>
                  <div className="d-block align-self-center">
                    <label className="checkbox checkbox-primary mr-2 mb-2">
                      <input
                        checked={all}
                        onChange={() => {
                          onProjectCheckBoxChange();
                          setAll(!all);
                        }}
                        name="project-partner-checkbox"
                        type="checkbox"
                      />
                      <span className="font-weight-bold pl-2">All</span>
                      <span className="checkmark"></span>
                    </label>
                    {project &&
                      project.map((x, i) => {
                        return (
                          <label
                            key={i}
                            className="checkbox checkbox-primary mr-2 mb-2"
                          >
                            <input
                              checked={checkBoxProjectState[i]}
                              onChange={() => {
                                setAll(false);
                                onProjectCheckBoxChange(i);
                              }}
                              name="project-partner-checkbox"
                              type="checkbox"
                            />
                            <span className="font-weight-bold pl-2">
                              {x["name"]}
                            </span>
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                  </div>
                </div>
                {checkBoxProjectState &&
                  checkBoxProjectState.includes(true) && (
                    <>
                      <div className="form-group w-100 container">
                        <label
                          htmlFor="recipient-name-2"
                          className="col-form-label"
                        >
                          <span className="text-success">(Optional)</span>{" "}
                          Project Add:
                        </label>
                        <div className="d-flex align-self-center row ml-1">
                          {Object.values(Action).map((x, i) => {
                            if (x.startsWith(Resources.Project)) {
                              if (x === Action.ProjectCreate) {
                                return (
                                  <Can
                                    I={x}
                                    a={Resources.Project}
                                    key={i.toString()}
                                  >
                                    <label className="checkbox checkbox-primary mr-2 mb-2">
                                      <input
                                        checked={checkBoxState[i]}
                                        name={Resources.Project}
                                        type="checkbox"
                                        onChange={() => {
                                          onPermissionCheckBoxChange(i);
                                        }}
                                      />
                                      <span className="font-weight-bold">
                                        {ActionToName[x]}
                                      </span>
                                      <span className="checkmark"></span>
                                    </label>
                                  </Can>
                                );
                              }
                            }
                          })}
                        </div>
                      </div>
                      {Object.values(Resources).map((resource, index) => {
                        return (
                          <div
                            key={index}
                            className="form-group w-100 container">
                            <label
                              htmlFor="recipient-name-2"
                              className="col-form-label"
                            >
                              <span className="text-success">(Optional)</span>{" "}
                              Role to {resource}:
                            </label>
                            <div className="d-flex align-self-center row ml-1">
                              {Object.values(Action).map((x, i) => {
                                if (x.startsWith(resource)) {
                                  if (x !== Action.ProjectCreate) {
                                    return (
                                      <Can
                                        I={x}
                                        a={resource}
                                        key={i.toString()}
                                      >
                                        <label className="checkbox checkbox-primary mr-2 mb-2">
                                          <input
                                            checked={checkBoxState[i]}
                                            name={resource}
                                            type="checkbox"
                                            onChange={() => {
                                              onPermissionCheckBoxChange(i);
                                            }}
                                          />
                                          <span className="font-weight-bold">
                                            {ActionToName[x]}
                                          </span>
                                          <span className="checkmark"></span>
                                        </label>
                                      </Can>
                                    );
                                  }
                                }
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={openModal}
              className="btn btn-secondary"
              data-dismiss={`${show ? "modal" : ""}`}
            >
              Close
            </button>
            {!loading && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  nextModal();
                }}
                type="button"
                className="btn btn-primary"
              >
                OK
              </button>
            )}
            {loading && <div className="spinner spinner-success mr-3"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
