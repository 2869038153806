import React, { useContext, useEffect, useState, useMemo } from "react";
import { Action } from "src/constant/abac";
import { IoTFullDeviceType } from "src/constant/deviceType";
import { IoTProtocol, IoTProtocolToImage, IoTProtocolToName } from "src/constant/protocol";
import { Resources } from "src/constant/resources";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import Modal from "react-modal";
import { Can } from "src/services/caslContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination } from "src/components/pavigation";
import { Button, Col, Divider, MenuProps, Popover, Row, Segmented } from "antd";
import * as _ from "lodash"
export const getImg = (x) => {
  let img = "";
  switch (x) {
    case "AC":
      img = "https://images.tuyacn.com/smart/program_category_icon/kt.png";
      break;
    case "MEDIA_BOX":
      img =
        "https://dashboard.fptsmarthome.vn/assets/images/img_section_smarthome_hub.png";
      break;
    case "LIGHT":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/al4v2zyg2br4.png";
      break;
    case "PLUG":
      img = "https://images.tuyacn.com/smart/program_category_icon/cz.png";
      break;
    case "SENSOR":
      img = "https://images.tuyacn.com/smart/program_category_icon/mcs.png";
      break;
    case "IR_DEVICE_CONTROLLER":
      img =
        "https://dashboard.fptsmarthome.vn/assets/images/ic_rg_ir_controller.png";
      break;
    case "DOORBELL":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/cm68nx44wq2o.jpg";
      break;
    case "CAMERA":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/cdcz60sexq0w.png";
      break;
    case "SWITCH_SCENE":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/bm430rswdvcw.jpg";
      break;
    case "CURTAINS":
      img = "https://images.tuyacn.com/smart/program_category_icon/clkg.png";
      break;
    case "TV":
      img = "https://images.tuyacn.com/smart/program_category_icon/zndsj.png";
      break;
    case "FAN":
      img = "https://images.tuyacn.com/smart/program_category_icon/fs.png";
      break;
    case "REPEATER":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/b984a9mg3qbk.png";
      break;
    case "USB_DONGLE":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/aj1vmmoae96o.png";
      break;
    case "DOORLOCK":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/b97yprx0baww.png";
      break;
    case "FACE_RECOGNIZE":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/a24cxu1iqtxc.png";
      break;
    case "SWITCH":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "MOTOR_CONTROLLER":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "BUTTON_DASH":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "TEMP_SENSOR":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "DOOR_SENSOR":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "SMOKE_SENSOR":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "MOTION_LUX_SENSOR":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "MOTION_SENSOR":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/au2vc0p70tfk.png";
      break;
    case "LUX_SENSOR":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/bxwj85jc49a8.png";
      break;
    case "DUST_SENSOR":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/bhwxr2br9yww.png";
      break;
    case "DIMMER_SCENE":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "PRESENSCE_SENSOR":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "DIMMER":
      img =
        "https://images.tuyacn.com/smart/user/frontCategroy/bjf1klaq94ao.png";
      break;
    case "AC_CONTROLLER":
      img = "https://images.tuyacn.com/smart/program_category_icon/kt.png";
      break;
    case "GATE":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
    case "GATEWAY":
      img = "https://images.tuyacn.com/smart/program_category_icon/wg2.png";
      break;
    case "SENSOR_DEVICE":
      img = "https://images.tuyacn.com/smart/program_category_icon/kg.png";
      break;
  }
  return img;
};
import {
  FilterOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox";
import "./productListTable.css";
import { POWERTYPE, PROTOCOL } from "src/constant/productModelNewVer";
import { IoTAttribute } from "src/constant/features";
import Table, { ColumnsType } from "antd/es/table";
const text = <span> Device Type</span>;
const contentProtocol = (
  productList,
  setProductList,
  productListTemp,
  setProductListTemp,
) => {
  const [checkedList, setCheckedList] = useState<any[]>();
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const plainOptions = Object.keys(IoTProtocolToName).map((x, i) => x);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setCheckedList(plainOptions);
    setIndeterminate(false);
    setCheckAll(true);
  }, []);
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    setProductList({
      data: !e.target.checked ? [] : productListTemp["data"],
      page: parseInt(searchParams.get("page")),
      pageSize: productListTemp["pageSize"],
      totalPage: productListTemp["totalPage"],
    });
  };
  const onChange = (list: any[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);

    const protocolRaw = list.map((y) => IoTProtocol[y]).flat();
    const productAfterFilter = productListTemp["data"].filter((x, i) =>
      protocolRaw.includes(x["baseInfo"][1]),
    );
    setProductList({
      data: productAfterFilter,
      page: parseInt(searchParams.get("page")),
      pageSize: productListTemp["pageSize"],
      totalPage: productListTemp["totalPage"],
    });
  };
  return (
    <div className="">
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        Check all
      </Checkbox>
      <Checkbox.Group
        className=""
        onChange={onChange}
        value={checkedList}
        style={{ margin: 0, marginInlineStart: "0!default" }}
      >
        <Col>
          {plainOptions.map((x, i) => (
            <Row key={i}>
              <Checkbox value={x}>
                {Object.values(IoTProtocolToName)[i]}
              </Checkbox>
            </Row>
          ))}
        </Col>
      </Checkbox.Group>
    </div>
  );
};
const contentDevType = (
  productList,
  setProductList,
  productListTemp,
  setProductListTemp,
) => {
  const [checkedList, setCheckedList] = useState<any[]>();
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const plainOptions = Object.keys(IoTFullDeviceType).map((x, i) => x);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setCheckedList(plainOptions);
    setIndeterminate(false);
    setCheckAll(true);
  }, []);
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    setProductList({
      data: !e.target.checked ? [] : productListTemp["data"],
      page: parseInt(searchParams.get("page")),
      pageSize: productListTemp["pageSize"],
      totalPage: productListTemp["totalPage"],
    });
  };
  const onChange = (list: any[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
    const devTypeRaw = list.map((x) => IoTFullDeviceType[x]);
    const productAfterFilter = productListTemp["data"].filter((x, i) =>
      devTypeRaw.includes(x["categoryInfo"][0]),
    );
    setProductList({
      data: productAfterFilter,
      page: parseInt(searchParams.get("page")),
      pageSize: productListTemp["pageSize"],
      totalPage: productListTemp["totalPage"],
    });
  };
  return (
    <div className="">
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        Check all
      </Checkbox>
      <Checkbox.Group
        className=""
        onChange={onChange}
        value={checkedList}
        style={{ margin: 0, marginInlineStart: "0!default" }}
      >
        <Col>
          {plainOptions.map((x, i) => (
            <Row key={i}>
              <Checkbox value={x}>{x}</Checkbox>
            </Row>
          ))}
        </Col>
      </Checkbox.Group>
    </div>
  );
};

export const ProductlistTable = ({
  setProductType,
  productInfo,
  setProductInfo,
  setSharePartner,
  setProductName,
  counter,
  consumerStage = false,
  handleSetSelectedList,
  selectedList,
  setListOTA,
  development = false,
  setSizeWidth = (a) => { },
  setProductDescription = (a) => { },
  stage = 'DEVELOPMENT',
  form = undefined
}) => {
  const [arrowAtCenter, setArrowAtCenter] = useState(false);
  const navigate = useNavigate()

  const mergedArrow = useMemo(() => {
    if (arrowAtCenter) return { pointAtCenter: true };
    return true;
  }, [arrowAtCenter]);

  useEffect(() => {
    getCloudAllProductId();
    if (searchParams.get('modelId')) {
      getCloudProduct(searchParams.get('modelId'));
      // setSizeWidth(6)
    }
  }, [counter]);

  const [loading, setLoading] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = React.useState<string | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [size, setSize] = useState<number>(50);
  const [productSearchId, setProductSearchId] = useState<string>();

  const [productList, setProductList] = useState<any>();
  const [productListTemp, setProductListTemp] = useState<any>();

  const getCloudAllProductId = async (
    pageActual: number = parseInt(searchParams.get("page")),
    sizePerPage: number = size,
  ) => {
    setLoading(true);
    const getAll = consumerStage
      ? await be.modelgetProductConsumer(defaultOrg, pageActual, sizePerPage)
      : await be.modelgetAllProductIds(defaultOrg, pageActual, sizePerPage, stage === 'MANAGER' ? 'released' : searchParams.get("selectField"));
    setProductList(getAll);
    setProductListTemp(getAll);
    setLoading(false);
  };
  const { defaultOrg } = useContext(FirebaseContext);
  const be = useContext(BackendContext);
  const getCloudProduct = async (productId) => {
    const getProductId = (!productInfo || productInfo['modelId'] !== productId) ? await be.modelgetProductId(defaultOrg, productId) : productInfo
    if (stage !== 'MANAGER') {
      form.setFieldValue('brand', getProductId['brand'])
      form.setFieldValue('ownership', getProductId['ownership'])
    }
    setProductName(getProductId["name"]);
    setProductDescription(getProductId["description"] ? getProductId["description"] : '');
    setProductInfo(getProductId);
    setProductType(getProductId["isPublic"]);
    setSharePartner(
      getProductId["sharePartners"].map((x) => {
        return { ...x, action: "remove" };
      }),
    );

  };
  const filterByProductId = async () => {
    setLoading(true);
    if (productSearchId) {
      const dataFilterByUserId = await be.modelgetProductId(
        defaultOrg,
        productSearchId,
      );
      if (dataFilterByUserId) {
        setProductList({
          data: [dataFilterByUserId],
          page: parseInt(searchParams.get("page")),
          pageSize: productList["pageSize"],
          totalPage: productList["totalPage"],
        });
      } else {
      }
    }
    if (!productSearchId) {
      getCloudAllProductId(parseInt(searchParams.get("page")), size);
    }
    setLoading(false);
  };
  const handleChangePage = (pageChange: number) => {
    setSearchParams({
      page: pageChange.toString(),
    });
    setLoading(true);
    getCloudAllProductId(pageChange);
    setLoading(false);
  };
  const handleSetSize = (sizePage: number) => {
    setSize(sizePage);
    getCloudAllProductId(parseInt(searchParams.get("page")), sizePage);
  };
  const generateFeatures = (productInfo) => {
    const elmInfosInDb = productInfo['extraInfo']['elmInfos']
    const powerTypes = [_.invert(POWERTYPE)[productInfo['categoryInfo'][2]]]
    const developmentInfo = productInfo['developmentInfo']
    const features = []
    for (const elmInfo in elmInfosInDb) {
      if (elmInfosInDb.hasOwnProperty(elmInfo)) {
        const attrs = elmInfosInDb[elmInfo]['attrs']
        features.push(attrs)
      }
    }
    const uniqueFeaturesWithName = features.length > 0 && _.uniq(features.flat()).map(x => _.invert(IoTAttribute)[x] && _.invert(IoTAttribute)[x].toLowerCase())
    return (
      <div>
        {
          developmentInfo && Object.entries(developmentInfo).map((x: any, i) => {
            return typeof x[1] !== 'object' && x[0] !== 'moduleType' && <div key={i} className="ml-3 mr-3"><b>{x[0]}</b>: {x[1]}</div>
          })
        }

        <div className="ml-3 mr-3"><b>powerTypes</b>: {powerTypes.join(',')}</div>
        <div className="ml-3 mr-3"><b>features</b>: {uniqueFeaturesWithName && uniqueFeaturesWithName.join(', ')}</div>
      </div>
    )
  }
  const columns: ColumnsType = [
    {
      title: " ",
      dataIndex: "stt",
    },
    {
      title: "ProductId Information",
      dataIndex: "modelId",
      render: (text: string) => (
        <div style={{ fontWeight: "500" }}>{text} </div>
      ),
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      render: (text: string) => (
        <div style={{ fontWeight: "500" }}>{text} </div>
      ),
    },
    {
      title: "Features",
      dataIndex: "features",
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: "Protocol",
      dataIndex: "protocol",
      filterDropdown: <div className="d-flex justify-content-left align-items-center pt-2">
        <div className="demo ">
          <div
            style={{
              marginLeft: 10,
              marginBottom: 1,
              clear: "both",
              whiteSpace: "nowrap",
            }}
          >
            {contentProtocol(
              productList,
              setProductList,
              productListTemp,
              setProductListTemp,
            )}
          </div>
        </div>
      </div>
    },
    {
      title: "Device Type",
      dataIndex: "devicetype",
      filterDropdown: <div className="d-flex justify-content-left align-items-center pt-2">
        <div className="demo ">
          <div
            style={{
              marginLeft: 10,
              marginBottom: 1,
              clear: "both",
              whiteSpace: "nowrap",
            }}
          >
            {contentDevType(
              productList,
              setProductList,
              productListTemp,
              setProductListTemp,
            )}
          </div>
        </div>
      </div>
    },
    {
      title: 'Created At',
      dataIndex: "createdat",
    },
    {
      title: 'Last Updated',
      dataIndex: "lastupdated",
    }
  ];
  return (
    <>
      <div className="table-responsive">
        <Pagination
          data={productList}
          handleChangePage={handleChangePage}
          searchParams={searchParams}
          setData={getCloudAllProductId}
          size={size}
          setSize={handleSetSize}
          children={
            !consumerStage && <div className="justify-content-start d-flex align-items-center mb-3">
              <span className="mr-2">
                <b>Search With Product Id</b>
              </span>
              <div
                className="form-group mb-0 mr-3 d-flex "
                style={{ minWidth: 100 }}
              >
                <input
                  onKeyPress={(e) => {
                    e.key === "Enter" && filterByProductId();
                  }}
                  onChange={(e) => {
                    setProductSearchId(e.target.value);
                  }}
                  defaultValue={productSearchId}
                  placeholder="Type Product Id"
                  id="Product_Id"
                  className="form-control form-control"
                  type="text"
                />
                <div
                  onClick={filterByProductId}
                  className="input-group-append"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className=" btn btn-primary"
                    id="basic-addon2"
                    style={{ zIndex: "auto" }}
                  >
                    OK
                  </span>
                </div>
              </div>
            </div>
          }
        />
        {productList && <Table
          pagination={false}
          columns={!consumerStage ? columns : columns.filter(x => !['createdat', 'lastupdated'].includes(x['dataIndex'])).concat([{ title: '', dataIndex: 'action' }])}
          onRow={(record, index) => {
            return {
              onClick: (event) => {

                const data = productList['data'][index]
                if (!consumerStage) {
                  setSearchParams((prev) => {
                    const result = {}
                    for (const data of prev) {
                      result[data[0]] = data[1]
                    }
                    result['modelId'] = data['modelId']
                    return result
                  })
                  getCloudProduct(data["modelId"]);
                  // setSizeWidth(6)
                }
              }
            }
          }}
          rowClassName={(record, index) => {
            const checkDisabled =
              selectedList &&
              selectedList.findIndex(
                (y) => y["modelId"] === productList['data'][index]["modelId"],
              ) !== -1;
            const bgColor = productInfo && productInfo["modelId"] === productList['data'][index]["modelId"];
            return searchParams.get('modelId') === productList['data'][index]['modelId'] ? `bg-color-custom` : bgColor ? `bg-color-aliceblue` : checkDisabled && `bg-color-aliceblue`
          }}
          dataSource={
            productList &&
            productList["data"].map((x, i) => {
              if (!development && !x['isReadyOEM'] && !consumerStage) {
                return;
              };
              const checkDisabled =
                selectedList &&
                selectedList.findIndex(
                  (y) => y["modelId"] === x["modelId"],
                ) !== -1;
              return {
                stt: i + 1,
                modelId: <div className="d-flex">
                  <div className="mr-2 align-items-center d-flex">
                    <img
                      width={70}
                      alt="img"
                      src={x['image'] ? x['image'] : getImg(Object.keys(IoTFullDeviceType)[
                        Object.values(IoTFullDeviceType).findIndex(
                          (z) => z === x["categoryInfo"][0],
                        )
                      ])}
                    />
                  </div>
                  <div>
                    <span
                      style={{ color: "black", cursor: "pointer" }}
                      onClick={() => { }}
                    >
                      {x["name"]}
                    </span>
                    <br />
                    <small>{x["modelId"]}</small>
                    <br />
                  </div>
                </div>,
                productType: <>
                  {!consumerStage ? (
                    x["isPublic"] ? (
                      <span className=" ml-2 badge badge-pill badge-success">
                        Public
                      </span>
                    ) : (
                      <>
                        <span className="text-dark ml-2 badge badge-pill badge-warning">
                          Private
                        </span>
                        {x["sharePartners"] &&
                          x["sharePartners"].length > 0 && (
                            <span className="text-white ml-2 badge badge-pill badge-success">
                              Shared
                            </span>
                          )}
                      </>
                    )
                  ) : (
                    <></>
                  )}

                  {consumerStage ? (
                    x["isPublic"] ? (
                      <span className=" ml-2 badge badge-pill badge-success">
                        Public Product
                      </span>
                    ) : (
                      <span className="text-dark ml-2 badge badge-pill badge-warning">
                        Share Product
                      </span>
                    )
                  ) : (
                    <></>
                  )}
                </>,
                features: generateFeatures(x),
                protocol: <>
                  {Object.values(IoTProtocol)
                    .map(
                      (z, i) =>
                        z.includes(x["baseInfo"][1]) &&
                        Object.values(IoTProtocolToName)[i],
                    )
                    .filter((z) => !!z)
                    .join("") ? Object.values(IoTProtocol)
                      .map(
                        (z, i) =>
                          z.includes(x["baseInfo"][1]) &&
                          Object.values(IoTProtocolToName)[i],
                      )
                      .filter((z) => !!z)
                      .join("") :
                    _.invert(PROTOCOL)[x["baseInfo"][1]]
                  }
                </>,
                devicetype: <>
                  {
                    Object.keys(IoTFullDeviceType)[
                    Object.values(IoTFullDeviceType).findIndex(
                      (z) => z === x["categoryInfo"][0],
                    )
                    ]
                  }
                </>,
                createdat: !consumerStage && new Date(x["createdAt"]).toLocaleString(),
                lastupdated: !consumerStage && new Date(x["updatedAt"]).toLocaleString(),
                action: <td
                  onClick={() => {
                    handleSetSelectedList(x, i);
                  }}
                >
                  {!checkDisabled && (
                    <RightOutlined
                      className="outline"
                      style={{
                        border: "0.5px solid ",
                        borderRadius: "4px",
                        color: "#979797",
                        float: "right",
                        marginTop: "6px",
                        paddingTop: 5,
                        paddingBottom: 5,
                        textAlign: "center",
                        width: " 23px",
                      }}
                      rev={undefined}
                    />
                  )}
                </td>
              };
            })
          }
        />}
      </div>
    </>
  );
};
