export const BRAND = {
  ANY: "0000",
  "FPT": "0001",
  "Rạng Đông": "0002",
  "Điện Quang": "0004",
  Heiman: "0006",
  Aquara: "0008",
  Tuya: "000A",
  Rogo: "7267",
  Yale: "000C",
  Hikvision: "000E",
  Sunricher: "0010"
};

// 4 bit
// DEPRECATED
export const CHIPSET = {
  ESP: [0, 1023], // range
  Telink: [1024, 2047],
  Nordic: [2048, 3017],
  DualChip: [60000, 65000],
  UNKNOWN: [0, 0],
};

// 4 bit
export const OWNERSHIP = {
  OEM: "0001",
  ODM: "0002",
  ODEM: "0003",
  OWNER: "000F",
};

// 9 bit
export const PROTOCOL = {
  Wile: "100",
  Bluetooth_LE: "101",
  Bluetooth_Mesh: "102",
  Bluetooth_Mesh_LE: "103",
  Zigbee: "104",
  Matter_Wifi: "105",
  Threads: "106",
  RFID: "107",
  'Zigbee + BLE Mesh': '1',
  'None': '0'
};

export const POWERTYPE = {
  standard: 0,
  low_power: 1
}

export const SHORT_PROTOCOL = {
  WILE: "WL",
  BLUETOOTH_LE: "B",
  BLUETOOTH_MESH: "B",
  BLUETOOTH_MESH_LE: "B", // BMLE
  ZIGBEE: "ZB",
  MATTER_WIFI: "MW",
  THREADS: "TR",
  RFID: "RFID",
  'Zigbee + BLE Mesh': 'ZBLEM'
};

export const ELEMENT = [0, 1, 2, 3];

export const DEVICE_SUBTYPE = {
  2: {
    1: "Đèn Bulb A6X - RGB",
    2: "Đèn panel PT04 - Non RGB",
    3: "Đèn panel PT07 - Non RGB",
    4: "Đèn LED strip LD01 - RGB",
    5: "Đèn Ceilling LN19 - Non RGB",
    6: "Đèn Ceilling LN23 - Non RGB",
    7: "Đèn Ceilling LN2X - Non RGB",
    8: "Đèn tracklight - Non RGB",
    9: "Đèn thả trần - Non RGB",
    10: "Đèn Downlight AT14 - Non RGB",
    11: "Đèn Downlight AT16 - Non RGB",
    12: "Đèn Downlight AT18 - Non RGB",
    13: "Đèn Downlight AT19 - Non RGB",
    14: "Đèn Downlight AT20 - Non RGB",
    15: "Đèn Downlight AT20 - RGB",
    16: "Đèn Downlight F18 - Non RGB",
    17: "Đèn Downlight F24 - Non RGB",
    18: "Đèn Downlight F36 - Non RGB",
    19: "Đèn Downlight A36 - Non RGB",
    20: "Đèn Downlight F24 - Non RGB",
  },
  3: {
    1: "Công tắc vuông có dây nguội",
    2: "Công tắc chữ nhật có dây nguội",
    3: "Công tắc vuông không dây nguội",
    4: "Công tắc chữ nhật ko dây nguội",
  },
  21: {
    1: "Công tắc nút nhấn",
    2: "Công tắc nút cảm ứng",
  },
  11: {
    1: "USB Điện Quang",
    2: "USB 2 chip",
    3: "USB FPT",
  },
  31: {
    1: "Cảm biến cửa DC",
  },
  34: {
    1: "Cảm biến chuyển động âm trần + ánh sáng AC",
    2: "Cảm biến chuyển động + ánh sáng AC",
  },
  35: {
    1: "Cảm biến ánh sáng DC",
  },
  30: {
    1: "Cảm biến nhiệt độ, độ ẩm DC",
  },
  32: {
    1: "Cảm biến khói DC",
  },
  38: {
    1: "Cảm biến hiện diện AC",
  },
  10: {
    1: "FPT Playbox T650",
    2: "FPT Playbox T590",
    3: "FPT Playbox T550",
    4: "Speaker",
  },
  19: {
    1: "Bộ điều khiển động cơ",
    2: "Công tắc không có khóa cảm ứng",
    3: "Công tắc có khóa cảm ứng",
  },
};
