import React, { useEffect, useState } from "react";
import { EditProduct } from "./product/editProduct";
import { ProductlistTable } from "./product/productListTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuProps, Switch, Form, Tabs, Button, Dropdown, Space, Modal, } from "antd";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";

const stepsDevelopment = [
  {
    name: "Developing",
    key: "developing"
  },
  {
    name: "Third party",
    key: "3rd"
  },
  {
    name: "Releasing",
    key: "releasing"
  },
  {
    name: "Released",
    key: "released"
  }
]
export const ProductDevelopment = () => {
  const [productInfo, setProductInfo] = useState<object>();
  const [productType, setProductType] = React.useState<boolean>();
  const [sharePartner, setSharePartner] = useState<object[]>();
  const [productName, setProductName] = useState<string>();
  const [brand, setBrand] = useState<string>();
  const [productDescription, setProductDescription] = useState<string>();
  const [counter, setCounter] = useState(0);
  const [listOTA, setListOTA] = React.useState<MenuProps["items"]>();
  const [size, setSize] = useState<number>(12)
  const [development, setDevelopment] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<any>(searchParams.get('selectField'))
  const [form] = Form.useForm();
  const navigate = useNavigate();
  useEffect(() => { }, []);

  const incrementCounter = () => {
    setCounter(counter + 1);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h3>Products Development</h3>
        <div
          className="breadcrumb"
          style={{ borderBottom: "3px solid rgb(232, 237, 235)" }}
        >
        </div>

      </div>

      <Tabs
        defaultActiveKey={tab}
        activeKey={tab}
        size={`large`}
        onChange={(e) => {
          setTab(e)
          // incrementCounter()
          setSearchParams({
            selectField: e,
            page: "1"
          })
          setProductName(undefined);
          setProductInfo(undefined);
          setProductType(undefined);
          setSharePartner(undefined);
          setSize(12)
        }}
        tabBarExtraContent={<div className="mr-3 align-items-center mb-3">
          {development && <>
            <Dropdown menu={{
              items: [
                {
                  label: <div onClick={() => navigate(`/org/product/add?step=1&type=product`)} >Create Product</div>,
                  key: '0',
                },
                {
                  type: 'divider',
                },
                {
                  label: <div onClick={() => navigate(`/org/product/add?step=1&type=3rd`)}>Create 3rd Product</div>,
                  key: '1',
                },
              ]
            }} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button
                    className="btn btn-success"
                    icon={<PlusOutlined rev={undefined} />}
                  >
                    Create{" "}
                  </Button>

                </Space>
              </a>
            </Dropdown>
          </>
          }
        </div>}
        items={stepsDevelopment.map((x, i) => {
          return {
            label: x['name'],
            key: x['key'],
            children: <>
              <div className="row">
                <div className={`mb-3 col-${size}`}>
                  <div className="card text-left">
                    <div className="card-body">
                      <ProductlistTable
                        productInfo={productInfo}
                        setProductInfo={setProductInfo}
                        setProductType={setProductType}
                        setSharePartner={setSharePartner}
                        setProductName={setProductName}
                        counter={counter}
                        handleSetSelectedList={undefined}
                        selectedList={undefined}
                        setListOTA={setListOTA}
                        setSizeWidth={setSize}
                        setProductDescription={setProductDescription}
                        development={development}
                        form={form}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </>
          };
        })}
      />
      <Modal
        width={`70%`}
        open={!!productInfo}
        cancelText={false}
        // mask={false}
        closeIcon={<><div></div></>}
        okButtonProps={{ disabled: true, className: 'd-none' }}
        cancelButtonProps={{ disabled: true, className: 'd-none' }}
        onOk={(handleOk => { })}
        keyboard={true}
        // confirmLoading={(confirmLoading) => return true}
        onCancel={(handleCancel) => {
          setProductName(undefined);
          setProductInfo(undefined);
          setProductType(undefined);
          setSharePartner(undefined);
        }}
      >
        {productInfo && (
          <EditProduct
            productInfo={productInfo}
            productType={productType}
            setProductType={setProductType}
            setSharePartner={setSharePartner}
            sharePartner={sharePartner}
            setProductName={setProductName}
            productName={productName}
            setProductInfo={setProductInfo}
            incrementCounterRoot={incrementCounter}
            listOTA={listOTA}
            setListOTA={setListOTA}
            setSizeWidth={setSize}
            setProductDescription={setProductDescription}
            productDescription={productDescription}
            setTab={setTab}
            form={form}
          />
        )}
      </Modal>
    </>
  );
};
