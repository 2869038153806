import React from "react";
export const Billing = () => {
  return (
    <>
      <h3>Billings</h3>
      <div
        className="breadcrumb"
        style={{ borderBottom: "3px solid rgb(232, 237, 235)" }}
      >
        <ul className=" pb-2">
          <li className="pl-0">
            <a href="/org/project">Dashboard</a>
          </li>
          <li>Version 1</li>
        </ul>
      </div>
    </>
  );
};
