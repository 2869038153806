import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Action } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import { Can } from "src/services/caslContext";
import "./partitals.css";
export const SideBarNoAction = () => {
  const { pathname: param } = useLocation();
  const { projectId, productId } = useParams();
  const getProjectRoute = () => {
    return projectId;
  };
  return (
    <>
      <div className="side-content-wrap">
        <div
          style={{ left: 0 }}
          className="sidebar-left-secondary rtl-ps-none ps"
          data-perfect-scrollbar
          data-suppress-scroll-x="true"
        >
          <ul className="childNav navigation-left p-0 d-block">
            {param.startsWith("/org") ? (
              <li className="nav-item d-block">
                <div
                  className="nav-item d-flex justify-content-center align-items-center"
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    padding: "0px",
                    fontSize: 14,
                  }}
                >
                  <span className="pr-2 pb-1">
                    <img
                      alt="org"
                      src={`${
                        window.location.origin + "/assets/images/org.svg"
                      }`}
                    />
                  </span>
                  <span
                    className="nav-item font-weight-bold"
                    style={{ fontSize: 12 }}
                  >
                    <b>ORGANIZATION</b>
                  </span>
                </div>
                <Link
                  to="/org/project"
                  className={`py-2 px-1 ${
                    param.startsWith("/org/project") && "item-name-active "
                  }`}
                >
                  <span className="item-name pl-2" style={{ fontWeight: 700 }}>
                    Project
                  </span>
                </Link>
                <Can I={Action.ReportView} a={Resources.Report}>
                  <Link
                    to={`/org/deviceactivations`}
                    className={`py-2 px-1 ${
                      param.endsWith("/deviceactivations") &&
                      "item-name-active "
                    }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      Device Activations
                    </span>
                  </Link>
                </Can>
                <Can I={Action.UserRead} a={"all"}>
                  <Link
                    to="/org/access"
                    className={`py-2 px-1 ${
                      param.startsWith("/org/access") && "item-name-active "
                    }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      Access Manager
                    </span>
                  </Link>
                </Can>
                <Can
                  I={Action.ProductViewAllPartnerModelId}
                  a={Resources.Product}
                >
                  <Link
                    to="/org/product?page=1"
                    className={`py-2 px-1 ${
                      param.startsWith("/org/product") && !param.startsWith("/org/product/development")  && "item-name-active "
                    }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      Product Manager
                    </span>
                  </Link>
                </Can>
                <Can
                  I={Action.ProductViewAllPartnerModelId}
                  a={Resources.Product}
                >
                  <Link
                    to="/org/product/development?page=1&selectField=developing"
                    className={`py-2 px-1 ${param.startsWith("/org/product/development") && "item-name-active "
                      }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      Product development
                    </span>
                  </Link>
                </Can>
                <Can
                  I={Action.HardwareViewAll}
                  a={Resources.Hardware}
                >
                  <Link
                    to="/org/module?page=1"
                    className={`py-2 px-1 ${param.startsWith("/org/module") && "item-name-active "
                      }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      Hardware Module
                    </span>
                  </Link>
                </Can>
                <Can
                  I={Action.HardwareViewAll}
                  a={Resources.Hardware}
                >
                  <Link
                    to="/org/firmware?type=developing"
                    className={`py-2 px-1 ${param.startsWith("/org/firmware") && "item-name-active "
                      }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      Firmware & SDK
                    </span>
                  </Link>
                </Can>
              </li>
            ) : (
              <li className="nav-item d-block  mb-3">
                <div
                  className="nav-item d-flex justify-content-center align-items-center"
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    padding: "0px",
                    fontSize: 14,
                  }}
                >
                  <span className="pr-2 pb-1">
                    <img
                      alt="org"
                      src={`${
                        window.location.origin + "/assets/images/org.svg"
                      }`}
                    />
                  </span>
                  <span
                    className="nav-item font-weight-bold"
                    style={{ fontSize: 12 }}
                  >
                    <b>PLATFORM</b>
                  </span>
                </div>
                <Link
                  to={`${getProjectRoute()}/overview`}
                  className={`py-2 px-1 ${
                    param.endsWith("/overview") && "item-name-active "
                  }`}
                >
                  <span className="item-name pl-2" style={{ fontWeight: 700 }}>
                    Overview
                  </span>
                </Link>
                <Link
                  to={`${getProjectRoute()}/enduser?page=1`}
                  className={`py-2 px-1 ${
                    param.endsWith("/enduser") && "item-name-active "
                  }`}
                >
                  <span className="item-name pl-2" style={{ fontWeight: 700 }}>
                    Report Enduser
                  </span>
                </Link>
                <Can I={Action.ReportView} a={Resources.User}>
                  <Link
                    to={`${getProjectRoute()}/users`}
                    className={`py-2 px-1 ${
                      param.endsWith("/users") && "item-name-active "
                    }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      Organization Users
                    </span>
                  </Link>
                </Can>
                <Can I={Action.AppView} a={Resources.App}>
                  <Link
                    to={`${getProjectRoute()}/app`}
                    className={`py-2 px-1 ${
                      param.endsWith("/app") && "item-name-active "
                    }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      Apps
                    </span>
                  </Link>
                </Can>
                <Can
                  I={Action.ProductViewAllPartnerModelId}
                  a={Resources.Product}
                >
                  <Link
                    to={`${getProjectRoute()}/product?page=1`}
                    className={`py-2 px-1 ${
                      param.includes("/product") && "item-name-active "
                    }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      Product Consumers
                    </span>
                  </Link>
                </Can>

                {/* <Can
                  I={Action.ProductViewAllPartnerModelId}
                  a={Resources.Product}
                >
                  <Link
                    to={`/org/ota`}
                    className={`py-2 px-1 ${param.startsWith("/org/ota") && "item-name-active "
                      }`}
                  >
                    <span
                      className="item-name pl-2"
                      style={{ fontWeight: 700 }}
                    >
                      OTA
                    </span>
                  </Link>
                </Can> */}
              </li>
            )}
          </ul>
        </div>
        <div className="sidebar-overlay"></div>
      </div>
    </>
  );
};
