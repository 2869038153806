// This is old protocol supported
// export class IoTProtocol {
//   public static ROGOPLAYBOX = [3];
//   public static BLMESH = [30, 31, 32, 33];
//   public static ZIGBEE = [4, 200, 201];
//   public static IPCAMERA = [239];
//   public static IRCONTROLLER = [20];
//   public static MIX_ZIGBEE_BLEMESH = [1];
// }

export class IoTProtocol {
  public static ROGOPLAYBOX = [3];
  public static BLMESH = [30, 31, 32, 33, 101, 102, 103];
  public static ZIGBEE = [4, 200, 201, 104];
  public static IPCAMERA = [239];
  public static IRCONTROLLER = [20, 100];
  public static MIX_ZIGBEE_BLEMESH = [1];
  public static MATTER_WIFI = [105];
  public static THREADS = [106];
  public static RFID = [107];
  public static GENERIC = [0];
}

export class IoTProtocolToName {
  public static ROGOPLAYBOX = "Rogo Play Box";
  public static BLMESH = "Bluetooth Mesh";
  public static ZIGBEE = "Zigbee";
  public static IPCAMERA = "Ip Camera";
  public static IRCONTROLLER = "WILE";
  public static MIX_ZIGBEE_BLEMESH = "Zigbee + BLE Mesh";
  public static MATTER_WIFI = "Matter Wifi";
  public static THREADS = "Threads";
  public static RFID = "RFID";
  public static GENERIC = "Generic";
}

export class IoTProtocolToImage {
  public static ROGOPLAYBOX = "/img_section_smarthome_hub.png";
  public static BLMESH = "/img_section_text_bluetooth_mesh.png";
  public static ZIGBEE = "/img_section_text_wile.png";
  public static IPCAMERA = "/img_section_text_ip_camera.png";
  public static IRCONTROLLER = "/ic_rg_ir_controller.png";
}
