import { Table, Popconfirm, Button, Modal, Space, Switch, Tag, Transfer, TransferProps } from "antd"
import React, { useContext, useState } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import { IoTAttribute } from "src/constant/features"
import { BackendContext } from "src/services/backend"
import { FirebaseContext } from "src/services/firebaseContext"
import * as _ from 'lodash'
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { TransferItem } from "antd/es/transfer"
import { ModalTarget } from "./modalTarget"
import { deleteModuleKeys } from "../completeProduct"

export const StepFeature = ({
    productInfo,
    incrementCounter
}) => {

    const { productId } = useParams()
    const be = useContext(BackendContext);
    const { defaultOrg } = useContext(FirebaseContext);
    const [loading, setLoading] = useState<boolean>()
    const [open, setOpen] = useState();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const isAddedModuleHardware = productInfo && !!productInfo['developmentInfo']['moduleName']
    const deleteFeature = async (element, index) => {
        const attrs = element[1]['attrs']
        const attrsAfterDelete = attrs.filter(x => x !== index)
        productInfo['extraInfo']['elmInfos'][element[0]] = { attrs: attrsAfterDelete }
        isAddedModuleHardware && deleteModuleKeys(productInfo['developmentInfo'])
        setLoading(true)
        const data = await be.modeleditProductId(defaultOrg, productId, {
            extraInfo: productInfo['extraInfo'],
            developmentInfo: productInfo['developmentInfo']
        });
        if (data) {
            toast.success('Update Successfully')
        }
        setLoading(false)
        incrementCounter()
    }


    const confirmDelete = async (extraInfo) => {
        setConfirmLoading(true);

        setTimeout(async () => {
            delete productInfo['extraInfo']['elmInfos'][extraInfo[0]]
            isAddedModuleHardware && deleteModuleKeys(productInfo['developmentInfo'])
            const data = await be.modeleditProductId(defaultOrg, productId, {
                extraInfo: productInfo['extraInfo'],
                developmentInfo: productInfo['developmentInfo']
            });
            if (data) {
                toast.success('Update Successfully')
            }
            setConfirmLoading(false);
            incrementCounter()
        }, 1000);


    }
    return (
        <>
            {
                productInfo && productInfo['extraInfo'] && productInfo['extraInfo']['elmInfos'] && Object.entries(productInfo['extraInfo']['elmInfos']).map((x: any, i) => {
                    return (
                        <div className="col-6" key={i}>
                            <ModalTarget
                                featureTarget={x}
                                setOpen={setOpen}
                                open={open}
                                be={be}
                                productInfo={productInfo}

                                incrementCounter={incrementCounter}
                            />

                            <div >
                                <div className="col-12">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="mb-2"><b>{'Element ' + x[0]}</b> </div>
                                        <div>
                                            <Popconfirm
                                                placement="topLeft"
                                                title={`Sure to delete this Element ?`}
                                                description={``}
                                                onConfirm={() => confirmDelete(x)
                                                }
                                                okText="Yes"
                                                cancelText="No"
                                                okButtonProps={{ loading: confirmLoading }}
                                            >
                                                <a
                                                    style={{ cursor: "pointer" }}
                                                    className="pr-2 text-danger mr-2 font-weight-bold"
                                                >
                                                    Delete
                                                </a>
                                            </Popconfirm>
                                            <button
                                                className="btn btn-success mb-2"
                                                onClick={() => setOpen(x[0])}
                                            >
                                                Add Features
                                            </button>
                                        </div>
                                    </div>
                                    <Table
                                        bordered={true}
                                        className="mb-5"
                                        pagination={false}
                                        size="small" dataSource={x[1] && x[1]['attrs'] && x[1]['attrs'].map((y, j) => {
                                            return {
                                                name: _.invert(IoTAttribute)[y],
                                                key: y,
                                                action: <>
                                                    <Popconfirm
                                                        placement="topLeft"
                                                        title={`Sure to delete this features ?`}
                                                        description={``}
                                                        onConfirm={() =>
                                                            deleteFeature(x, y)
                                                        }
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <a
                                                            style={{ cursor: "pointer" }}
                                                            className="pr-2 text-danger mr-2 font-weight-bold"
                                                        >
                                                            Delete
                                                        </a>
                                                    </Popconfirm>
                                                </>
                                            }
                                        })} columns={[
                                            {
                                                title: 'Feature Name',
                                                dataIndex: 'name',
                                            },
                                            {
                                                title: 'Feature Key',
                                                dataIndex: 'key',
                                            },
                                            {
                                                title: '',
                                                dataIndex: 'action',
                                            },
                                        ]}>

                                    </Table>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}