export interface IBAC {
  resources: string[];
  actions: string[];
}
export const RoleDecompile = {
  decompile: (abacs: IBAC[]) => {
    const result = [];
    for (const abac of abacs) {
      let info = {
        partnerId: "",
        projectId: "",
        role: [],
      };
      const { resources, actions } = abac;
      for (const resource of resources) {
        const partnerId = resource.split("/")[0].split(":")[1];
        const projectId = resource.split("/")[1];
        info["partnerId"] = partnerId;
        info["projectId"] = projectId;
      }
      for (const action of actions) {
        const role = action.split(":")[1];
        info["role"].push(role);
      }
      result.push(info);
    }
    return result;
  },
};
