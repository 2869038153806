export class IoTDeviceType {
  // public static OTHER = -1;
  // public static ALL = 0;

  public static LIGHT = 2;
  public static SWITCH = 3;
  public static PLUG = 4;
  // public static CURTAINS = 5;
  // public static DOORLOCK = 6;
  // public static DOORBELL = 8;

  public static MEDIA_BOX = 10;
  public static USB_DONGLE = 11;
  // public static REPEATER = 12;
  // public static CAMERA = 13;

  // public static AC = 16;
  // public static TV = 17;
  // public static FAN = 18;
  public static MOTOR_CONTROLLER = 19;

  // public static BUTTON_DASH = 20;
  public static SWITCH_SCENE = 21;
  // public static TEMP_SENSOR = 30;
  public static DOOR_SENSOR = 31;
  // public static SMOKE_SENSOR = 32;
  public static MOTION_LUX_SENSOR = 33;
  // public static MOTION_SENSOR = 34;
  // public static LUX_SENSOR = 35;
  // public static DUST_SENSOR = 36;

  // public static IR_DEVICE_CONTROLLER = 99;
  // public static GATE = 100;
  // public static GATEWAY = 192;

  // public static SENSOR_DEVICE = 1001;
  // public static OTHER_CONTROL_DEVICE = 1002;
  // public static IR_DEVICE_OLD = 1003;
}

export class IoTFullDeviceType {
  public static LIGHT = 2;
  public static SWITCH = 3;
  public static PLUG = 4;
  public static CURTAINS = 5;
  public static DOORLOCK = 6;
  public static DOORBELL = 8;

  public static MEDIA_BOX = 10;
  public static USB_DONGLE = 11;
  public static REPEATER = 12;
  public static CAMERA = 13;

  public static AC = 16;
  public static TV = 17;
  public static FAN = 18;
  public static MOTOR_CONTROLLER = 19;

  public static BUTTON_DASH = 20;
  public static SWITCH_SCENE = 21;
  public static TEMP_SENSOR = 30;
  public static DOOR_SENSOR = 31;
  public static SMOKE_SENSOR = 32;
  public static MOTION_LUX_SENSOR = 33;
  public static MOTION_SENSOR = 34;
  public static LUX_SENSOR = 35;
  public static DUST_SENSOR = 36;
  public static DIMMER_SCENE = 37;
  public static PRESENSCE_SENSOR = 38;
  public static SWITCH_LIGHT_DIMMER = 39;

  public static DIMMER = 64;

  public static AC_CONTROLLER = 96;

  public static IR_DEVICE_CONTROLLER = 99;
  public static GATE = 100;
  public static GATEWAY = 192;

  public static SENSOR_DEVICE = 1001;
}
