import React from "react";
import { toast } from "react-hot-toast";
import axiosInstance from "./axios";
export class BackendAPI {
  protected token: string;
  protected header: { headers: { Authorization: string } };
  constructor(token: string) {
    this.token = token;
    this.header = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
  }
  getHeaders() {
    return this.header;
  }
  async modelGetAllOEM(
    data: object
  ) {
    try {
      const modelGetAllOEM = await axiosInstance.post(
        `/model/oem`,
        {
          ...data
        },
        this.header,
      );
      return modelGetAllOEM.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async hardwareGetAllHardwareModule(
    data: object
  ) {
    try {
      const hardwareGetAllHardwareModule = await axiosInstance.post(
        `hardware-module/get`,
        {
          ...data
        },
        this.header,
      );
      return hardwareGetAllHardwareModule.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelGetAlldevices(
    partnerId: string,
    productId: string,
    page: number,
    size: number,
  ) {
    try {
      const getAlldevices = await axiosInstance.get(
        `/admin/model/modeldevices/${partnerId}/${productId}?size=${size}&page=${page}`,
        this.header,
      );
      return getAlldevices.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelGetNeedProductFirmware(
    partnerId: string
  ) {
    try {
      const getAlldevicesNeedProductFirmware = await axiosInstance.get(
        `/model/firmware/${partnerId}`,
        this.header,
      );
      return getAlldevicesNeedProductFirmware.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelGetFlasherUrl(
    partnerId: string,
    modelId: string,
    jsonfile: string,
    binfile: string
  ) {
    try {
      const getFlasherUrl = await axiosInstance.post(
        `/model/flasherurl`,
        {
          jsonfile,
          binfile,
          partnerId,
          modelId
        },
        this.header,
      );
      return getFlasherUrl.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelCreateOTAFolder(partnerId: string, data) {
    try {
      const createOTAFolder = await axiosInstance.post(
        `/admin/model/ota/folder/${partnerId}`,
        {
          ...data,
        },
        this.header,
      );
      return createOTAFolder.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }

  async modelReleaseProduct(partnerId: string, modelId: string, releaseStatus: string = 'developing') {
    try {
      const modelReleaseProduct = await axiosInstance.post(
        `/admin/model/release/${partnerId}`,
        {
          modelId,
          releaseStatus
        },
        this.header,
      );
      return modelReleaseProduct.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }

  async hardwareCreateHardwareModuleFolder(partnerId: string, data) {
    try {
      const createHardwareModuleFolder = await axiosInstance.post(
        `hardware-module/module/folder/${partnerId}`,
        {
          ...data,
        },
        this.header,
      );
      return createHardwareModuleFolder.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelUploadImagefile(partnerId: string, formData) {
    try {
      const uploadImagefile = await axiosInstance.post(
        `admin/model/image/upload/${partnerId}`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            ...this.getHeaders()["headers"],
          },
        },
      );
      return uploadImagefile.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async hardwareUploadModuleYaml(partnerId: string, formData) {
    try {
      const uploadBinfile = await axiosInstance.post(
        `hardware-module/upload/${partnerId}`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            ...this.getHeaders()["headers"],
          },
        },
      );
      return uploadBinfile.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async firmwareUploadModuleYaml(partnerId: string, formData) {
    try {
      const uploadBinfile = await axiosInstance.post(
        `admin/firmware/upload/${partnerId}`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            ...this.getHeaders()["headers"],
          },
        },
      );
      return uploadBinfile.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async firmwareGetDownloadUrl(partnerId: string, path) {
    try {
      const uploadBinfile = await axiosInstance.post(
        `admin/firmware/downloadUrl/${partnerId}`,
        {
          path
        },
        this.header,
      );
      return uploadBinfile.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async firmwareGetAll(partnerId, productId?) {
    try {
      const getAllFirmwares = await axiosInstance.get(
        productId ? `admin/firmware/get/${partnerId}?productId=${productId}` : `admin/firmware/get/${partnerId}`,
        this.header,
      );
      return getAllFirmwares.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelUploadBinfile(partnerId: string, formData) {
    try {
      const uploadBinfile = await axiosInstance.post(
        `/admin/model/ota/upload/${partnerId}`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            ...this.getHeaders()["headers"],
          },
        },
      );
      return uploadBinfile.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectuserUpdateUserMode(userMode: object) {
    try {
      const userUpdateUserMode = await axiosInstance.post(
        `projectuser/usermode`,
        {
          ...userMode,
        },
        this.header,
      );
      return userUpdateUserMode.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async hardwareGetSpecificHardwareInS3(partnerId: string, path: string) {
    try {
      const data = await axiosInstance.post(
        `hardware-module/getSpecific/module`,
        {
          path,
          partnerId
        },
        this.header,
      );
      return data.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async hardwareGetHardwareObjectInS3(partnerId: string, moduleId?: string) {
    try {
      const data = await axiosInstance.post(
        `hardware-module/module/${partnerId}`,
        {
          moduleId,
          partnerId
        },
        this.header,
      );
      return data.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelGetObjectInS3(partnerId: string, modelId: string) {
    try {
      const data = await axiosInstance.post(
        `admin/model/ota/${partnerId}`,
        {
          modelId,
        },
        this.header,
      );
      return data.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelGetProjectProduct(partnerId: string, projectId: string) {
    try {
      const data = await axiosInstance.get(
        `model/get/projectproduct/${partnerId}/${projectId}`,
        this.header,
      );
      return data.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelEncodeProductInfo(data: object) {
    try {
      const dataEncodeProductInfo = await axiosInstance.post(
        `model/encode`,
        {
          ...data,
        },
        this.header,
      );
      return dataEncodeProductInfo.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelAddprojectproduct(data: object) {
    try {
      const addprojectproduct = await axiosInstance.post(
        `model/add/projectproduct`,
        {
          ...data,
        },
        this.header,
      );
      return addprojectproduct.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async partnerGetinfo(partnerId: string) {
    try {
      const data = await axiosInstance.get(
        `partner/get/${partnerId}`,
        this.header,
      );
      return data.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelgetProductConsumer(partnerId: string, page: number, size: number) {
    try {
      const getProductConsumer = await axiosInstance.post(
        `model/consumer?size=${size}&page=${page}`,
        {
          partnerId,
        },
        this.header,
      );
      return getProductConsumer.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelgetAllProductIds(partnerId: string, page: number, size: number, selectField: string) {
    try {
      const getPartnerProductIds = await axiosInstance.get(
        `model/get/${partnerId}?size=${size}&page=${page}&selectField=${selectField}`,
        this.header,
      );
      return getPartnerProductIds.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modelgetProductId(partnerId: string, productId: string) {
    try {
      const getPartnerProductIds = await axiosInstance.get(
        `model/get/${partnerId}/${productId}`,
        this.header,
      );
      return getPartnerProductIds.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }

  async modelgetProductIdDevelopment(partnerId: string, productId: string) {
    try {
      const getPartnerProductIds = await axiosInstance.get(
        `model/get/development/${partnerId}/${productId}`,
        this.header,
      );
      return getPartnerProductIds.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async modeleditProductId(partnerId: string, productId: string, data: object) {
    try {
      const bodyPartner = {
        partnerId,
        modelId: productId,
      };
      let featuresMap
      if (data['extraInfo'] && data['extraInfo']['elmInfos']) {
        const elmInfosInDb = data['extraInfo']['elmInfos']
        const features = []
        for (const elmInfo in elmInfosInDb) {
          if (elmInfosInDb.hasOwnProperty(elmInfo)) {
            const attrs = elmInfosInDb[elmInfo]['attrs']
            features.push(attrs)
          }
        }
        featuresMap = features.flat()
        data['wrapFeatures'] = featuresMap
      }
      const editPartnerProductIds = await axiosInstance.post(
        `model/edit`,
        {
          ...data,
          ...bodyPartner,
        },
        this.header,
      );
      return editPartnerProductIds.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectusergetPartnerUserDevices(
    partnerId: string,
    projectId: string,
    puid: string,
  ) {
    try {
      const getPartnerUserDevices = await axiosInstance.get(
        `projectuser/devices/${partnerId}/${projectId}/${puid}`,
        this.header,
      );
      return getPartnerUserDevices.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectusergetByPartnerUserId(
    partnerId: string,
    projectId: string,
    puid: string,
  ) {
    try {
      const getByPartnerUserId = await axiosInstance.get(
        `projectuser/get/${partnerId}/${projectId}/${puid}`,
        this.header,
      );
      return getByPartnerUserId.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectusergetAlluser(
    partnerId: string,
    projectId: string,
    page: number,
    size: number,
  ) {
    try {
      const getAll = await axiosInstance.get(
        `projectuser/get/${partnerId}/${projectId}?size=${size}&page=${page}`,
        this.header,
      );
      return getAll.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async appsdkDeleteApp(partnerId: string, projectId: string, appId: string) {
    try {
      const appDelete = await axiosInstance.post(
        `appsdk/delete`,
        { appId, partnerId, projectId },
        this.header,
      );
      return appDelete.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async appsdkCreateApp(
    partnerId: string,
    projectId: string,
    name: string,
    type: number,
    packageNameOrBundleId: string,
    version: string,
  ) {
    try {
      const body = {
        partnerId,
        name,
        type,
        projectId,
        version,
      };
      type === 1
        ? (body["bundleId"] = packageNameOrBundleId)
        : type === 2
          ? (body["packageName"] = packageNameOrBundleId)
          : "";
      const createApps = await axiosInstance.post(
        `appsdk/add`,
        body,
        this.header,
      );
      return createApps.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectuserViewReportEndUser(
    partnerId: string,
    projectId: string,
    start: Date,
    to: Date,
  ) {
    try {
      const viewReportEndUser = await axiosInstance.post(
        `projectuser/stat`,
        { partnerId, start, to, projectId },
        this.header,
      );
      return viewReportEndUser.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async appsdkGetAllAppInAProject(partnerId: string, projectId: string) {
    try {
      const getAll = await axiosInstance.get(
        `appsdk/get/${partnerId}/${projectId}`,
        this.header,
      );
      return getAll.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectGetAll(partnerId: string) {
    try {
      const getAll = await axiosInstance.get(
        `project/getWithPermission/${partnerId}`,
        this.header,
      );
      return getAll.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectGetDetail(partnerId: string, projectId: string) {
    try {
      const getAll = await axiosInstance.get(
        `project/get/${partnerId}/${projectId}`,
        this.header,
      );
      return getAll.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectGetApiKey(partnerId: string, projectId: string) {
    try {
      const getAll = await axiosInstance.get(
        `projectapikey/get/${partnerId}/${projectId}`,
        this.header,
      );
      return getAll.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectGetWhiteListedIp(partnerId: string, projectId: string) {
    try {
      const getWhiteListedIps = await axiosInstance.get(
        `projectapikey/getwhitelistedip/${partnerId}/${projectId}`,
        this.header,
      );
      return getWhiteListedIps.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectUpdateWhiteListedIp(
    partnerId: string,
    projectId: string,
    whitelistedIps: string[],
  ) {
    try {
      const updateWhiteListedIps = await axiosInstance.post(
        `projectapikey/update`,
        {
          projectId,
          partnerId,
          whitelistedIps,
        },
        this.header,
      );
      toast.success("Update Whitelisted IPs successfully");
      return updateWhiteListedIps.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async userpartnerGetAllWithPermission(partnerId: string, projectId: string) {
    try {
      const getAll = await axiosInstance.get(
        `userpartner/getPermission/${partnerId}/${projectId}`,
        this.header,
      );
      return getAll.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async userpartnerGetAll(partnerId: string) {
    try {
      const getAll = await axiosInstance.get(
        `userpartner/get/${partnerId}`,
        this.header,
      );
      return getAll.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async userpartnerGetAllInProject(partnerId: string, projectId: string) {
    try {
      const getAll = await axiosInstance.get(
        `userpartner/get/${partnerId}/${projectId}`,
        this.header,
      );
      return getAll.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async userpartnerAddToSystem(
    data: object,
    partnerId: string,
    projectId: string,
  ) {
    try {
      const add = await axiosInstance.post(
        `userpartner/add`,
        {
          partnerId,
          name: data["name"],
          email: data["email"],
          password: data["pass"],
        },
        this.header,
      );
      return add.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async abacAttachRoleToUser(
    ownerId: string,
    partnerId: string,
    projectId: string,
    abac: any,
    isAddFunc: boolean,
    detach: boolean = false,
  ) {
    try {
      const attach = await axiosInstance.post(
        `abac/attach`,
        {
          partnerId,
          ownerId,
          projectId,
          abac,
          isAddFunc,
          detach,
        },
        this.header,
      );
      return attach.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async userpartnerDelete(partnerId: string, uuid: string) {
    try {
      const deleteUser = await axiosInstance.post(
        `userpartner/delete`,
        {
          partnerId,
          uuid,
        },
        this.header,
      );
      return deleteUser.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async abacGetUserABAC(partnerId: string, uuid: string) {
    try {
      const getUserAbac = await axiosInstance.get(
        `abac/get/${partnerId}/${uuid}`,
        this.header,
      );
      return getUserAbac.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async userpartnerCheckExist(
    email: string,
    partnerId: string,
    projectId: string,
  ) {
    try {
      const checkUserExist = await axiosInstance.post(
        `userpartner/checkUserExist`,
        {
          email,
          partnerId,
          projectId,
        },
        this.header,
      );
      return checkUserExist.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectAdd(partnerId: string, name: string) {
    try {
      const checkUserExist = await axiosInstance.post(
        `project/add`,
        {
          partnerId,
          name,
        },
        this.header,
      );
      return checkUserExist.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectEdit(partnerId: string, projectId: string, data: object) {
    try {
      const edit = await axiosInstance.post(
        `project/edit/${partnerId}/${projectId}`,
        {
          partnerId,
          ...data,
        },
        this.header,
      );
      return edit.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async projectDelete(partnerId: string, projectId: string) {
    try {
      const deleteProject = await axiosInstance.post(
        `project/delete`,
        { partnerId, projectId },
        this.header,
      );
      return deleteProject.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async deviceactivationViewReport(
    partnerId: string,
    start: Date,
    to: Date,
    manufacturer: number[],
    productId: string[],
  ) {
    try {
      const viewReport = await axiosInstance.post(
        `deviceactivation/get`,
        { partnerId, start, to, manufacturer, productId },
        this.header,
      );
      return viewReport.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async deviceactivationViewPieReportData(
    partnerId: string,
    start: Date,
    to: Date,
  ) {
    try {
      const viewReport = await axiosInstance.post(
        `deviceactivation/get/pieData`,
        { partnerId, start, to },
        this.header,
      );
      return viewReport.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async deviceactivationViewPieReportTableData(
    partnerId: string,
    start: Date,
    to: Date,
  ) {
    try {
      const viewReport = await axiosInstance.post(
        `deviceactivation/get/tableProductData`,
        { partnerId, start, to },
        this.header,
      );
      return viewReport.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }

  async deviceactivationManufacturer(partnerId: string, start: Date, to: Date) {
    try {
      const viewReport = await axiosInstance.post(
        `deviceactivation/get/manufaturer`,
        { partnerId, start, to },
        this.header,
      );
      return viewReport.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
  async deviceactivationGetExportData(
    partnerId: string,
    year: number,
    month: number,
  ) {
    try {
      const data = await axiosInstance.post(
        `deviceactivation/get/getExportS3Link`,
        {
          partnerId,
          year,
          month,
        },
        this.header,
      );
      return data.data;
    } catch (e) {
      if (e.response.status === 403) {
        toast.error("Access Denied");
      } else {
        toast.error(JSON.stringify(e.response.data.message));
      }
    }
  }
}

export const BackendContext = React.createContext<BackendAPI>(
  new BackendAPI(undefined),
);
