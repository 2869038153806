import * as React from "react";

export const TabDevices = () => {
  return (
    <>
      {/* <div className="d-flex justify-content-end mb-2">
                <button type="button" className="btn btn-success btn-icon m-1">
                    <span className="ul-btn__icon"><i className="i-Add"></i></span>Add Device
                </button>
            </div> */}
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h4 className="mt-2">Devices</h4>
                </div>
                <div className="form-group m-0 ">
                  <button
                    data-backdrop="static"
                    data-keyboard="false"
                    data-toggle="modal"
                    data-target=""
                    type="button"
                    className="btn btn-success btn-icon m-1"
                  >
                    <span className="ul-btn__icon">
                      <i className="i-Add"></i>
                    </span>
                    Add Devices
                  </button>
                </div>
              </div>

              <div
                className="tab-pane fade show active"
                id="4"
                role="tabpanel"
                aria-labelledby="4"
              >
                <table id="" className="table text-left table-hover">
                  <thead>
                    <tr style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                      <th scope="col">Device Name</th>
                      <th scope="col">Device ID</th>
                      <th scope="col">Product</th>
                      <th scope="col">Status</th>
                      <th scope="col">Device Type</th>
                      <th scope="col">Activation Time</th>
                      <th scope="col">Device Permission</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ cursor: "pointer" }}>
                      <th scope="row">ROGO</th>
                      <td>12345678code</td>
                      <td>Smart Hotel</td>
                      <td>
                        <span className="badge badge-success">Active</span>
                      </td>
                      <td> Bulb</td>
                      <td>2022-06-16 13:54:45</td>
                      <td>No</td>
                      <td>
                        <a href="#" className="text-success mr-2">
                          <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                        </a>
                        <a href="#" className="text-danger mr-2">
                          <i className="nav-icon i-Close-Window font-weight-bold"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
