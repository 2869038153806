import * as React from "react";
import { useParams } from "react-router-dom";
import { DatePicker2, convertTimezone } from "src/components/datePicker";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import Chart from "react-apexcharts";
import { Empty } from "antd";
import { ProjectUserTable } from "./elements/projectUsersTable";
import { AbilityContext, Can } from "src/services/caslContext";
import { Action } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import { useAbility } from "@casl/react";

export const TabEndUser = () => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();

  // Get the start of the month in UTC
  const startOfMonth = new Date(year, month, 1, 0, 0, 0, 0);
  React.useEffect(() => {
    ability.can(Action.ProjectViewUserReport, Resources.Project) &&
      getReports();
  }, []);
  // Get the end of the month in UTC
  const nextMonth = month + 1;
  const startOfNextMonth = new Date(year, nextMonth, 1, 0, 0, 0, 0);
  const endOfMonth = new Date(startOfNextMonth.getTime() - 1);

  const [data, setData] = React.useState<any>();
  const [dateRange, setDateRange] = React.useState<any>([
    startOfMonth.getTime(),
    endOfMonth.getTime(),
  ]);
  const [openDate, setOpenDate] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [startDate, endDate] = dateRange;
  const be = React.useContext(BackendContext);
  const { defaultOrg } = React.useContext(FirebaseContext);
  const { projectId } = useParams();
  const [empty, setEmpty] = React.useState<boolean>(false);
  const [activeUserDevices, setActiveUserDevices] = React.useState<any>();
  const [linkingInfo, setLinkingInfo] = React.useState<any>();
  const [collapseDeviceActivated, setCollapseDeviceActivated] =
    React.useState<boolean>();
  const [collapseLinking, setCollapseLinking] = React.useState<boolean>();
  const ability = useAbility(AbilityContext);
  function groupByLabel(data) {
    const groupedData = {};

    for (const item of data) {
      if (item.locInfo[0]) {
        const label = item.locInfo[0]["label"];

        if (groupedData[label]) {
          groupedData[label].push(item);
        } else {
          groupedData[label] = [item];
        }
      } else {
        if (groupedData["none"]) {
          groupedData["none"].push(item);
        } else {
          groupedData["none"] = [item];
        }
      }
    }

    return groupedData;
  }
  function groupByLabelOfGroup(data) {
    const groupedData = {};
    for (const item of data) {
      if (item.grpInfo[0]) {
        const label = item.grpInfo[0]["label"];

        if (groupedData[label]) {
          groupedData[label].push(item);
        } else {
          groupedData[label] = [item];
        }
      } else {
        if (groupedData["none"]) {
          groupedData["none"].push(item);
        } else {
          groupedData["none"] = [item];
        }
      }
    }

    return groupedData;
  }

  const getReports = async (
    start = convertTimezone(dateRange[0]),
    to = convertTimezone(dateRange[1]),
  ) => {
    setLoading(true);
    const startTimestamp: any =
      typeof start === "object" ? start.getTime() : start;
    const toTimestamp: any = typeof to === "object" ? to.getTime() : to;
    const viewReport = await be.projectuserViewReportEndUser(
      defaultOrg,
      projectId,
      startTimestamp,
      toTimestamp,
    );
    if (viewReport.length === 0) {
      setEmpty(true);
      setData({ data: [] });
    } else {
      setEmpty(false);
      setData({ data: viewReport });
    }

    setLoading(false);
  };
  const transform = (num: number) => {
    if (num.toString().length === 1) {
      return "0" + num.toString();
    } else {
      return num.toString();
    }
  };
  function numberMonthToName(arg0: any) {
    throw new Error("Function not implemented.");
  }
  const LINKING_PARTNER = {
    1: "Google Assistant",
    3: "Olli Maika",
  };
  const LINKING_TYPE = {
    1: "All Devices",
    2: "Groups",
    3: "Groups And Cross Group Control",
    4: "Locations",
  };
  return (
    <>
      <div className="row">
        <Can I={Action.ProjectViewUserReport} a={Resources.Project}>
          <div className="col-12 mb-3">
            <div>
              <h4 className="mt-2">Project Users Activated</h4>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <div className="justify-content-start d-flex align-items-center mb-3">
                  <span>
                    <b>
                      <i>Date Range:</i>
                    </b>
                  </span>
                  <div
                    className="picker"
                    onClick={() => {
                      setOpenDate(true);
                    }}
                  >
                    <div
                      className="font-weight-bold dropdown-toggle"
                      style={{ cursor: "pointer", paddingRight: 25 }}
                    >
                      {typeof startDate === "object"
                        ? startDate.toLocaleDateString()
                        : new Date(startDate).toLocaleDateString()}{" "}
                      -{" "}
                      {typeof endDate === "object"
                        ? endDate.toLocaleDateString()
                        : new Date(endDate).toLocaleDateString()}
                    </div>
                  </div>
                  <div
                    className="shadow"
                    style={{ left: 0, top: 0, zIndex: 1, position: "absolute" }}
                  >
                    <DatePicker2
                      openDate={openDate}
                      setOpenDate={setOpenDate}
                      getReports={getReports}
                      startDate={startDate}
                      endDate={endDate}
                      setDateRange={setDateRange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="card mb-4" style={{ maxHeight: 300 }}>
                      <div className="card-body">
                        <div className="mb-1 text-22 font-weight-light font-weight-bold">
                          <div className="heading d-flex justify-content-between align-items-center">
                            <div className="heading d-flex justify-content-start align-items-center">
                              <h1 className="heading text-success font-weight-bold">
                                {data &&
                                  data["data"].reduce((x, i) => x + i["y"], 0)}
                              </h1>
                              <small
                                className="text-muted pl-2"
                                style={{ fontSize: "60%", fontWeight: 800 }}
                              >
                                Total User Registered from{" "}
                                {new Date(startDate).toLocaleDateString()} -{" "}
                                {new Date(endDate).toLocaleDateString()}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div
                          className="progress mb-1"
                          style={{ height: "4px" }}
                        >
                          <div
                            className="progress-bar bg-warning"
                            style={{ width: `${1000000 / 10000}%` }}
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <h4 className="mt-2">User Registered</h4>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show active"
                      id="4"
                      role="tabpanel"
                      aria-labelledby="4"
                    >
                      {loading && (
                        <div className="spinner spinner-success mr-3"></div>
                      )}
                      {empty && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                      {data && data["data"].length > 0 && (
                        <Chart
                          type={`line`}
                          options={{
                            chart: {
                              height: 280,
                              dropShadow: {
                                enabled: !0,
                                top: 3,
                                left: 3,
                                blur: 1,
                                opacity: 0.2,
                              },
                            },
                            stroke: {
                              width: 3,
                            },
                            xaxis: {
                              categories: data["data"].map(
                                (x) =>
                                  `${x["x"]["year"]}${
                                    x["x"]["date"]
                                      ? "/" + transform(x["x"]["month"] + 1)
                                      : x["x"]["month"] + 1
                                      ? "-" + numberMonthToName(x["x"]["month"])
                                      : ""
                                  }${
                                    x["x"]["date"]
                                      ? "/" + transform(x["x"]["date"])
                                      : ""
                                  }`,
                              ),
                            },
                            fill: {
                              type: "gradient",
                              gradient: {
                                shade: "dark",
                                gradientToColors: ["#fe1b57"],
                                shadeIntensity: 1,
                                type: "horizontal",
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 100, 100, 100],
                              },
                            },
                            markers: {
                              size: 1,
                              colors: ["#FFA41B"],
                              strokeWidth: 1,
                              hover: {
                                size: 7,
                              },
                            },
                            yaxis: [
                              {
                                title: {
                                  text: "User Registered",
                                  style: {
                                    fontWeight: "normal",
                                    fontFamily: "inherit",
                                    fontSize: "14",
                                  },
                                },
                              },
                            ],
                          }}
                          series={[
                            {
                              name: "User Registered",
                              data: data["data"].map((x) => x["y"]),
                            },
                          ]}
                          height={280}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Can>
      </div>
      <div className="row">
        <Can I={Action.ProjectUserViewUserDevices} a={Resources.Project}>
          <div className="col-12 col-sm-12  col-lg-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <h4 className="mt-2">Project Users Information</h4>
              </div>
            </div>
            <div
              className="tab-pane fade show active"
              id="4"
              role="tabpanel"
              aria-labelledby="4"
            >
              <ProjectUserTable
                setLinkingInfo={setLinkingInfo}
                canView={ability.can(Action.ProjectUpdate, Resources.Project)}
                setActiveUserDevices={setActiveUserDevices}
                activeUserDevices={activeUserDevices}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-8">
            <div
              className="tab-pane fade show active"
              id="4"
              role="tabpanel"
              aria-labelledby="4"
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="text-left d-flex justify-content-end">
                  <a
                    onClick={() =>
                      setCollapseDeviceActivated(!collapseDeviceActivated)
                    }
                    href="#collapse-icon"
                    className="text-default collapsed btn btn-default p-0 m-0"
                    data-toggle="collapse"
                    aria-expanded="false"
                  >
                    <div>
                      <h4 className="mt-2">
                        Device Activated{" "}
                        <b>
                          {activeUserDevices &&
                            `by ${activeUserDevices["puid"]}`}
                        </b>
                        {collapseDeviceActivated ? (
                          <i className="px-2 i-Arrow-Up-in-Circle"></i>
                        ) : (
                          <i className="px-2 i-Arrow-Down-in-Circle"></i>
                        )}
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
              <div className="collapse show" id="collapse-icon">
                <table id="" className="table text-left ">
                  <thead>
                    <tr style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                      <th scope="col">Location</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <div className="spinner spinner-success mr-3"></div>
                      </tr>
                    )}
                    {!loading &&
                      activeUserDevices &&
                      activeUserDevices.data &&
                      activeUserDevices.data.length > 0 &&
                      Object.entries(groupByLabel(activeUserDevices.data)).map(
                        (x: any) => {
                          const grps = groupByLabelOfGroup(x[1]);
                          return (
                            <tr>
                              <td className="border">
                                {x[0] === "none" ? "" : x[0]}
                              </td>
                              <table className="w-100">
                                <thead>
                                  <th scope="col" className="border-right">
                                    Group
                                  </th>
                                  <th>Devices</th>
                                </thead>
                                <tbody>
                                  {Object.entries(grps).map((y: any) => {
                                    return (
                                      <>
                                        <tr>
                                          <td className="border-right">
                                            {y[0] === "none" ? "" : y[0]}
                                          </td>
                                          <table className="w-100 table-hover">
                                            <thead>
                                              <th
                                                scope="col"
                                                className="border-right"
                                              >
                                                Label
                                              </th>
                                              <th
                                                scope="col"
                                                className="border-right"
                                              >
                                                MAC
                                              </th>
                                              <th
                                                scope="col"
                                                className="border-right"
                                              >
                                                CreatedAt
                                              </th>
                                            </thead>
                                            <tbody>
                                              {y[1].map((z) => {
                                                return (
                                                  <>
                                                    <tr
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <td className="border-right">
                                                        {z["label"]}
                                                      </td>
                                                      <td className="border-right">
                                                        {z["mac"]}
                                                      </td>
                                                      <td className="border-right">
                                                        {z["createdAt"]}
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </tr>
                          );
                        },
                      )}

                    {activeUserDevices &&
                      activeUserDevices.data &&
                      activeUserDevices.data.length === 0 && (
                        <tr>
                          <td colSpan={6}>
                            <Empty />
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3 pt-4">
                <a
                  onClick={() => setCollapseLinking(!collapseLinking)}
                  href="#collapse-icon2"
                  className="text-default collapsed btn btn-default p-0 m-0"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <div>
                    <h4 className="mt-2">
                      Linking 3rd by{" "}
                      <b>
                        {activeUserDevices && `by ${activeUserDevices["puid"]}`}
                      </b>
                      {collapseLinking ? (
                        <i className="px-2 i-Arrow-Up-in-Circle"></i>
                      ) : (
                        <i className="px-2 i-Arrow-Down-in-Circle"></i>
                      )}
                    </h4>
                  </div>
                </a>
              </div>

              <div className="collapse show" id="collapse-icon2">
                <table className="table text-left">
                  <thead>
                    <tr style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                      <th>Linking id</th>
                      <th>Linking with</th>
                      <th>Linking type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {linkingInfo && (
                    <tbody>
                      {linkingInfo.length === 0 && (
                        <tr>
                          <td colSpan={3}>
                            <Empty />
                          </td>
                        </tr>
                      )}

                      {Object.entries(linkingInfo).map((x) => {
                        return (
                          <tr>
                            <td>
                              <div>{x[0]}</div>
                            </td>
                            <td>{LINKING_PARTNER[x[1]["type"]]}</td>
                            <td>{LINKING_TYPE[x[1]["linking"]]}</td>
                            <td>
                              <a
                                className="text-danger text-bold"
                                style={{ cursor: "pointer" }}
                              >
                                <b>Disconnect</b>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </Can>
      </div>
    </>
  );
};
