import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import "./elements/project.css";
import Modal from "react-modal";
import { Action } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import { Can } from "src/services/caslContext";
import "../../../assets/modal.css";

export const TabApps = () => {
  const [apps, setApp] = useState<any[]>();
  const { projectId } = useParams();
  const { defaultOrg } = React.useContext(FirebaseContext);
  const navigate = useNavigate();
  const be = React.useContext(BackendContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>();
  const [bundleId, setBundleId] = React.useState<string>();
  const [packageName, setPackageName] = React.useState<string>();
  const [ver, setVer] = React.useState<string>("1.0.0");
  const [modalIsOpen, setIsOpen] = React.useState<string | undefined>();
  const getAllAppInAProject = async () => {
    setLoading(true);
    const data = await be.appsdkGetAllAppInAProject(defaultOrg, projectId);
    setApp(data);
    setLoading(false);
  };
  const openModal = () => {
    setShow(!show);
  };

  function modalDelete(uuid: string) {
    setIsOpen(uuid);
  }
  function closeModal() {
    setIsOpen(undefined);
  }
  const createAppFunc = async () => {
    setLoading(true);
    if (!bundleId && !packageName) {
      return;
    }
    if (bundleId) {
      await be.appsdkCreateApp(defaultOrg, projectId, name, 1, bundleId, ver);
    }

    if (packageName) {
      await be.appsdkCreateApp(
        defaultOrg,
        projectId,
        name,
        2,
        packageName,
        ver,
      );
    }
    await getAllAppInAProject();
    setLoading(false);
    setName("");
    setBundleId("");
    setPackageName("");
    openModal();
  };
  const deleteApp = async (appId: string) => {
    setLoading(true);
    await be.appsdkDeleteApp(defaultOrg, projectId, appId);
    await getAllAppInAProject();
    setLoading(false);
  };
  useEffect(() => {
    getAllAppInAProject();
  }, []);
  return (
    <>
      <Modal
        isOpen={show}
        // onRequestClose={modalDelete}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            background: "none!important",
            border: 0,
            width: "900px",
          },
        }}
      >
        <div
          className=""
          id="modalAdd"
          role="dialog"
          aria-labelledby="modalAdd"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="verifyModalContent_title">
                  {"Create Apps"}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={openModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <>
                    <div className="form-group w-100 container">
                      <label
                        htmlFor="recipient-name-2"
                        className="col-form-label"
                      >
                        <span className="text-danger">*</span> Name:
                      </label>
                      <input
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    {!bundleId && !packageName && (
                      <div className="form-group w-100 container m-0">
                        <label
                          htmlFor="recipient-name-2"
                          style={{ color: "red" }}
                        >
                          <i>
                            ** One BundleId or PackageName has to be defined{" "}
                          </i>
                        </label>
                      </div>
                    )}
                    <div className="form-group w-100 container">
                      <label
                        htmlFor="recipient-name-2"
                        className="col-form-label"
                      >
                        BundleId For IOS:
                      </label>
                      <input
                        onChange={(e) => {
                          setBundleId(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        placeholder="BundleId "
                      />
                    </div>
                    <div className="form-group w-100 container">
                      <label
                        htmlFor="recipient-name-2"
                        className="col-form-label"
                      >
                        Package Name for Android:
                      </label>
                      <input
                        onChange={(e) => {
                          setPackageName(e.target.value);
                        }}
                        type="text"
                        placeholder=" Package Name"
                        className="form-control"
                      />

                      <label>
                        **
                        <i>
                          If both <b>BundleId</b> and <b>PackageName</b> are
                          defined then 2 app will be created for IOS, ANDROID
                          respectively{" "}
                        </i>
                        {/* and contain the following types: numbers, uppercase letters, lowercase letters and special characters. */}
                      </label>
                    </div>
                    <div className="form-group w-100 container">
                      <label
                        htmlFor="recipient-name-2"
                        className="col-form-label"
                      >
                        Version:
                      </label>
                      <input
                        onChange={(e) => {
                          setVer(e.target.value);
                        }}
                        type="text"
                        defaultValue={ver}
                        className="form-control"
                        placeholder="Version "
                      />
                    </div>
                  </>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setName("");
                    setBundleId("");
                    setPackageName("");
                    openModal();
                  }}
                  className="btn btn-secondary"
                  data-dismiss={`${show ? "modal" : ""}`}
                >
                  Close
                </button>
                {!loading && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      createAppFunc();
                    }}
                    type="button"
                    className="btn btn-primary"
                  >
                    OK
                  </button>
                )}
                {loading && (
                  <div className="spinner spinner-success mr-3"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="breadcrumb d-flex justify-content-start">
        <h1>Project Apps</h1>
        <Can I={Action.AppCreated} a={Resources.App}>
          <div>
            <button
              onClick={openModal}
              type="button"
              className="btn btn-success btn-icon m-1"
            >
              <span className="ul-btn__icon">
                <i className="i-Add"></i>
              </span>
              Create Apps
            </button>
          </div>
        </Can>
      </div>
      <div className="separator-breadcrumb border-top"></div>
      {loading && (
        <div className="">
          <div className="spinner spinner-success mr-3"></div>
        </div>
      )}
      <div className="row">
        {!loading &&
          apps &&
          apps.map((x) => {
            return (
              <div
                className="col-lg-4 col-md-4 col-sm-12"
                style={{ zIndex: 0 }}
              >
                <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4 border-tuya">
                  <div className="card-body justify-content-between">
                    <div className="d-block w-100">
                      <small>
                        **:{" "}
                        <i>
                          {" "}
                          <b>
                            Use AppSecret and AppKey to get app's information{" "}
                          </b>{" "}
                        </i>
                      </small>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="font-weight-bold">{x["name"]}</h4>
                        <div className="d-flex">
                          {/* <a onClick={() => { }} href="#" className="text-success mr-2 font-weight-bold">
                                                        Edit
                                                    </a> */}
                          <Can I={Action.AppDeleted} a={Resources.App}>
                            <a
                              onClick={() => modalDelete(x["uuid"])}
                              href="#"
                              className="text-danger mr-2 font-weight-bold"
                            >
                              Delete
                            </a>
                            <Modal
                              isOpen={modalIsOpen === x["uuid"]}
                              // onRequestClose={modalDelete}
                              ariaHideApp={false}
                              style={{
                                content: {
                                  top: "50%",
                                  left: "50%",
                                  right: "auto",
                                  bottom: "auto",
                                  marginRight: "-50%",
                                  transform: "translate(-50%, -50%)",
                                },
                              }}
                            >
                              <h2>Delete {x["name"]} ?</h2>
                              <div className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  onClick={closeModal}
                                  className="btn btn-secondary"
                                >
                                  Close
                                </button>
                                {!loading && (
                                  <button
                                    type="button"
                                    onClick={() => deleteApp(modalIsOpen)}
                                    className="btn btn-danger ml-3"
                                  >
                                    Delete
                                  </button>
                                )}
                                {loading && (
                                  <div className="spinner spinner-success mr-3"></div>
                                )}
                              </div>
                            </Modal>
                          </Can>
                        </div>
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        {x["type"] === 1 && (
                          <div className="SdkButton_icon__1gvgy d-flex justify-content-center align-items-center text-center">
                            <span
                              role="img"
                              aria-label="apple"
                              font-size="16"
                              className="anticon anticon-apple"
                              style={{ color: "rgb(151 147 147)" }}
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="apple"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5-34.9-50-87.7-77.5-157.3-82.8-65.9-5.2-138 38.4-164.4 38.4-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8 23.8 68.2 109.6 235.3 199.1 232.6 46.8-1.1 79.9-33.2 140.8-33.2 59.1 0 89.7 33.2 141.9 33.2 90.3-1.3 167.9-153.2 190.5-221.6-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7-44.8 2.6-96.6 30.5-126.1 64.8-32.5 36.8-51.6 82.3-47.5 133.6 48.4 3.7 92.6-21.2 129-63.7z"></path>
                              </svg>
                            </span>
                            <span className="SdkButton_text__1WJpk pt-1">
                              {x["version"]}
                            </span>
                          </div>
                        )}
                        {x["type"] === 2 && (
                          <div className="SdkButton_icon__1gvgy d-flex justify-content-center align-items-center text-center">
                            <span
                              role="img"
                              aria-label="android"
                              font-size="16"
                              className="anticon anticon-android"
                              style={{ color: "rgb(172, 196, 82)" }}
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="android"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M270.1 741.7c0 23.4 19.1 42.5 42.6 42.5h48.7v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h85v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h48.7c23.5 0 42.6-19.1 42.6-42.5V346.4h-486v395.3zm357.1-600.1l44.9-65c2.6-3.8 2-8.9-1.5-11.4-3.5-2.4-8.5-1.2-11.1 2.6l-46.6 67.6c-30.7-12.1-64.9-18.8-100.8-18.8-35.9 0-70.1 6.7-100.8 18.8l-46.6-67.5c-2.6-3.8-7.6-5.1-11.1-2.6-3.5 2.4-4.1 7.4-1.5 11.4l44.9 65c-71.4 33.2-121.4 96.1-127.8 169.6h486c-6.6-73.6-56.7-136.5-128-169.7zM409.5 244.1a26.9 26.9 0 1126.9-26.9 26.97 26.97 0 01-26.9 26.9zm208.4 0a26.9 26.9 0 1126.9-26.9 26.97 26.97 0 01-26.9 26.9zm223.4 100.7c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c.1-30.6-24.3-55.3-54.6-55.3zm-658.6 0c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c0-30.6-24.5-55.3-54.6-55.3z"></path>
                              </svg>
                            </span>
                            <span className="SdkButton_text__1WJpk pt-1">
                              {x["version"]}
                            </span>
                          </div>
                        )}
                      </div>
                      <div>
                        <div>
                          <b>Creation Time:</b>{" "}
                          {new Date(x["createdAt"]).toLocaleString()}
                        </div>
                      </div>
                      <h5 className="font-weight-bold mt-3">Authorization</h5>
                      <div>
                        {x["type"] === 2 ? (
                          <div>
                            <b>PackageName:</b> {x["packageName"]}
                          </div>
                        ) : x["type"] === 1 ? (
                          <div>
                            <b>BundleId:</b> {x["bundleId"]}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="my-1 d-flex justify-content-between mt-1">
                          <div className="font-weight-bold">AppKey:</div>
                          <span>{x["appKey"]}</span>
                        </div>
                        <div className="d-flex justify-content-between mt-1">
                          <div className="font-weight-bold">App Secret:</div>
                          <span>{x["appSecret"]} </span>
                          {/* <a href="#" onClick={viewProjectApiKey} style={{ backgroundColor: 'ButtonShadow' }} className="px-2 ml-4">
                                                        <span className="font-weight-bold" style={{ fontSize: 10 }}>
                                                            {apiKey ? `Hide` : `View`}
                                                        </span>
                                                    </a> */}
                        </div>
                        <small>
                          <b>**</b>{" "}
                          <i>
                            For security reasons, Keep the appSecret secret.
                          </i>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
