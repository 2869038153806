import React, { useEffect, useState } from "react";
import { EditProduct } from "./product/editProduct";
import { ProductlistTable } from "./product/productListTable";
import { useNavigate } from "react-router-dom";
import { MenuProps, Switch, Form, Modal, } from "antd";

export const Product = () => {
  useEffect(() => { }, []);
  const [productInfo, setProductInfo] = useState<object>();
  const [productType, setProductType] = React.useState<boolean>();
  const [sharePartner, setSharePartner] = useState<object[]>();
  const [productName, setProductName] = useState<string>();
  const [productDescription, setProductDescription] = useState<string>();
  const [counter, setCounter] = useState(0);
  const [listOTA, setListOTA] = React.useState<MenuProps["items"]>();
  const [size, setSize] = useState<number>(12)
  const [development, setDevelopment] = useState<boolean>(false)
  const navigate = useNavigate();

  const incrementCounter = () => {
    setCounter(counter + 1);
  };
  const addProduct = () => {
    navigate(`/org/product/add?step=1`);
  };
  return (
    <>
      <h3>Products</h3>
      <div
        className="breadcrumb"
        style={{ borderBottom: "3px solid rgb(232, 237, 235)" }}
      >
        <ul className=" pb-2">
          <li className="pl-0">
            <a href="/org/project">Dashboard</a>
          </li>
          <li>Version 1</li>
        </ul>
      </div>
      <div className="row">
        <div className={`mb-3 col-${size}`}>
          <div className="card text-left">
            <div className="card-body">
              <div className="mr-3 align-items-center mb-3">
                {/* <div className=""> 
                  <Form.Item
                    name="switch"
                    className=" mr-4"
                    label={<h4 className="mt-2 mr-2">Development</h4>}
                    valuePropName="checked"
                  >
                    <Switch
                      onChange={(e) => {
                        setDevelopment(!development);
                      }}
                      defaultChecked={development}
                    />
                  </Form.Item>
                </div> */}
                {development && <div>
                  <button
                    className="btn btn-success"
                    onClick={() => addProduct()}
                  >
                    + Add Product{" "}
                  </button>
                </div>}
              </div>
              <ProductlistTable
                productInfo={productInfo}
                setProductInfo={setProductInfo}
                setProductType={setProductType}
                setSharePartner={setSharePartner}
                setProductName={setProductName}
                counter={counter}
                handleSetSelectedList={undefined}
                selectedList={undefined}
                setListOTA={setListOTA}
                setSizeWidth={setSize}
                setProductDescription={setProductDescription}
                development={development}
                stage={`MANAGER`}
              />
            </div>
          </div>
        </div>
        {productInfo && (
          <Modal
            width={`70%`}
            open={!!productInfo}
            cancelText={false}
            closeIcon={<><div></div></>}
            okButtonProps={{ disabled: true, className: 'd-none' }}
            cancelButtonProps={{ disabled: true, className: 'd-none' }}
            keyboard={true}
            onOk={(handleOk => { })}
            // confirmLoading={(confirmLoading) => return true}
            onCancel={(handleCancel) => {
              setProductName(undefined);
              setProductInfo(undefined);
              setProductType(undefined);
              setSharePartner(undefined);
            }}
          >
            <EditProduct
              productInfo={productInfo}
              productType={productType}
              setProductType={setProductType}
              setSharePartner={setSharePartner}
              sharePartner={sharePartner}
              setProductName={setProductName}
              productName={productName}
              setProductInfo={setProductInfo}
              incrementCounterRoot={incrementCounter}
              listOTA={listOTA}
              setListOTA={setListOTA}
              setSizeWidth={setSize}
              setProductDescription={setProductDescription}
              productDescription={productDescription}
            />
          </Modal>
        )}
      </div>
    </>
  );
};
