import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import { toast } from "react-hot-toast";
import { RoleAttach } from "./elements/roleAttach";
import Modal from "react-modal";
import { Action, ActionToName } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import "../../../assets/modal.css";

export const AttachUsers = () => {
  const [email, setEmail] = useState<string>();
  const [emails, setEmails] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState({});
  const [modalIsOpen, setIsOpen] = React.useState<string | undefined>();

  const navigate = useNavigate();
  const be = React.useContext(BackendContext);
  const { projectId } = useParams();
  const { defaultOrg } = React.useContext(FirebaseContext);

  const buildAbac = () => {
    const result = {};
    for (const user of Object.entries(users)) {
      const actions = Object.values(Action);
      const actionResource = [];
      actions.map((x, i) => {
        Object.values(Resources).forEach((resource, index) => {
          if (user[1][i]) {
            if (actions[i].startsWith(resource)) {
              actionResource.push(`${resource}:${actions[i]}`);
            }
          }
        });
      });
      const abacBuilder = [
        {
          resources: [`partner:${defaultOrg}:project/${projectId}`],
          actions: actionResource,
        },
      ];
      result[user[0]] = abacBuilder;
    }
    return result;
  };

  const submit = async () => {
    if (Object.keys(users).length > 0) {
      setLoading(true);
      const abacBuilds: any = buildAbac();
      for (const abacBuild of Object.entries(abacBuilds)) {
        const attachRole = await be.abacAttachRoleToUser(
          abacBuild[0],
          defaultOrg,
          projectId,
          abacBuild[1],
          false,
        );
        if (attachRole) {
          toast.success(`Add ${emails[abacBuild[0]]} To Project Successfully`);
          removeItem(abacBuild[0]);
        }
      }
      setLoading(false);
    }
  };
  const addUser = async () => {
    if (
      email &&
      !users[email] &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      setLoading(true);
      const checkExist = await be.userpartnerCheckExist(
        email,
        defaultOrg,
        projectId,
      );
      if (checkExist && checkExist["exist"]) {
        toast.error("Email existed in this project");
      } else if (checkExist && !checkExist["exist"]) {
        const ownerId = checkExist["ownerId"];
        emails[ownerId] = email;
        users[ownerId] = new Array(Object.values(Action).length).fill(false);
        setEmails(emails);
        setUsers(users);
        setEmail("");
      }
      setLoading(false);
    }
  };
  const setUserPermission = (ownerId: string, permission: boolean[]) => {
    let usersTemp = users;
    usersTemp[ownerId] = permission;
    setUsers(usersTemp);
  };
  const removeItem = (ownerId: string) => {
    let usersTemp = users;
    let emailsTemp = emails;
    delete emails[ownerId];
    delete usersTemp[ownerId];
    setEmails(emailsTemp);
    setUsers(usersTemp);
  };
  function modalSet(ownerId: string) {
    setIsOpen(ownerId);
  }
  function closeModal() {
    setIsOpen(undefined);
  }
  return (
    <>
      <div className="main-content">
        <div className="breadcrumb">
          <h1>Attach User To Project</h1>
        </div>
        <div className="separator-breadcrumb border-top"></div>
        <div className="row">
          <div className="col-6 mb-3">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-group w-100 container">
                    <div>
                      <h4 className="mt-2">Email</h4>
                      <label htmlFor="inputEmail4" className="ul-form__label">
                        Attach new users into this project:
                      </label>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder="Search for.."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      {!loading && (
                        <div
                          onClick={addUser}
                          className="input-group-append"
                          style={{ cursor: "pointer" }}
                        >
                          <span className=" btn btn-primary" id="basic-addon2">
                            OK
                          </span>
                        </div>
                      )}
                      {loading && (
                        <div className="spinner spinner-success mr-3"></div>
                      )}
                    </div>
                    {!email ? (
                      <label htmlFor="recipient-name-2" className="text-danger">
                        Enter Email Address
                      </label>
                    ) : (
                      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                        email,
                      ) && (
                        <label
                          htmlFor="recipient-name-2"
                          className="text-danger"
                        >
                          Not Valid Email Format
                        </label>
                      )
                    )}
                  </div>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                {users &&
                  Object.entries(users).map((x: any, i) => {
                    return (
                      <div
                        key={i.toString()}
                        className="d-flex justify-content-between align-items-center mt-2"
                      >
                        <div className="form-group container d-flex flex-column">
                          <span className="mr-3 font-weight-bold">
                            {emails[x[0]]}
                          </span>
                          <span>
                            <i>
                              {x[1].map((i, j) => {
                                return (
                                  i &&
                                  ActionToName[Object.values(Action)[j]] + ", "
                                );
                              })}
                            </i>
                          </span>
                        </div>
                        <div className="form-group container">
                          <a
                            onClick={() => {
                              modalSet(x[0]);
                            }}
                            className="text-success mr-2 border border-dark border-3 rounded p-2"
                            style={{ backgroundColor: "#efefef" }}
                          >
                            <i
                              style={{ color: "black" }}
                              className="nav-icon i-Pen-2 font-weight-bold"
                            ></i>
                          </a>
                          <Modal
                            isOpen={modalIsOpen === x[0]}
                            // onRequestClose={modalDelete}
                            ariaHideApp={false}
                            style={{
                              content: {
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                marginRight: "-50%",
                                transform: "translate(-50%, -50%)",
                              },
                            }}
                          >
                            <RoleAttach
                              closeModal={closeModal}
                              emails={emails}
                              users={x}
                              setUserPermission={setUserPermission}
                            />
                          </Modal>
                          <a onClick={() => removeItem(x[0])} className="ml-1">
                            <b>Remove</b>
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                {!loading && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      submit();
                    }}
                    type="button"
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                )}
                {loading && (
                  <div className="spinner spinner-success mr-3"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
