import { FileAddOutlined } from "@ant-design/icons";
import { Drawer, Empty, Menu, Input, Button, Form } from "antd";
import React, { useState } from "react";

export const OTADrawer = (
    {
        open,
        setOpen,
        verInput,
        setVerInput,
        listOTA,
        onFinishVerInput
    }
) => {
    const [loading, setLoading] = useState<boolean>(false);
    return <Drawer
        title="OTA Firmware"
        placement="right"
        onClose={() => {
            setOpen(false);
            setVerInput(false);
        }}
        open={open}
    >
        {loading && <div className="spinner spinner-success mr-3"></div>}

        {listOTA && listOTA.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {!loading && (
            <Menu
                defaultOpenKeys={
                    listOTA &&
                    listOTA[0] &&
                    listOTA[0]["children"].map((x) => x["key"])
                }
                mode="inline"
                items={listOTA && listOTA[0] && listOTA[0]["children"]}
            />
        )}
        <div>
            {verInput && (
                <>
                    <div>
                        <Form
                            name="wrap"
                            labelAlign="left"
                            labelWrap
                            wrapperCol={{ flex: 1 }}
                            colon={false}
                            className="mt-3"
                            onFinish={onFinishVerInput}
                        >
                            <Form.Item
                                className="mb-2"
                                label="Version Folder"
                                name="path"
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter the correct path format",
                                        pattern: /^[a-zA-Z0-9_.\/]+\/$/,
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        defaultValue={
                                            listOTA[0] &&
                                            listOTA[0]["children"].map((x) => x["label"])[
                                            listOTA[0]["children"].length - 1
                                            ] + "/"
                                        }
                                    />
                                    <small>
                                        Enter path with format :{" "}
                                        <i>
                                            <b>folder/subfolder/</b>
                                        </i>{" "}
                                        <br />
                                        We will create new folder if the folder has not
                                        existed yet <br />
                                    </small>
                                </div>
                            </Form.Item>
                            <Form.Item
                                className="mb-1 justify-content-end d-flex"
                                label=" "
                            >
                                <Button type="primary" htmlType="submit">
                                    Add
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </>
            )}
        </div>
        <div className="mt-2">
            <Button
                onClick={() => setVerInput(!verInput)}
                className="d-flex align-items-center"
                icon={!verInput && <FileAddOutlined rev={undefined} />}
            >
                {!verInput ? `Create New version` : `Cancel`}
            </Button>
        </div>
    </Drawer>
}