import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Toaster } from "react-hot-toast";
import { BackendAPI, BackendContext } from "./backend";
import { auth } from "./config";
import { IAbac } from "src/constant/resources";
import { useParams } from "react-router-dom";
import { RogosdkService } from "./iotDecode";
export const FirebaseContext = React.createContext({
  user: {},
  userOrg: [],
  defaultOrg: undefined,
  setDefautlOrg: undefined,
  abacs: undefined,
});

export const firebaseHOC = (Component) => (props) => {
  const [userOrg, setUserOrg] = useState<Array<string>>();
  const [abacs, setAbac] = useState<Array<IAbac>>();
  const [defautlOrg, setDefautlOrg] = useState<string>();
  const setDefautlOrgFunc = (org) => {
    setDefautlOrg(org);
    localStorage.setItem("defaultOrg", org);
  };
  useEffect(() => {}, [abacs]);

  const [user, loading, error] = useAuthState(auth, {
    onUserChanged: async (user: User) => {
      const getCustomClaim = await user.getIdTokenResult(true);
      const { claims } = getCustomClaim;
      const { abac: abacs } = claims;
      const rogosdkService = new RogosdkService();
      const abacHex: any = rogosdkService.decodeHexAbac(abacs);
      setAbac(abacHex);
      const usersPartner = [];
      if (abacHex.length > 0) {
        for (const element of abacHex) {
          const { resources, actions } = element;
          for (const resource of resources) {
            const getUserOrg = resource.split("/")[0].split(":")[1];
            !usersPartner.includes(getUserOrg) && usersPartner.push(getUserOrg);
          }
        }
      }
      setUserOrg(usersPartner);

      const orgDefault = localStorage.getItem("defaultOrg");
      if (!orgDefault) {
        setDefautlOrgFunc(usersPartner[0]);
      } else {
        setDefautlOrgFunc(orgDefault);
      }
    },
  });
  if (loading) {
    return (
      <div>
        <div className="loadscreen" id="preloader">
          <div className="loader spinner-bubble spinner-bubble-primary"></div>
        </div>
      </div>
    );
  }
  return (
    <FirebaseContext.Provider
      value={{
        user: user && user.toJSON(),
        userOrg: userOrg && userOrg.length > 0 && userOrg,
        defaultOrg: user && localStorage.getItem("defaultOrg"),
        setDefautlOrg: (data: string) => setDefautlOrgFunc(data),
        abacs: abacs && abacs,
      }}
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
      <BackendContext.Provider
        value={
          user &&
          user["stsTokenManager"] &&
          new BackendAPI(user["stsTokenManager"]["accessToken"])
        }
      >
        <Component trigger={loading} />
      </BackendContext.Provider>
    </FirebaseContext.Provider>
  );
};
