import { Button, Card, Divider, Empty, Form, Steps, Typography } from "antd"
import style from "antd/es/alert/style";
import Meta from "antd/es/card/Meta"
import React, { useContext, useEffect, useState } from "react"
import { BackendContext } from "src/services/backend";
import YamlViewer from "./yamlViewer"
import yaml from 'js-yaml';
import * as _ from 'lodash'
import { IoTAttribute } from "src/constant/features";
import { POWERTYPE, PROTOCOL } from "src/constant/productModelNewVer";
import { toast } from "react-hot-toast";
import { FirebaseContext } from "src/services/firebaseContext";
import { IoTFullDeviceType } from "src/constant/deviceType";
import { getImg } from "../productListTable";
const { Paragraph } = Typography;

export const StepHardware = ({
    currentRootStep,
    productInfo,
    incrementCounter,
    confirmEdit,
    counter,
    smartmode,
    productChoice,
    setProductChoice
}) => {
    const be = useContext(BackendContext);
    const SMART_MODE = [
        {
            title: 'Rogo OS',
            key: 'ROGO_OS',
            description: 'There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain...'
        },
        // {
        //     title: 'Rogo Link',
        //     key: 'ROGO_LINK',
        //     description: 'There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain...'
        // },
    ]
    const [form] = Form.useForm();
    const [current, setCurrent] = useState<number>(1)
    const [selectModel, setSelectModel] = useState<string>(productInfo['developmentInfo']['moduleName'])
    const [oemModels, setOemModels] = useState<any>()
    const [hardwareModule, setHardwareModule] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false);
    const [isAddBefore, setIsAddBefore] = useState<boolean>(productInfo['developmentInfo']['moduleName'] ? true : false)
    const { defaultOrg } = useContext(FirebaseContext);
    useEffect(() => {
        modelGetAllOEM().then(x => {
            getAllHardwareModule()
        })
    }, [smartmode, currentRootStep])

    const modelGetAllOEM = async () => {
        setLoading(true);
        const elmInfosInDb = productInfo['extraInfo']['elmInfos']
        const features = []
        for (const elmInfo in elmInfosInDb) {
            if (elmInfosInDb.hasOwnProperty(elmInfo)) {
                const attrs = elmInfosInDb[elmInfo]['attrs']
                features.push(attrs)
            }
        }
        const getAllOem = await be.modelGetAllOEM({
            brand: '0000',
            baseInfo: productInfo['baseInfo'],
            wrapFeatures: features.flat(),
            categoryInfo: productInfo['categoryInfo'],

        })
        setOemModels(getAllOem)
        setLoading(false);

    }
    const getAllHardwareModule = async () => {
        const elmInfosInDb = productInfo['extraInfo']['elmInfos']
        const protocols = [_.invert(PROTOCOL)[productInfo['baseInfo'][1]].toLowerCase()]
        const powerTypes = [_.invert(POWERTYPE)[productInfo['categoryInfo'][2]]]
        const features = []
        for (const elmInfo in elmInfosInDb) {
            if (elmInfosInDb.hasOwnProperty(elmInfo)) {
                const attrs = elmInfosInDb[elmInfo]['attrs']
                features.push(attrs)
            }
        }
        const uniqueFeaturesWithName = _.uniq(features.flat()).map(x => _.invert(IoTAttribute)[x].toLowerCase())
        const getAllHardwareModule = await be.hardwareGetAllHardwareModule({
            features: uniqueFeaturesWithName,
            protocols,
            powerTypes
        })
        setHardwareModule(getAllHardwareModule)
    }
    const generateSummaryProductInfo = () => {
        const elmInfosInDb = productInfo['extraInfo']['elmInfos']
        const protocols = [_.invert(PROTOCOL)[productInfo['baseInfo'][1]].toLowerCase()]
        const powerTypes = [_.invert(POWERTYPE)[productInfo['categoryInfo'][2]]]
        const developmentInfo = productInfo['developmentInfo']

        const features = []
        for (const elmInfo in elmInfosInDb) {
            if (elmInfosInDb.hasOwnProperty(elmInfo)) {
                const attrs = elmInfosInDb[elmInfo]['attrs']
                features.push(attrs)
            }
        }
        const uniqueFeaturesWithName = _.uniq(features.flat()).map(x => _.invert(IoTAttribute)[x].toLowerCase())

        return <>
            <div className="d-flex mt-2 mb-2 justify-content-between align-items-center">
                <Paragraph>
                    {
                        Object.entries(developmentInfo).map((x: any, i) => {
                            return typeof x[1] !== 'object' && <div key={i} className="ml-3 mr-3"><b>{x[0]}</b>: {x[1]}</div>
                        })
                    }

                    <div className="ml-3 mr-3"><b>protocols</b>: {protocols.join(',')}</div>
                    <div className="ml-3 mr-3"><b>powerTypes</b>: {powerTypes.join(',')}</div>
                    <div className="ml-3 mr-3"><b>features</b>: {uniqueFeaturesWithName && uniqueFeaturesWithName.join(', ')}</div>
                </Paragraph>

                {smartmode && smartmode === 'ROGO_OS' && <div className="col-6 border-right">
                    <div className=" mb-4">
                        <small className="pb-2">These are product in <b>Store</b> which available with your product's configuration</small>
                        {
                            oemModels && oemModels.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                        <div className="row">
                            {
                                oemModels && oemModels.map((x, i) => {
                                    const isRighPowertype = x['categoryInfo'][2] === productInfo['categoryInfo'][2]

                                    return (
                                        <div key={i} className="col-12">
                                            <Card
                                                onClick={() => chooseModel(x['modelId'], 'STORE')}
                                                key={i.toString()}
                                                hoverable={true}
                                                className={selectModel === x['modelId'] && `card-selected`}
                                                style={{
                                                    marginTop: 10,
                                                    border: selectModel === x['modelId'] && '1px solid #005cff'
                                                }}
                                            >
                                                <div className="d-flex">

                                                    <div className="mr-2 align-items-center d-flex">
                                                        <img
                                                            width={70}
                                                            alt="img"
                                                            src={x['image'] ? x['image'] : getImg(Object.keys(IoTFullDeviceType)[
                                                                Object.values(IoTFullDeviceType).findIndex(
                                                                    (z) => z === x["categoryInfo"][0],
                                                                )
                                                            ])}
                                                        />
                                                    </div>
                                                    <Meta title={<>
                                                        <div>
                                                            <b className="font-weight-bold">{x['name']}</b>
                                                        </div>
                                                        <small className="mt-0 mb-0">{x['modelId']}</small>
                                                    </>} description={<div>{x['description']} <br /> {!isRighPowertype && <b className="font-weight-bold text-danger">!!! Your Product powertype is not match to that product in the store</b>}</div>} />
                                                </div>
                                            </Card>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {productChoice === 'STORE' && <div className="" style={{ textAlign: 'end' }}>
                            <Button onClick={() => handleSubmit(oemModels && oemModels.filter(x => x['modelId'] === selectModel))} type="primary" htmlType="submit" className="mt-3">
                                Confirm
                            </Button>
                        </div>}
                    </div>
                </div>}
            </div>
        </>
    }
    const generateHardwareInfo = (hardwareInfo) => {
        const powerTypes = hardwareInfo['metadata']['supported_power']
        const protocols = Object.keys(hardwareInfo['protocol'])
        const uniqueFeaturesWithName = Object.keys(hardwareInfo['featureSupported'])
        return <>
            <div className="d-flex mt-2 mb-2">
                <Paragraph>

                    <div className="ml-3 mr-3"><b>protocols supported</b>: {protocols.join(',')}</div>
                    <div className="ml-3 mr-3"><b>power type supported</b>: {powerTypes.join(',')}</div>
                    <div className="ml-3 mr-3"><b>features supported</b>: {uniqueFeaturesWithName && uniqueFeaturesWithName.join(', ')}</div>
                </Paragraph>
            </div>
        </>
    }
    const chooseModel = (x, choice) => {
        setSelectModel(x)
        setProductChoice(choice)
    }
    const handleSubmit = async (data) => {
        // console.log({ selectModel, productChoice, data })
        if (productChoice === 'STORE') {
            // console.log('data', data)
            const developmentInfo = {
                ...productInfo['developmentInfo'],
                moduleType: productChoice,
                moduleName: selectModel,
                moduleDetail: data[0]
            }
            const updateData = await be.modeleditProductId(defaultOrg, productInfo['modelId'], {
                developmentInfo: {
                    ...developmentInfo,
                }
            });
            if (updateData) {
                toast.success('Update Successfully')
                incrementCounter()
                setIsAddBefore(true)
            }
        } else if (productChoice === 'MODULE') {
            //  {
            //     "metadata-mode": "master_slave",
            //     "metadata-version": 1,
            //     "metadata-mesh_control": false,
            //     "brightness": "i2c",
            //     "onoff": "gpio",
            //     "brightness-template": "pca9685",
            //     "brightness-i2c_pin-sda": 20,
            //     "brightness-i2c_pin-scl": 21,
            //     "onoff-io_number": 6,
            //     "onoff-on_level": "low",
            //     "onoff-zero_detect": "enable",
            //     "onoff-zero_detect-zero_detect-gpio": 2,
            //     "onoff-zero_detect-zero_detect-trigger": "rising",
            //     "onoff-zero_detect-zero_detect-delay": 0
            // }
            const buildJSON = {}
            for (const moduleKey in data) {
                if (data.hasOwnProperty(moduleKey)) {
                    const getRootKey = moduleKey.split(`-`)[0]
                    const getRawKey = moduleKey.split(`-`)[1]
                    const getSubKey = moduleKey.split(`-`)[2]
                    if (getRootKey === 'metadata') {
                        if (!buildJSON[getRootKey]) {
                            buildJSON[getRootKey] = {}
                        }
                        buildJSON[getRootKey][getRawKey] = data[moduleKey]
                    } else {
                        if (!buildJSON['features']) {
                            buildJSON['features'] = {}
                        }
                        if (!buildJSON['features'][getRootKey]) {
                            buildJSON['features'][getRootKey] = {}
                        }

                        // features key
                        if (!getRawKey) {
                            buildJSON['features'][getRootKey]['user_choice'] = data[moduleKey]
                        } else {
                            if (getSubKey) {
                                // console.log('getSubKey', getSubKey, getRootKey, getRawKey, buildJSON['features'][getRootKey][getRawKey], typeof buildJSON['features'][getRootKey][getRawKey])
                                if (typeof buildJSON['features'][getRootKey][getRawKey] === 'object') {
                                    buildJSON['features'][getRootKey][getRawKey] = { ...buildJSON['features'][getRootKey][getRawKey] }
                                } else {
                                    buildJSON['features'][getRootKey][getRawKey] = { 'user_choice': buildJSON['features'][getRootKey][getRawKey] }
                                }
                                buildJSON['features'][getRootKey][getRawKey][getSubKey] = data[moduleKey]
                            }
                            else {
                                if (!buildJSON['features'][getRootKey][getRawKey]) {
                                    buildJSON['features'][getRootKey][getRawKey] = data[moduleKey]
                                }
                            }
                        }

                    }
                }
            }
            const developmentInfo = {
                ...productInfo['developmentInfo'],
                moduleType: productChoice,
                moduleName: selectModel,
                moduleDetail: buildJSON
            }
            const updateData = await be.modeleditProductId(defaultOrg, productInfo['modelId'], {
                developmentInfo: {
                    ...developmentInfo,
                }
            });
            if (updateData) {
                toast.success('Update Successfully')
                incrementCounter()
                setIsAddBefore(true)
            }
        }
    }
    const renderSmartModeContent = () => {
        // const isDefaultSelect = productInfo['developmentInfo'] && productInfo['developmentInfo']['moduleType'] === productChoice
        switch (smartmode) {
            case 'ROGO_OS':
                {
                    if (isAddBefore) {
                        return productInfo['developmentInfo']['moduleType'] === 'MODULE' ? <div className="col-3">
                            <pre style={{ backgroundColor: 'whitesmoke' }} className="p-2">
                                <div className="d-flex justify-content-center align-items-center">
                                    <h3 className="font-weight-bold">{productInfo['developmentInfo']['moduleName']}</h3>
                                </div>
                                <code className="font-weight-bold ">
                                    {
                                        yaml.dump(productInfo['developmentInfo']['moduleDetail'])
                                    }
                                </code>
                            </pre>
                            <Button onClick={() => { chooseModel('', ''), setIsAddBefore(false) }} type="primary" htmlType="submit" className="mt-3">
                                Re-Select
                            </Button>
                        </div> : <div className="">
                            {productInfo['developmentInfo']['moduleDetail'] && <Card
                                hoverable={true}
                                className={`card-selected`}
                                style={{
                                    marginTop: 10,
                                    border: '1px solid #005cff'
                                }}
                            >
                                <div className="d-flex">
                                    <div className="mr-2 align-items-center d-flex">
                                        <img
                                            width={70}
                                            alt="img"
                                            src={
                                                productInfo['developmentInfo']['moduleDetail']['image'] ? productInfo['developmentInfo']['moduleDetail']['image'] : getImg(Object.keys(IoTFullDeviceType)[
                                                    Object.values(IoTFullDeviceType).findIndex(
                                                        (z) => z === productInfo['developmentInfo']['moduleDetail']["categoryInfo"][0],
                                                    )
                                                ])}
                                        />
                                    </div>
                                    <Meta title={<>
                                        <div>{productInfo['developmentInfo']['moduleDetail']['name']}</div>
                                        <small>{productInfo['developmentInfo']['moduleDetail']['modelId']}</small>
                                    </>} description={productInfo['developmentInfo']['moduleDetail']['description']} />
                                </div>
                            </Card>
                            }
                            <Button onClick={() => { chooseModel('', ''), setIsAddBefore(false) }} type="primary" htmlType="submit" className="mt-3">
                                Re-Select
                            </Button>
                        </div>
                    } else {
                        return <>

                            <div className="col-9 pl-4">
                                <small>Hardware custom fit your product info</small>
                                {
                                    hardwareModule && hardwareModule.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                                <div className="row">
                                    {
                                        hardwareModule && hardwareModule.map((x, i) => {
                                            return (
                                                <div key={i} className="col-3">
                                                    <Card
                                                        onClick={() => { chooseModel(x['name'], 'MODULE'); form.resetFields(); }}
                                                        key={i.toString()}
                                                        hoverable={true}
                                                        className={selectModel === x['name'] && `card-selected`}
                                                        style={{
                                                            marginTop: 10,
                                                            border: selectModel === x['name'] && '1px solid #005cff'
                                                        }}
                                                    >
                                                        <div className="mr-2 align-items-center d-flex">
                                                            <img
                                                                width={70}
                                                                alt="img"
                                                                src={x['image'] ? x['image'] : `https://imaged.tuyaus.com/smart/productModule/undefined/BT7L.jpg_f45d73ff-2b95-43e6-ae34-37ff6e1b2f5c?process=image/resize,w_48,h_48`}
                                                            />
                                                        </div>
                                                        <Meta title={<>
                                                            <div>{x['name']}</div>
                                                        </>} description={generateHardwareInfo(x)}
                                                        />
                                                    </Card>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="row mt-4">
                                    <div className="col-2">
                                        <h3>
                                            {hardwareModule && hardwareModule.filter(x => x['name'] === selectModel).length > 0 && selectModel}
                                        </h3>
                                        <Divider type='vertical' />
                                    </div>
                                    <div className="col-3">
                                        {
                                            hardwareModule && hardwareModule.filter(x => x['name'] === selectModel).length > 0 && <YamlViewer

                                                yamlData={hardwareModule.filter(x => x['name'] === selectModel)[0]}
                                                productInfo={productInfo}
                                                form={form}
                                                productChoice={productChoice}
                                                handleSubmit={handleSubmit}
                                                protocolData={
                                                    // TL;DR : get `protocol` info in yaml file & its property based on `protocol` in db
                                                    hardwareModule.filter(x => x['name'] === selectModel)[0]['protocol'][
                                                    _.invert(PROTOCOL)[productInfo['baseInfo'][1].toString()].toLowerCase()
                                                    ]} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                }
            case 'ROGO_LINK':
                return <>Under Develop</>
        }
    }
    return (
        <div>
            {
                productInfo && generateSummaryProductInfo()
            }
            {loading && (
                <div className="spinner spinner-success mr-3"></div>
            )}
            {!loading && <Steps
                progressDot
                current={current}
                direction="vertical"
                items={[
                    {
                        title: 'Please choose development mode',
                        description:
                            <div className="p-3 m-2 d-flex justify-content-start align-items center" >
                                {
                                    SMART_MODE.map((x, i) => {
                                        const defaultSmartMode = smartmode === x['key']
                                        return (
                                            <Card
                                                onClick={() => {
                                                    !defaultSmartMode && confirmEdit({
                                                        smartMode: x['key']
                                                    });
                                                }}
                                                className={defaultSmartMode && `card-selected`}
                                                key={i.toString()}
                                                hoverable={!defaultSmartMode}
                                                style={{ width: 300, marginRight: 50, border: defaultSmartMode && '1px solid #005cff' }}
                                            >
                                                <Meta title={x.title} description={x.description} />
                                            </Card>
                                        )
                                    })
                                }
                            </div>
                    },
                    {
                        title: 'There are available model based on your product information',
                        description:
                            <div className="p-3 m-2 d-flex justify-content-start align-items center" >

                                {renderSmartModeContent()}
                            </div>,
                    },
                ]}
            />}
        </div>
    )
}