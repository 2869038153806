import { useAbility } from "@casl/react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Action, ActionToName } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import { BackendContext } from "src/services/backend";
import { AbilityContext, Can } from "src/services/caslContext";
import { FirebaseContext } from "src/services/firebaseContext";
import { IBAC, RoleDecompile } from "src/services/roleCompiler";

export const ModalHandleAuthorize = ({
  show,
  openModal,
  project,
  getAccessUserPartner,
  updateScreen,
  setUpdateScreen,
}) => {
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [pass, setPass] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const be = useContext(BackendContext);
  const { defaultOrg } = useContext(FirebaseContext);
  const { projectId } = useParams();
  const [checkBoxState, setCheckBoxState] = useState(
    new Array(Object.values(Action).length).fill(false),
  );
  const ability = useAbility(AbilityContext);
  useEffect(() => {
    if (updateScreen) {
      getUserAbac(updateScreen).then((x) => {
        const abacInfo = RoleDecompile.decompile(x["abac"]);
        const abacInfoInPartnerIdAndProjectId = abacInfo.filter(
          (x) => x.partnerId === defaultOrg && x.projectId === projectId,
        );
        const role = abacInfoInPartnerIdAndProjectId[0]["role"];
        const checkBoxStateInit = Object.values(Action).map((x, i) =>
          role.includes(x),
        );
        setCheckBoxState(checkBoxStateInit);
      });
    }
  }, []);
  const nextModal = async () => {
    const actions = Object.values(Action);
    const actionResource = [];
    actions.map((x, i) => {
      if (checkBoxState[i]) {
        Object.values(Resources).forEach((resource, index) => {
          if (actions[i].startsWith(resource)) {
            actionResource.push(`${resource}:${actions[i]}`);
          }
        });
      }
    });
    const abacBuilder = [
      {
        resources: [`partner:${defaultOrg}:project/${projectId}`],
        actions: actionResource,
      },
    ];
    if (!updateScreen) {
      if (
        name &&
        email &&
        pass &&
        pass.length >= 8 &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
      ) {
        setLoading(true);
        const data = {
          name,
          email,
          pass,
        };
        const users = await be.userpartnerAddToSystem(
          data,
          defaultOrg,
          projectId,
        );
        if (users) {
          const user = users[0];
          const attachRole = await be.abacAttachRoleToUser(
            user["ownerId"],
            defaultOrg,
            projectId,
            abacBuilder,
            true,
          );
          getAccessUserPartner();
          openModal();
        }
        setLoading(false);
      }
    } else {
      setLoading(true);
      const attachRole = await be.abacAttachRoleToUser(
        updateScreen,
        defaultOrg,
        projectId,
        abacBuilder,
        false,
      );
      await getAccessUserPartner();
      openModal();
      setLoading(false);
    }
  };
  const onProjectCheckBoxChange = (position) => {
    const findUserReadPosition = Object.values(Action).findIndex(
      (x) => x === Action.UserRead,
    );

    const updatedCheckedState = checkBoxState.map((item, index) => {
      return index === position ? !item : item;
    });
    if (
      Object.values(Action)[position] === Action.UserCreate ||
      Object.values(Action)[position] === Action.UserDelete ||
      Object.values(Action)[position] === Action.UserUpdate
    ) {
      updatedCheckedState[findUserReadPosition] = true;
    }
    if (
      Object.values(Action)[position] === Action.UserRead &&
      !updatedCheckedState[findUserReadPosition]
    ) {
      updatedCheckedState.map((item, index) => {
        if (Object.values(Action)[index].startsWith(Resources.User)) {
          updatedCheckedState[index] = false;
        }
      });
    }
    setCheckBoxState(updatedCheckedState);
  };
  const getUserAbac = async (uuid: string) => {
    const getUserPermission = await be.abacGetUserABAC(defaultOrg, uuid);
    return getUserPermission;
  };
  return (
    <div
      className=""
      id="modalAdd"
      role="dialog"
      aria-labelledby="modalAdd"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="verifyModalContent_title">
              {!updateScreen ? "Create Sub Account" : `Edit User Partner`}
            </h5>
            <button
              type="button"
              className="close"
              onClick={openModal}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="d-flex">
              {!updateScreen && (
                <div>
                  <div className="form-group w-100 container">
                    <label
                      htmlFor="recipient-name-2"
                      className="col-form-label"
                    >
                      <span className="text-danger">*</span> Name:
                    </label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      className="form-control"
                    />
                    {!name && (
                      <label htmlFor="recipient-name-2" className="text-danger">
                        Enter Name
                      </label>
                    )}
                  </div>
                  <div className="form-group w-100 container">
                    <label
                      htmlFor="recipient-name-2"
                      className="col-form-label"
                    >
                      <span className="text-danger">*</span> Email:
                    </label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="form-control"
                    />
                    {!email ? (
                      <label htmlFor="recipient-name-2" className="text-danger">
                        Enter Email Address
                      </label>
                    ) : (
                      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                        email,
                      ) && (
                        <label
                          htmlFor="recipient-name-2"
                          className="text-danger"
                        >
                          Not Valid Email Format
                        </label>
                      )
                    )}
                  </div>
                  <div className="form-group w-100 container">
                    <label
                      htmlFor="recipient-name-2"
                      className="col-form-label"
                    >
                      <span className="text-danger">*</span> Password:
                    </label>
                    <input
                      onChange={(e) => setPass(e.target.value)}
                      type="password"
                      placeholder="******"
                      className="form-control"
                    />
                    {!pass ? (
                      <label htmlFor="recipient-name-2" className="text-danger">
                        Enter Password
                      </label>
                    ) : (
                      pass.length < 8 && (
                        <label
                          htmlFor="recipient-name-2"
                          className="text-danger"
                        >
                          The format of the specified password is incorrect
                        </label>
                      )
                    )}
                    <label>
                      The password must at least 8 characters in length
                      {/* and contain the following types: numbers, uppercase letters, lowercase letters and special characters. */}
                    </label>
                  </div>
                </div>
              )}
              <div>
                {Object.values(Resources).map((resource) => {
                  return (
                    <>
                      <div className="form-group w-100 container">
                        <label
                          htmlFor="recipient-name-2"
                          className="col-form-label"
                        >
                          <span className="text-success">(Optional)</span> Role
                          to {resource}:
                        </label>
                        <div className="d-flex align-self-center row ml-1">
                          {ability.can(
                            Action.ProjectEditRole,
                            Resources.Project,
                          ) ? (
                            <>
                              {Object.values(Action).map((x, i) => {
                                return (
                                  x.startsWith(resource) &&
                                  x !== Action.ProjectCreate && (
                                    <>
                                      <Can I={x} a={resource} key={i.toString()}>
                                        <label
                                        className="checkbox checkbox-primary mr-2 mb-2"
                                        key={i.toString()}
                                      >
                                        <input
                                          checked={checkBoxState[i]}
                                          name={resource}
                                          type="checkbox"
                                          onChange={() => {
                                            onProjectCheckBoxChange(i);
                                          }}
                                        />
                                        <span className="font-weight-bold">
                                          {ActionToName[x]}
                                        </span>
                                        <span className="checkmark"></span>
                                      </label>
                                      </Can>
                                    </>
                                  )
                                );
                              })}
                            </>
                          ) : (
                            Object.values(Action).map((x, i) => {
                              return (
                                x.startsWith(resource) &&
                                x !== Action.ProjectCreate && (
                                  <>
                                    <Can I={x} a={resource} key={i.toString()}>
                                      <label className="checkbox checkbox-primary mr-2 mb-2">
                                        <input
                                          checked={checkBoxState[i]}
                                          name={resource}
                                          type="checkbox"
                                          onChange={() => {
                                            onProjectCheckBoxChange(i);
                                          }}
                                        />
                                        <span className="font-weight-bold">
                                          {ActionToName[x]}
                                        </span>
                                        <span className="checkmark"></span>
                                      </label>
                                    </Can>
                                  </>
                                )
                              );
                            })
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setUpdateScreen(false);
                openModal();
              }}
              className="btn btn-secondary"
              data-dismiss={`${show ? "modal" : ""}`}
            >
              Close
            </button>
            {!loading && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  nextModal();
                }}
                type="button"
                className="btn btn-primary"
              >
                OK
              </button>
            )}
            {loading && <div className="spinner spinner-success mr-3"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
