import React, { useState } from "react";
import { Outlet } from "react-router-dom";

export const Pagination = ({
  data,
  handleChangePage,
  setData,
  searchParams,
  size,
  setSize,
  children = <></>
}) => {
  return (
    <>
      <nav
        aria-label="Page navigation example"
        className="d-flex justify-content-between align-items-center"
      >
        <div className="d-flex flex-column  ">
          <div className="justify-content-start d-flex align-items-center mb-3">
            <span className="mr-2">
              <b>Page Size</b>:
            </span>
            <div
              className="form-group mb-0 mr-3 d-flex "
              style={{ minWidth: 100 }}
            >
              <select
                onChange={(e) => {
                  setSize(parseInt(e.target.value));
                }}
                className="form-control"
              >
                {[50, 100, 200, 500].map((x) => {
                  return (
                    <>
                      <option selected={x === size} key={x} value={x}>
                        {x}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        {children && children}
        {data && data["totalPage"] <= 3 && (
          <>
            <ul className="pagination">
              {Array(data["totalPage"])
                .fill(0)
                .map((x, i) => {
                  return (
                    <li
                      key={i.toString()}
                      onClick={() => handleChangePage(i + 1)}
                      className={`page-item ${
                        data["page"] === i + 1 && "active"
                      }`}
                    >
                      <a className="page-link">{i + 1}</a>
                    </li>
                  );
                })}
            </ul>
          </>
        )}
        {data && data["totalPage"] > 3 && (
          <ul className="pagination">
            {data && data["page"] !== 1 && (
              <li
                className="page-item"
                onClick={() =>
                  handleChangePage(parseInt(searchParams.get("page")) - 1)
                }
              >
                <a className="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
            )}
            {data && data["page"] !== 1 && (
              <li onClick={() => handleChangePage(1)} className="page-item">
                <a className="page-link">1</a>
              </li>
            )}
            {data && data["page"] !== 2 && data["page"] !== 1 && (
              <li className="d-flex align-items-center">......</li>
            )}
            {data && data["page"] >= data["totalPage"] - 2 && (
              <>
                <li
                  onClick={() => handleChangePage(data["totalPage"] - 2)}
                  className={`page-item ${
                    data["page"] === data["totalPage"] - 2 && "active"
                  }`}
                >
                  <a className="page-link">{data["totalPage"] - 2}</a>
                </li>
                <li
                  onClick={() => handleChangePage(data["totalPage"] - 1)}
                  className={`page-item ${
                    data["page"] === data["totalPage"] - 1 && "active"
                  }`}
                >
                  <a className="page-link">{data["totalPage"] - 1}</a>
                </li>
                <li
                  onClick={() => handleChangePage(data["totalPage"])}
                  className={`page-item ${
                    data["page"] === data["totalPage"] && "active"
                  }`}
                >
                  <a className="page-link">{data["totalPage"]}</a>
                </li>
              </>
            )}
            {data &&
              data["totalPage"] &&
              data["page"] < data["totalPage"] - 2 && (
                <>
                  <li
                    onClick={() => handleChangePage(data["page"])}
                    className="page-item active"
                  >
                    <a className="page-link">{data["page"]}</a>
                  </li>
                  <li
                    onClick={() => handleChangePage(data["page"] + 1)}
                    className="page-item"
                  >
                    <a className="page-link">{data["page"] + 1}</a>
                  </li>
                  <li
                    onClick={() => handleChangePage(data["page"] + 2)}
                    className="page-item"
                  >
                    <a className="page-link">{data["page"] + 2}</a>
                  </li>
                  {data["page"] !== data["data"].length - 4 && (
                    <>
                      <li className="d-flex align-items-center">......</li>{" "}
                      <li
                        onClick={() => handleChangePage(data["totalPage"])}
                        className="page-item"
                      >
                        <a className="page-link" href="#">
                          {data["totalPage"]}
                        </a>
                      </li>
                    </>
                  )}
                </>
              )}
            {data && data["page"] < data["totalPage"] - 2 && (
              <li
                className="page-item"
                onClick={() =>
                  handleChangePage(parseInt(searchParams.get("page")) + 1)
                }
              >
                <a className="page-link" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            )}
          </ul>
        )}
      </nav>
    </>
  );
};
