
export class IoTAttribute {
    //BLOCK FOR GENERIC FEATURES
    public static ONOFF = 1;
    public static OPEN_CLOSE_CTL = 2;
    public static LOCK_UNLOCK = 3;

    public static BATTERY = 9;
    public static POSITION = 11;

    public static MODE = 17;
    public static FAN_SWING = 18;
    public static FAN_SPEED = 19;
    public static TEMP_SET = 20;
    public static CAMERA_STREAMING = 22;
    public static CALLING = 23;
    public static ALARM = 24;

    //BLOCK FOR LIGHT FEATURES
    public static BRIGHTNESS = 28;
    public static KELVIN = 29;
    public static BRIGHTNESS_KELVIN = 30;
    public static COLOR_HSV = 31;
    public static COLOR_HSL = 32;
    public static COLOR_HSV_CHANNEL = 33;
    public static LEVEL = 36;

    //BLOCK FOR EVENT FEATURES
    public static HUMID_EVT = 48;
    public static TEMP_EVT = 49;
    public static TEMP_HUMID_EVT = 50;
    public static OPEN_CLOSE_EVT = 51;
    public static MOTION_LUX_EVT = 52;
    public static MOTION_EVT = 53;
    public static LUX_EVT = 54;
    public static SMOKE_EVT = 55;
    public static WALL_MOUNTED_EVT = 56;

    public static MOTION_SENSITIVE_LV = 57;
    public static PRESENSCE_EVT = 58;

    public static BUTTON_PRESS_EVT = 60;
    public static KNOB_EVT = 61;
    public static LEVEL_EVT = 62;

    public static TOUCH_SETTING = 192;
    public static MOTOR_CALIB_TIME_INFO = 226;
    public static MOTOR_TYPE_INFO = 227;
    public static VENDOR = 225;
    public static ENABLE_DISABLE_ATTR = 230;

    public static REVERSE_ONOFF = 310;
    public static SYNC_ONOFF_NORMAL = 311;
    public static SYNC_ONOFF_REVERSING = 312;
    public static SYNC_ONOFF_STAIR_SWITCH = 313;
    public static SYNC_ONOFF_NO_TRIGGER = 314;


    public static BRIGHTNESS_UP = 320;
    public static BRIGHTNESS_DOWN = 321;
    public static KELVIN_WARM_UP = 322;
    public static KELVIN_WARM_DOWN = 323;
    public static COLOR_HSV_CHANNEL_UP = 324;
    public static COLOR_HSV_CHANNEL_DOWN = 325;
    public static TEMP_UP = 326;
    public static TEMP_DOWN = 327;
    public static UP_OTHER_ATTR = 328; //value should be attr - step
    public static DOWN_OTHER_ATTR = 329; //value should be attr - step

    public static PUSH_NOTIFICATION = 330;
    public static TRIGGER_ACTION_CFG = 331;
    public static TRIGGER_ZONE_CFG = 332;


    public static SIMULATOR_REMOTE_ANDROID_TV = 332;

    //BLOCK FOR OTHER VALUE & FEATURE
    public static IR = 81;
    public static IR_KEY = 82;
    public static IR_RAW = 83;
    public static AC = 257;


    public static SMART_TEMPERATURE = 1024; //[1024(SMART_TEMPERATURE),gapTemp, hasTemp, requireMotion, requireDoorClose, 0,0,0,0,0 numberZone, .... targetTemp]

    public static SINGLE_PRESS_EVT_DEPRECATED = 90;//Deprecated, end when old automation remove
    public static LONG_PRESS_EVT_DEPRECATED = 91;//Deprecated, end when old automation remove
    public static DOUBLE_PRESS_EVT_DEPRECATED = 92;//Deprecated, end when old automation remove
    public static ONOFF_SWITCH_ROGO_V1_DEPRECATED = 800; // Will be deprecated, end when old automation remove
    public static ONOFF_SWITCH_ROGO_V2_DEPRECATED = 801; // Will be deprecated, end when old automation remove
    public static CMD_FORCE_RESET_MESH_DEVICE_DEPRECATED = 462; // Will be deprecated, end when old automation remove


    public static isEventAttrs(attr) {
        switch (attr) {
            case 'ONOFF':
            case 'BUTTON_PRESS_EVT':
            case 'BATTERY':
            case 'MOTION_EVT':
            case 'MOTION_LUX_EVT':
            case 'LUX_EVT':
            case 'OPEN_CLOSE_EVT':
            case 'KNOB_EVT':
            case 'TEMP_EVT':
            case 'TEMP_HUMID_EVT':
            case 'HUMID_EVT':
            case 'LEVEL':
            case 'WALL_MOUNTED_EVT':
            case 'LOCK_UNLOCK':
                return true;
        }
        return false;
    }
}