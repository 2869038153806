import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Head } from "./head";
import { SideBar } from "./sidebar";
import { Footer } from "./footer";

import {
  BrowserRouter as Router,
  Link,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { SideBarNoAction } from "./sidebarNoAction";
import { auth } from "src/services/config";
import { BackendAPI, BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import { AbilityContext } from "src/services/caslContext";
import { Action } from "src/constant/abac";
import {
  FULLPERMISSION_STRING,
  ROGO_RESOURCE_NAME_TO_OBJECT,
  Resources,
  Subjects,
} from "src/constant/resources";
import {
  MongoAbility,
  AbilityBuilder,
  createMongoAbility,
} from "@casl/ability";
type AppAbilities = MongoAbility<[Action, Subjects]>;
export const Layout = ({ mainSideBar = true }) => {
  const navigate = useNavigate();
  const { can, cannot, build } = new AbilityBuilder<AppAbilities>(
    createMongoAbility,
  );
  const { abacs, defaultOrg } = React.useContext(FirebaseContext);
  const { projectId } = useParams();
  React.useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (!!user) {
        user.getIdTokenResult().then((a) => { });
      } else {
        navigate("/auth");
      }
    });
    return () => unregisterAuthObserver();
  }, []);
  const defineAbility = () => {
    if (!abacs || (abacs && abacs.length === 0)) {
    } else {
      for (const abac of abacs) {
        const resources = abac.resources;
        const projectIds = [];
        const partnerIds = [];
        for (const resource of resources) {
          projectIds.push(resource.split("/")[1]);
          partnerIds.push(resource.split("/")[0].split(":")[1]);
        }
        if (partnerIds.includes(defaultOrg)) {
          const actions = abac.actions;
          for (const action of actions) {
            const actionSplit = action.split(":");
            const rName = actionSplit[0];
            const rAction = actionSplit[1];
            const actionAbility = [];
            if (rAction === FULLPERMISSION_STRING) {
              Object.values(Action).map((keyResource) => {
                if (keyResource !== FULLPERMISSION_STRING) {
                  if (keyResource.startsWith(rName)) {
                    actionAbility.push(keyResource);
                  }
                }
              });
            } else {
              actionAbility.push(rAction);
            }
            // for device activation logic
            if (actionAbility.includes(Action.ReportView)) {
              can(
                Action.ReportView,
                ROGO_RESOURCE_NAME_TO_OBJECT[Resources.Report],
              );
            }

            // for product development logic
            if (actionAbility.includes(Action.ProductViewAllPartnerModelId)) {
              can(
                Action.ProductViewAllPartnerModelId,
                ROGO_RESOURCE_NAME_TO_OBJECT[Resources.Product],
              );
            }
            // for hardware management logic
            if (actionAbility.includes(Action.HardwareViewAll)) {
              can(
                Action.HardwareViewAll,
                ROGO_RESOURCE_NAME_TO_OBJECT[Resources.Hardware],
              );
            }

            if (actionAbility.includes(Action.ProductEditPartnerModel)) {
              can(
                Action.ProductEditPartnerModel,
                ROGO_RESOURCE_NAME_TO_OBJECT[Resources.Product],
              );
            }
            if (projectIds.includes(FULLPERMISSION_STRING)) {
              can(actionAbility, "all");
            } else if (projectIds.includes(projectId)) {
              can(actionAbility, ROGO_RESOURCE_NAME_TO_OBJECT[rName]);
            }
          }
        }
      }
    }

    return build({
      detectSubjectType: (item) => item,
    });
  };
  return (
    <AbilityContext.Provider value={defineAbility()}>
      <div className="App">
        <div className="app-admin-wrap layout-sidebar-large clearfix">
          <Head mainSideBar={mainSideBar} />
          {/* {
                    mainSideBar ? <SideBar /> : <SideBarNoAction />
                } */}
          <SideBarNoAction />
          <div
            className="main-content-wrap sidenav-open d-flex flex-column"
            style={{ width: "calc(100% - 160px)" }}
          >
            <Outlet />
            <div className="flex-grow-1"></div>
            <Footer logo={true} />
          </div>
          {/* <Customizer /> */}
        </div>
      </div>
    </AbilityContext.Provider>
  );
};
