import { addDays, format, isWeekend } from "date-fns";
import React from "react";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment-timezone";
export function convertTimezone(dateString): Date {
  const sourceDateTime = new Date(dateString);
  const targetDateTime = new Date(
    sourceDateTime.getTime() - sourceDateTime.getTimezoneOffset() * 60 * 1000,
  );
  return targetDateTime;
}

export const DatePicker2 = ({
  setDateRange,
  openDate,
  setOpenDate,
  getReports,
  startDate,
  endDate,
}) => {
  const [state, setState] = useState({
    selection: {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  });
  const handleGetReport = () => {
    setDateRange([
      state["selection"]["startDate"],
      state["selection"]["endDate"],
    ]);
    getReports(
      convertTimezone(state["selection"]["startDate"]),
      convertTimezone(state["selection"]["endDate"]),
    );
    setOpenDate(false);
  };
  return (
    <>
      {openDate && (
        <>
          <DateRangePicker
            className="daterangepicker "
            onChange={(item) => {
              // const { startDate, endDate } = item.selection;
              item.selection.endDate.setHours(23);
              item.selection.endDate.setMinutes(59);
              item.selection.endDate.setSeconds(59);
              item.selection.endDate.setMilliseconds(999);
              setState({ ...state, ...item });
            }}
            showDateDisplay={true}
            months={1}
            minDate={addDays(new Date(), -700)}
            maxDate={addDays(new Date(), 0)}
            direction="vertical"
            scroll={{ enabled: true }}
            ranges={[state.selection]}
          />
          <div className="p-2 pl-4 shadow footer-date d-flex justify-content-end">
            <div
              className="px-3 font-weight-bold apply-cancel"
              onClick={() => setOpenDate(false)}
            >
              Cancel
            </div>
            <div
              className="ml-2 px-3 font-weight-bold apply-cancel"
              onClick={() => handleGetReport()}
            >
              Apply
            </div>
          </div>
        </>
      )}
    </>
  );
};
