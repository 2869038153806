import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import {
    MenuProps,
    Menu, 
    Empty,
    Button,
    Typography,
    Upload,
    Space,
} from "antd";
import { ArrowRightOutlined, DeleteOutlined, FileProtectOutlined, SettingOutlined, UndoOutlined, UploadOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { Buffer } from 'buffer'
import * as _ from "lodash";
import yaml from 'js-yaml';
import { SHORT_PROTOCOL } from "src/constant/productModelNewVer";
const { Paragraph } = Typography;

export const HardwareModule = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const be = useContext(BackendContext);
    const { defaultOrg } = useContext(FirebaseContext);
    const { productId } = useParams();
    const [listHardwareModule, setHardwareModule] = useState<MenuProps["items"]>();
    const [hardwareYamlData, setHardwareYamlData] = useState<string>()
    const [activeViewModule, setActiveViewModule] = useState<string>()
    const [fileList, setFileList] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(null); // State to store the uploaded file
    const [stateBtnContinue, setStateBtnContinue] = useState<boolean>(false)
    const handleUploadChange = (info) => {
        let fileList = [...info.fileList];

        // Remove all files except the latest one
        fileList = fileList.slice(-1);

        // Read the uploaded file content and update the fileList with the content
        const reader = new FileReader();
        reader.onload = (event) => {
            const content = event.target.result;
            try {
                setActiveViewModule(`Preview new hardware module `)
                setHardwareYamlData(JSON.stringify(yaml.load(content)))
                setUploadedFile(info.file)
            } catch (error) {
                toast.error('Error parsing YAML file.');
            }
        };
        reader.readAsText(info.file);
        setFileList(fileList);
        setStateBtnContinue(true)
    };
    const handleRemove = () => {
        setFileList([]);
        setUploadedFile(null); // Clear the uploaded file from state if removed 
        setActiveViewModule(``)
        setHardwareYamlData(``)
    };

    const validateYamlFile = (yamlToValidate) => {
        const fieldRequired = ['metadata', 'feature_config', 'protocol', 'feature_supported']
        const metadataFieledRequired = ['owner', 'package_type', 'chipset', 'flash', 'ram', 'antenna', 'supported_power', 'supported_protocol']
        if (Array.isArray(yamlToValidate)) {
            return {
                success: false,
                msg: 'Format Failed, Yaml has to be Object instead of Array',
                filedError: '*'
            }
        }
        // missing field
        for (const field of fieldRequired) {
            if (!yamlToValidate[field]) {
                return {
                    success: false,
                    msg: `Missing "${field}"`,
                    filedError: field
                }
            }
        }
        for (const dataExtract in yamlToValidate) {
            // exist field & isValidate or not
            if (!fieldRequired.includes(dataExtract)) {
                return {
                    success: false,
                    msg: `Field "${dataExtract}" not supported`,
                    filedError: dataExtract
                }
            }
            if (Array.isArray(yamlToValidate[dataExtract])) {
                return {
                    success: false,
                    msg: `Format Failed, "${dataExtract}" first element has to be Object instead of Array`,
                    filedError: dataExtract
                }
            }
        }
        for (const metadata in yamlToValidate['metadata']) {
            if (!metadataFieledRequired.includes(metadata)) {
                return {
                    success: false,
                    msg: `Field "${metadata}" not found or not supported`,
                    filedError: 'metadata'
                }
            }
        }

        for (const field of metadataFieledRequired) {
            if (!yamlToValidate['metadata'][field]) {
                return {
                    success: false,
                    msg: `Missing "${field}" in "metadata"`,
                    filedError: field
                }
            }
        }
        return {
            success: true,
        }
    }
    useEffect(() => {
        modelGetHardwareObjectInS3();
    }, []);

    const modelGetHardwareObjectInS3 = async () => {
        const listInDb: any = await be.hardwareGetHardwareObjectInS3(defaultOrg);
        setHardwareModule(transformObjectToMenuItems(listInDb));
    };
    const onClickHandleGetHardware = async (data) => {
        const getHardwareDetail = await be.hardwareGetSpecificHardwareInS3(defaultOrg, data)
        setActiveViewModule(data)
        setHardwareYamlData(JSON.stringify(yaml.load(Buffer.from(getHardwareDetail).toString())))
        setFileList([]);
        setUploadedFile(null);
        setStateBtnContinue(false)
    }
    function transformObjectToMenuItems(obj) {
        // TL;DR: this code for transforming server response s3 bucket information into menu of antd format
        // ref for antd format: https://ant.design/components/menu
        const tempObj = obj;
        const menuItems = [];
        for (const key in _.omit(obj, ["key"])) {
            if (obj.hasOwnProperty(key)) {
                const item = obj[key];
                const children =
                    typeof item === "object" && item !== null
                        ? transformObjectToMenuItems(item)
                        : [];

                const menuItem = {
                    key: key + '@' + Math.random(), // @ for split between key and random
                    icon: <SettingOutlined rev={undefined} />,
                    label: key,
                };
                if (!key.includes(".yaml")) {
                    menuItem["children"] = children;
                }
                menuItems.push(menuItem);
                const emptyFolder = Object.keys(_.omit(item, ["key"])).length === 0;
                if (key.includes(".yaml") || (emptyFolder && key !== "latest")) {
                    menuItem["icon"] = <FileProtectOutlined rev={undefined} />;
                    menuItem["onClick"] = () => onClickHandleGetHardware(tempObj[key]["key"] + key)
                }
            }
        }

        return menuItems;
    }
    const generateYaml = () => {

        const jsx = []
        const parseYmlData = JSON.parse(hardwareYamlData)
        const validateYaml = validateYamlFile(parseYmlData)
        let color
        if (!validateYaml['success']) {
            if (validateYaml['filedError'] === '*') {
                color = 'red'
            }
        }
        validateYaml['success'] && jsx.push(
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <div style={{ textAlign: 'start' }} > <h5>Module name :<b>{hardwareYamlData && generateHardwareModuleName()}</b></h5> </div>
                    <div style={{ textAlign: 'end' }} >All good <i style={{ fontSize: 25, color: 'green' }} className="icofont-verification-check"></i></div>
                </div>
            </>
        )
        validateYaml['success'] && jsx.push(

        )
        !validateYaml['success'] && jsx.push(
            <div style={{ textAlign: 'end' }} >Not good <i style={{ fontSize: 25, color: 'red' }} className="icofont-close"></i></div>
        )
        for (const ymlData in parseYmlData) {
            jsx.push(
                <div className=" ">
                    <Paragraph style={{}}>
                        <div className="d-flex justify-content-between align-items-center" style={{ color: color ? color : validateYaml['filedError'] === ymlData ? 'red' : 'black' }}>
                            <b>{ymlData}</b>
                            {validateYaml['filedError'] === ymlData ?
                                <small style={{ color: 'red' }}>{validateYaml['msg']}</small> :
                                !validateYaml['success'] ?
                                    <small style={{ color: 'red' }}>{validateYaml['msg']}</small> :
                                    <></>
                            }
                        </div>
                        <pre style={{ border: 'none', marginTop: 0 }}>{yaml.dump(parseYmlData[ymlData])}</pre>
                    </Paragraph>
                </div>
            )
        }
        return jsx.flat()
    }
    const generateHardwareModuleName = () => {
        const { metadata } = JSON.parse(hardwareYamlData)
        const { supported_protocol, antenna, package_type } = metadata
        const mappingPackageType = {
            'esp-12': '01'
        }
        const mappingAntenna = {
            'pcb': 'I'
        }
        let nameHardware = ''
        for (const protocol in supported_protocol) {
            nameHardware += SHORT_PROTOCOL[protocol.toLocaleUpperCase()]
            if (!SHORT_PROTOCOL[protocol.toLocaleUpperCase()]) {
                toast.error('not supported protocol ' + protocol)
                return false;
            }
        }
        if (!mappingPackageType[package_type]) {
            toast.error('not supported package_type ' + package_type)
            return false;
        }

        if (!mappingAntenna[antenna]) {
            toast.error('not supported antenna ' + antenna)
            return false;
        }
        nameHardware += '-' + mappingPackageType[package_type] + '-'
        nameHardware += mappingAntenna[antenna]
        return nameHardware
    }
    const handleContinue = async () => {
        const nameHardware = generateHardwareModuleName()
        if (nameHardware) {
            const formData = new FormData();
            formData.append("file", uploadedFile, uploadedFile.name);
            formData.append(
                "path",
                nameHardware,
            );
            formData.append(
                "data",
                hardwareYamlData,
            );

            const createOtafolder = await be.hardwareUploadModuleYaml(defaultOrg, formData);
            if (createOtafolder) {
                toast.success("Succesfully upload");
                setLoading(true);
                await modelGetHardwareObjectInS3();
                setLoading(false);
                setFileList([]);
                setUploadedFile(null);
                setActiveViewModule(`hardware_module/${defaultOrg}/${nameHardware}/${nameHardware}.yaml`)
                setStateBtnContinue(false)
            }
        }
    }
    return (
        <>
            <h3>
                Product <b>{productId}</b>{" "}
            </h3>
            <div
                className="breadcrumb"
                style={{ borderBottom: "3px solid rgb(232, 237, 235)" }}
            >
                <ul className=" pb-2">
                    <li className="pl-0">
                        <a href="/org/project">Dashboard</a>
                    </li>
                    <li>Version 1</li>
                </ul>
            </div>
            <div className="row">
                <div className="col-3 mb-3">
                    <div className="card mb-4">
                        <div className="card-body">

                            {listHardwareModule && listHardwareModule.length === 0 && (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                            {!loading && (
                                <Menu
                                    // onClick={onClick}
                                    defaultOpenKeys={
                                        listHardwareModule &&
                                        listHardwareModule[0] &&
                                        listHardwareModule[0]["children"].map((x) => x["key"])
                                    }
                                    mode="inline"
                                    items={listHardwareModule && listHardwareModule[0] && listHardwareModule[0]["children"]}
                                />
                            )}
                            <div className="mt-4">
                                <Upload
                                    customRequest={() => { }}
                                    fileList={fileList}
                                    beforeUpload={() => { return false }}
                                    onChange={handleUploadChange}
                                    showUploadList={false}
                                    accept=".yaml,.yml"
                                >
                                    {/* {fileList.length === 0 ? handleUploadButton : null} */}
                                    <Button type="primary" style={{ backgroundColor: fileList.length !== 0 ? 'red' : '' }} icon={fileList.length !== 0 ? <UndoOutlined rev={undefined} /> : <UploadOutlined rev={undefined} />}>
                                        {fileList.length === 0 ? ` Upload` : `Upload Again`}
                                    </Button>
                                </Upload>
                                {fileList.map((file) => (
                                    <div key={file.uid}>
                                        <Space className="mt-3">
                                            <span>{file.name}</span>
                                            <Button
                                                icon={<DeleteOutlined rev={undefined} />}
                                                onClick={() => handleRemove()}
                                            >
                                            </Button>
                                        </Space>
                                        {file.preview}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {loading && <div className="spinner spinner-success mr-3"></div>}
                    <div></div>
                </div>
                <div className="col-9 mb-3">
                    <div className="card mb-4">
                        <div className="card-body">

                            <div className="row m-0 p-0">
                                <div className="col-6">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div className="w-100 d-block justify-content-start align-items-center mb-3">
                                            <h4 className="mt-2 mb-0"> <b> <i>{activeViewModule && activeViewModule}</i> </b></h4>
                                        </div>
                                        <div>
                                            {loading && <div className="spinner spinner-success mr-3"></div>}

                                            {
                                                stateBtnContinue && hardwareYamlData && validateYamlFile(JSON.parse(hardwareYamlData))['success'] &&
                                                !loading && <Button
                                                    onClick={handleContinue}
                                                    className="ml-3 " type="primary" icon={<ArrowRightOutlined rev={undefined} />}>
                                                    Continue
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                    {hardwareYamlData && generateYaml()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
