import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Action, ActionToName } from "src/constant/abac";
import { BackendContext } from "src/services/backend";
import { Can } from "src/services/caslContext";
import { FirebaseContext } from "src/services/firebaseContext";
import { IBAC, RoleDecompile } from "src/services/roleCompiler";

export const ModalProjectAdd = ({
  show,
  openModal,
  getCloudProject,
  updateScreen,
  setUpdateScreen,
}) => {
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const be = useContext(BackendContext);
  const { defaultOrg } = useContext(FirebaseContext);
  useEffect(() => {
    if (updateScreen) {
      setName(updateScreen["name"]);
    }
  }, []);
  const addProject = async () => {
    if (name) {
      if (!updateScreen) {
        setLoading(true);
        const add = await be.projectAdd(defaultOrg, name);
        await getCloudProject();
        openModal();
        setLoading(false);
      } else {
        setLoading(true);
        const add = await be.projectEdit(defaultOrg, updateScreen["uuid"], {
          name,
        });
        await getCloudProject();
        openModal();
        setLoading(false);
      }
    }
  };
  return (
    <div
      className=""
      id="modalAdd"
      role="dialog"
      aria-labelledby="modalAdd"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="verifyModalContent_title">
              {!updateScreen
                ? "Add Project"
                : "Edit Project " + updateScreen["name"]}
            </h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                setUpdateScreen(false);
                openModal();
              }}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group w-100 container">
                <label htmlFor="recipient-name-2" className="col-form-label">
                  <span className="text-danger">*</span> Name:
                </label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="form-control"
                />
                {!name && (
                  <label htmlFor="recipient-name-2" className="text-danger">
                    Enter Name
                  </label>
                )}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setUpdateScreen(false);
                openModal();
              }}
              className="btn btn-secondary"
              data-dismiss={`${show ? "modal" : ""}`}
            >
              Close
            </button>
            {!loading && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  addProject();
                }}
                type="button"
                className="btn btn-primary"
              >
                OK
              </button>
            )}
            {loading && <div className="spinner spinner-success mr-3"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
