import React, { useContext, useEffect, useState } from "react";
import { BackendAPI, BackendContext } from "src/services/backend";
import { FirebaseContext } from "src/services/firebaseContext";
import { ModalAdd } from "./access/modalAdd";
import Modal from "react-modal";
import { Can } from "src/services/caslContext";
import { Action } from "src/constant/abac";
import { Resources } from "src/constant/resources";
import "../../assets/modal.css";
export const Access = () => {
  const [show, setShow] = useState<boolean>(false);
  const { defaultOrg } = useContext(FirebaseContext);
  const [userPartner, setUserPartner] = useState<Array<object>>();
  const [project, setProject] = useState<Array<object>>();
  const be = useContext(BackendContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = React.useState<string | undefined>();
  const [updateScreen, setUpdateScreen] = React.useState<boolean | string>(
    false,
  );
  const openModal = () => {
    setShow(!show);
  };
  const getCloudProject = async () => {
    const getAll = await be.projectGetAll(defaultOrg);
    setProject(getAll);
  };
  useEffect(() => {
    getAccessUserPartner().then((x) => {
      getCloudProject();
    });
  }, []);
  const getAccessUserPartner = async () => {
    setLoading(true);
    const getAll = await be.userpartnerGetAll(defaultOrg);
    setUserPartner(getAll);
    setLoading(false);
  };
  const deleteUser = async (uuid: string) => {
    setLoading(true);
    await be.userpartnerDelete(defaultOrg, uuid);
    await getAccessUserPartner();
    setLoading(false);
  };
  function modalDelete(uuid: string) {
    setIsOpen(uuid);
  }
  function closeModal() {
    setIsOpen(undefined);
  }
  return (
    <>
      <h3>Organization Access Manager</h3>
      <div
        className="breadcrumb"
        style={{ borderBottom: "3px solid rgb(232, 237, 235)" }}
      >
        <ul className=" pb-2">
          <li className="pl-0">
            <a href="/org/project">Dashboard</a>
          </li>
          <li>Version 1</li>
        </ul>
      </div>
      <Modal
        isOpen={show}
        // onRequestClose={modalDelete}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "none",
            border: 0,
            width: "900px",
            background: "none!important",
          },
        }}
      >
        <ModalAdd
          updateScreen={updateScreen}
          setUpdateScreen={() => setUpdateScreen(false)}
          project={project}
          show={show}
          openModal={openModal}
          getAccessUserPartner={getAccessUserPartner}
        />
      </Modal>
      <div className="row">
        <div className="col-8 mb-3">
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h4 className="mt-2">Authorizations</h4>
                </div>
                <div className="form-group m-0 ">
                  <button
                    onClick={() => {
                      setUpdateScreen(false);
                      openModal();
                    }}
                    data-backdrop="static"
                    data-keyboard="false"
                    data-toggle="modal"
                    data-target={`${show ? "#modalAdd" : ""}`}
                    type="button"
                    className="btn btn-success btn-icon m-1"
                  >
                    <span className="ul-btn__icon">
                      <i className="i-Add"></i>
                    </span>
                    Add Authorization
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table id="" className="table text-left  ">
                  <thead>
                    <tr style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                      <th scope="col">Users</th>
                      <th scope="col" className="text-center">
                        Projects
                      </th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {loading && (
                    <tbody>
                      <div className="spinner spinner-success mr-3"></div>
                    </tbody>
                  )}
                  {!loading && (
                    <tbody>
                      {userPartner &&
                        userPartner.map((user, i) => {
                          const x = user["user"];
                          return (
                            <tr key={i}>
                              <th style={{ width: "16%" }} scope="row">
                                <span
                                  className="font-weight-bold "
                                  style={{ fontSize: 22 }}
                                >
                                  {x["name"]}{" "}
                                </span>
                                {x["status"] === 0 ? (
                                  <span className="badge badge-success">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-warning">
                                    Not Active
                                  </span>
                                )}
                                <br />
                                <span className="mr-2 font-weight-normal">
                                  {x["email"]}
                                </span>{" "}
                              </th>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                <span className="font-weight-bold">
                                  {user["numOfProject"]}
                                </span>
                              </td>
                              <td>
                                {new Date(x["createdAt"]).toLocaleString()}
                              </td>
                              <td>
                                {/* <a onClick={() => {
                                                                    setUpdateScreen(x['ownerId'])
                                                                    openModal()
                                                                }} href="#" className="text-success mr-2 border border-dark border-3 rounded p-2" style={{ backgroundColor: '#efefef' }} >
                                                                    <i style={{ color: 'black' }} className="nav-icon i-Pen-2 font-weight-bold"></i>
                                                                </a> */}
                                <Can I={Action.UserDelete} a={Resources.User}>
                                  <a
                                    onClick={() => modalDelete(x["uuid"])}
                                    href="#"
                                    className="text-danger mr-2 border border-3 rounded p-2"
                                    style={{ backgroundColor: "#efefef" }}
                                  >
                                    Delete
                                  </a>
                                </Can>
                                <Modal
                                  isOpen={modalIsOpen === x["uuid"]}
                                  // onRequestClose={modalDelete}
                                  ariaHideApp={false}
                                  style={{
                                    content: {
                                      top: "50%",
                                      left: "50%",
                                      right: "auto",
                                      bottom: "auto",
                                      marginRight: "-50%",
                                      transform: "translate(-50%, -50%)",
                                      overflow: "auto",
                                    },
                                  }}
                                >
                                  <h2>Detach {x["email"]} ?</h2>
                                  <div className="d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={closeModal}
                                      className="btn btn-secondary"
                                    >
                                      Close
                                    </button>
                                    {!loading && (
                                      <button
                                        type="button"
                                        onClick={() => deleteUser(modalIsOpen)}
                                        className="btn btn-danger ml-3"
                                      >
                                        Delete
                                      </button>
                                    )}
                                    {loading && (
                                      <div className="spinner spinner-success mr-3"></div>
                                    )}
                                  </div>
                                </Modal>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
