import * as React from "react";
import { useState } from "react";
import { Footer } from "src/partials/footer";
import { BackendAPI } from "../services/backend";
import { auth } from "src/services/config";
import toast from "react-hot-toast";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "src/services/firebaseContext";
import { useAuthState } from "react-firebase-hooks/auth";
export const Login = () => {
  const [mail, setEmail] = useState<any>();
  const [pwd, setPwd] = useState<any>();
  const [loadings, setLoadings] = useState<boolean>();

  const navigate = useNavigate();
  const contextData = React.useContext(FirebaseContext);
  const [user, loading, error] = useAuthState(auth);
  const login = async () => {
    setLoadings(true);
    if (mail || pwd) {
      try {
        const signIn = await signInWithEmailAndPassword(auth, mail, pwd);
      } catch (err) {
        toast.error(err.message);
      }
    }
    setLoadings(false);
  };
  React.useEffect(() => {
    if (user) {
      // navigate('/org/project')
      window.location.href = "/org/project";
    }
  }, [user]);
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };
  return (
    <>
      <div className="auth-layout-wrap" style={{ backgroundColor: "#eee" }}>
        <div className="main-header">
          <div className="logo d-flex justify-content-center align-items-center ml-4">
            {/* <img src="../assets/images/logo.svg" alt=" FPT Smart Home" />
                    <div className="font-weight-bold text-20 ml-3"> FPT Smart Home</div> */}
          </div>
          <div style={{ margin: "auto" }}></div>
        </div>
        <div className="auth-content">
          <div className="card o-hidden rounded-0" style={{ marginBottom: 50 }}>
            <div className="row py-5">
              <div className="col-md-12 ">
                <div className="p-4 border-right">
                  <div className="auth-logo text-center mb-4">
                    {/* <img src="../assets/images/logo.svg" alt="" /> */}
                  </div>
                  <h1 className="mb-3 text-18 text-center font-weight-bold">
                    Sign In
                  </h1>
                  <div>
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <input
                        onKeyPress={handleEnterPress}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        className="form-control form-control"
                        type="email"
                      />
                      {!mail ? (
                        <label
                          htmlFor="recipient-name-2"
                          className="text-danger"
                        >
                          Email Is Required
                        </label>
                      ) : (
                        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          mail,
                        ) && (
                          <label
                            htmlFor="recipient-name-2"
                            className="text-danger"
                          >
                            Not Valid Email Format
                          </label>
                        )
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        onKeyPress={handleEnterPress}
                        onChange={(e) => setPwd(e.target.value)}
                        id="password"
                        className="form-control form-control"
                        type="password"
                      />
                      {!pwd && (
                        <label
                          htmlFor="recipient-name-2"
                          className="text-danger"
                        >
                          Enter Password
                        </label>
                      )}
                    </div>
                    <div className="form-group mt-4">
                      <div className="d-flex justify-content-start">
                        {/* <input id="checkbox" className="mr-2" type="checkbox" />
                                            <span>
                                                Agree to Terms of Use, Legal Statement, Privacy Policy
                                            </span> */}
                      </div>
                    </div>
                    {loadings && (
                      <div className="text-center">
                        <div className="spinner btn btn-primary btn-block mt-2 spinner-success mr-3"></div>
                      </div>
                    )}
                    {!loadings && (
                      <button
                        onClick={login}
                        className="btn btn-primary btn-block mt-2"
                      >
                        Sign In
                      </button>
                    )}
                    {/* <div className="d-flex justify-content-between mt-2">
                                        <span style={{ fontSize: 13 }}>
                                            <a href="#">Sign up</a>
                                        </span>
                                        <span style={{ fontSize: 13 }}>
                                            <a href="#">Forgot Password?</a>
                                        </span>
                                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 text-center " style={{ backgroundSize: "cover", }}>
                            <div className="pr-3 auth-right">
                                <a className="btn btn-outline-primary btn-outline-email btn-block btn-icon-text" href="signup-2.html">
                                    <i className="i-Mail-with-At-Sign"></i> Sign up with Email
                                </a>
                                <a className="btn btn-outline-primary btn-outline-google btn-block btn-icon-text">
                                    <i className="i-Google-Plus"></i> Sign up with Google
                                </a>
                                <a className="btn btn-outline-primary btn-block btn-icon-text btn-outline-facebook">
                                    <i className="i-Facebook-2"></i> Sign up with Facebook
                                </a>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
        <Footer logo={false} />
      </div>
    </>
  );
};
