import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Radio, Select, Space } from 'antd';
import { PROTOCOL } from 'src/constant/productModelNewVer';
import * as _ from 'lodash'
import { toast } from 'react-hot-toast';
import { IoTAttribute } from 'src/constant/features';
const { Option } = Select;


const YamlViewer = (
  {
    yamlData,
    productInfo,
    protocolData,
    form,
    productChoice,
    handleSubmit
  }
) => {
  const [hardwareInfo, sethardwareInfo] = useState<any>()
  const [subData, setSubData] = useState<any>({ metadata: { key: 'metadata', data: [{ metadata: protocolData }] } })
  useEffect(() => {
    const { baseInfo, wrapFeatures, extraInfo, developmentInfo } = productInfo
    const featureName = wrapFeatures && wrapFeatures.map(x => _.invert(IoTAttribute)[x].toLowerCase()) // onoff | brightness 
    sethardwareInfo(_.uniq(featureName))
    setSubData({ metadata: { key: 'metadata', data: [{ metadata: protocolData }] } })
  }, [yamlData])

  const onFinish = (inputValue) => {
    handleSubmit(inputValue)
  }
  // `hardwareInfo` contains all feature that user selected before
  // this function for get all selected value into state `subData`  along with the `data` (object value of key) of the key in object
  // save `key` (object key ) and `data` (object values) and `root` (object root)
  const onChangeSelect = (dataInput, key, root) => {
    const { featureConfig } = yamlData
    const result = { ...subData }
    dataInput.map(x => {
      if (featureConfig[key] && x[key]) {
        x[key] = { ...x[key], ...featureConfig[key] }
      }
      return x
    })
    result[root] = { key, data: dataInput }
    setSubData(result)
  }
  // this function for generating `select` `option` value based on type & depth of object we have in yaml file
  // use recursive to read if the `rootKey` (in feature_supported) matches `subData` we save in prev stage (filter step)
  // yes => generate 
  const generateSelect = (rootKey, labelRootKey = '') => {
    const { featureConfig } = yamlData
    const result = []

    for (const key in subData) {
      const dataShow = subData[key]['data']
      const keyShow = subData[key]['key']
      if (rootKey === key) {
        const getSpecific = {
          key: keyShow,
          root: key,
          data: dataShow && dataShow.filter(x => !!x[keyShow] || x[keyShow] === null).map(x => {
            let resultTemp
            if (featureConfig[keyShow]) {
              resultTemp = { ...x[keyShow], ...featureConfig[keyShow] }
            } else {
              resultTemp = x[keyShow]
            }
            return resultTemp
          })[0] // get user active selected 
        }
        result.push(getSpecific)
      }
    }
    const jsx = []
    for (const elms of result) {
      const { data } = elms
      for (const elm in data) {
        jsx.push(
          <Form.Item
            className='ml-5' name={labelRootKey + rootKey + `-` + elm} label={elm}
            rules={[{ required: (typeof data[elm] === 'object' && Array.isArray(data[elm])) || typeof data[elm] === 'boolean' ? true : false, message: "Please select " + elm }]}
            initialValue={typeof data[elm] === 'number' ? data[elm] : undefined}
          >
            {
              typeof data[elm] === 'boolean' ?
                <Radio.Group>
                  <Radio value={true} >{`true`} </Radio>
                  <Radio value={false} >{`false`} </Radio>
                </Radio.Group>
                :
                typeof data[elm] === 'number' ?
                  <InputNumber value={data[elm]} />
                  :
                  Array.isArray(data[elm]) ? <Radio.Group
                    onChange={(x) => {
                      onChangeSelect(data[elm], x.target.value, elm)
                    }}
                  >
                    {
                      data[elm].map(x => {
                        return typeof x === 'string' || typeof x === 'number' ? <Radio value={x} >{x} </Radio> : generateSubSelected(x, false, rootKey)
                      })

                    }
                    {
                      generateSelect(elm, rootKey + '-')
                    }
                  </Radio.Group> : typeof data[elm] === 'object' && generateSubSelected(data[elm], true, rootKey + '-' + elm)
            }
          </Form.Item>
        )
      }
    }
    return jsx.flat()
  }
  const generateSubSelected = (data, checkExistFeature = false, rootKey) => {
    const jsx = []
    if (!checkExistFeature) {
      for (const sub in data) {
        jsx.push(
          <Radio value={sub}>{sub}</Radio>
        )
      }
    } else {
      // item i2c_pin object iterate
      for (const sub in data) {
        jsx.push(
          <Form.Item
            name={rootKey + `-` + sub}
            label={sub}
            initialValue={data[sub]}
          >
            {
              <InputNumber value={data[sub]} />
            }
          </Form.Item>
        )
      }
    }
    return jsx.flat()
  }
  return (
    <Form
      name="validate_other"
      onFinish={(values) => onFinish(values)}
      style={{ minWidth: 300 }}
      form={form}
    >
      {
        generateSelect('metadata')
      }
      {
        hardwareInfo && hardwareInfo.map(x => {
          const featureData = yamlData['featureSupported'][x]
          return featureData && (
            <>
              <Form.Item
                rules={[{ required: true, message: "Please select " + x }]}
                name={x}
                label={x} >
                <Radio.Group
                  onChange={(z) => {
                    onChangeSelect(featureData, z.target.value, x)
                  }}
                  className='ml-5'>
                  {
                    featureData && featureData.map((y, j) => {
                      return <>
                        {
                          !!y && Object.keys(y).map(z => {
                            return <>
                              <Radio value={z} >{z}</Radio>
                            </>
                          })
                        }
                      </>
                    }) 
                  }
                </Radio.Group>
              </Form.Item>
              {subData && generateSelect(x)}
            </>
          )

        })
      }

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Space>
          <>
            <Button htmlType="reset">reset</Button>
            {productChoice === 'MODULE' && <Button type="primary" htmlType="submit">
              Confirm
            </Button>}
          </>
        </Space>
      </Form.Item>
    </Form>
  )
};


export default YamlViewer;
